import React, { useEffect, useState, useRef } from "react";
import {
  get_shift,
  bp_machine_data,
  get_employee,
  add_machine_shift,
  get_machine_shift,
  Add_Bulk_Machine_Shift,
} from "../../../utils/api";
import { toast } from "react-toastify";
import { Table, Image, Modal, Select as AntSelect, Tabs } from "antd";
import AddIcon from "@mui/icons-material/Add";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import sample_shift_data from "../../../assets/third-party/sample_shift_data.xlsx";

import {
  InputLabel,
  OutlinedInput,
  Stack,
  Tooltip,
  Typography,
  Select,
  MenuItem,
  Grid,
  Button,
  IconButton,
  Divider,
  FormHelperText,
} from "@mui/material";
import MainCard from "components/MainCard";
import { downloadPDF } from "pages/reusable_functions";

function Shift() {
  const inputRef = useRef(null);
  const [modal_open, set_modal_open] = useState(false);
  const [machine_shift_details, set_machine_shift_details] = useState([]);
  const [shift_data, set_shift_data] = useState([]);
  const [machine_data, set_machine_data] = useState([]);
  const [employee_data, set_employee_data] = useState([]);
  const [shift_submit_data, set_shift_submit_data] = useState([]);

  const [qr_file, set_qr_file] = useState([]);

  const [shift_obj, set_shift_obj] = useState({
    shift: "",
    shift_id: "",
    machine: "",
    machine_id: "",
    employee_names: [],
    employee: [],
  });

  const machine_shift_columns = [
    {
      dataIndex: "shift",
      title: "Shift",
      key: "shift",
      width: "8%",
    },
    {
      dataIndex: "machine",
      title: "Machine",
      key: "machine",
      width: "8%",
    },
    {
      dataIndex: "employee_names",
      title: "Employees",
      key: "employee",
      width: "8%",
      render: (employee_names) => employee_names?.join(", "),
    },
  ]?.filter(Boolean);

  //####################### Handle Api's######################################################

  const get_all_inventory = async () => {
    try {
      await get_shift(success_get_shift);
      await bp_machine_data(success_machine_list_get);
      await get_employee(success_set_employee_list);
      await get_machine_shift(success_get_machine_shift);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const success_get_shift = (res) => {
    set_shift_data(res?.data?.data);
  };
  const success_machine_list_get = (res) => {
    set_machine_data(res?.data?.data);
  };
  const success_set_employee_list = (res) => {
    set_employee_data(res?.data?.data);
  };
  const success_get_machine_shift = (res) => {
    set_machine_shift_details(res?.data?.data);
  };

  //Add inventory
  const submit_shift = async () => {
    try {
      await add_machine_shift(
        { data_flow: shift_submit_data },
        success_add_inventory
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const success_add_inventory = (res) => {
    if (res.status === "failed") {
      toast.warning(res.message);
    } else {
      set_shift_submit_data([]);
      set_shift_submit_data(res?.data);
      if (inputRef.current) {
        inputRef.current.value = "";
      }
      if (res?.existing?.length > 0) {
        error(res?.existing);
      }
      get_machine_shift(success_get_machine_shift);
      toast.success("Users shift added successfully");
    }
  };

  const reset_function = () => {
    set_shift_obj({
      shift: "",
      shift_id: "",
      machine: "",
      machine_id: "",
      employee_names: [],
      employee: [],
    });
  };

  const error = (exists_data) => {
    Modal.error({
      title: "Error Message",
      content: (
        <Table
          size="small"
          bordered
          columns={[
            {
              dataIndex: "name",
              align: "center",
              title: "Name",
            },
            {
              dataIndex: "msg",
              title: "Error Message",
            },
          ]}
          pagination={{ pageSize: 10 }}
          dataSource={exists_data}
        />
      ),
      width: "40%",
      okText: "Close",
    });
  };

  useEffect(() => {
    get_all_inventory();
  }, []);
  const [activeKey, setActiveKey] = useState("1");

  return (
    <MainCard>
      <Tabs
        defaultActiveKey="1"
        onChange={(key) => setActiveKey(key)}
        tabBarExtraContent={
          activeKey === "2" && (
            <FormHelperText>
              *Add shift details using either an Excel upload or manual entry.
            </FormHelperText>
          )
        }
        items={[
          {
            key: "1",
            label: "Shift Details",
            children: (
              <Table
                dataSource={machine_shift_details}
                columns={[
                  {
                    dataIndex: "shift",
                    title: "Shift",
                    key: "shift",
                    align: "center",
                    width: "30%",
                  },
                  {
                    dataIndex: "machine",
                    title: "Machine",
                    key: "machine",
                    align: "center",
                    width: "30%",
                  },
                  {
                    dataIndex: "employees",
                    title: "Employees",
                    key: "employees",
                    align: "center",
                    width: "40%",
                    render: (employees) => employees?.join(", "),
                  },
                ]?.filter(Boolean)}
                scroll={{ x: 940, y: 510 }}
                bordered
                pagination={{ pageSize: 10 }}
              />
            ),
          },
          {
            key: "2",
            label: "Add Shift",
            children: (
              <>
                <Grid item lg={12} container spacing={1}>
                  <Grid item lg={2.75}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="quantity">Upload File</InputLabel>
                      <Stack direction="row" spacing={1}>
                        <OutlinedInput
                          inputProps={{
                            accept:
                              ".xlsx, .xls, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
                          }}
                          type="file"
                          id="select-excel"
                          fullWidth
                          required
                          inputRef={inputRef}
                          onChange={(e) => {
                            set_qr_file(e.target.files);
                          }}
                        />
                        <a href={sample_shift_data} download="sample_file.xlsx">
                          <Tooltip title="Sample File">
                            <IconButton id="download-sample-file">
                              <DescriptionOutlinedIcon
                                color="primary"
                                style={{
                                  fontSize: "30px",
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                        </a>
                      </Stack>
                    </Stack>
                  </Grid>
                  <Grid item xs={2}>
                    <Stack spacing={1}>
                      <Typography>&nbsp;</Typography>
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{ width: "fit-content" }}
                        disabled={qr_file?.length === 0}
                        onClick={() => {
                          const userFileData = new FormData();
                          userFileData.append("file", qr_file[0]);
                          Add_Bulk_Machine_Shift(
                            userFileData,
                            success_add_inventory
                          );
                        }}
                      >
                        Submit
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
                <Divider sx={{ my: 2 }}>OR</Divider>
                <Grid container item lg={12} spacing={2}>
                  <Grid item xs={2.75}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="title">Shift</InputLabel>
                      <AntSelect
                        id="shift"
                        showSearch
                        allowClear
                        size="large"
                        placeholder="Select Shift"
                        labelRender={(props) => {
                          return props?.label?.props?.children;
                        }}
                        optionFilterProp="children"
                        value={shift_obj["shift_id"]}
                        onChange={(e) => {
                          const selectedShift = shift_data?.find(
                            (item) => item?.shift_id === e
                          );
                          set_shift_obj({
                            ...shift_obj,
                            shift_id: e,
                            shift: selectedShift?.shift,
                          });
                        }}
                        filterOption={(input, option) =>
                          (option?.label2 ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        style={{
                          width: "100%",
                          border: "0.5px solid #D9D9D9",
                          borderRadius: "3px",
                        }}
                        bordered={false}
                        dropdownStyle={{
                          maxHeight: 400,
                          overflow: "auto",
                        }}
                        options={shift_data?.map((item) => {
                          return {
                            value: item?.shift_id,
                            label: (
                              <div
                                style={{
                                  maxWidth: "100%",
                                  wordWrap: "break-word",
                                  whiteSpace: "normal",
                                }}
                              >
                                {item?.shift}
                              </div>
                            ),
                            label2: item?.shift,
                          };
                        })}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={2.75}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="machine">Machine</InputLabel>
                      <AntSelect
                        id="machine"
                        showSearch
                        allowClear
                        size="large"
                        placeholder="Select Machine"
                        labelRender={(props) => {
                          return props?.label?.props?.children;
                        }}
                        optionFilterProp="children"
                        value={shift_obj["machine_id"]}
                        onChange={(value) => {
                          set_shift_obj({
                            ...shift_obj,
                            machine_id: value,
                            machine: machine_data?.find(
                              (item) => item?.machine_id === value
                            )?.name,
                          });
                        }}
                        filterOption={(input, option) =>
                          (option?.label2 ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        style={{
                          width: "100%",
                          border: "0.5px solid #D9D9D9",
                          borderRadius: "3px",
                        }}
                        bordered={false}
                        dropdownStyle={{
                          maxHeight: 420,
                          overflow: "auto",
                        }}
                        options={machine_data?.map((item) => {
                          return {
                            value: item?.machine_id,
                            label: (
                              <div
                                style={{
                                  maxWidth: "100%",
                                  wordWrap: "break-word",
                                  whiteSpace: "normal",
                                }}
                              >
                                {item?.name}
                              </div>
                            ),
                            label2: item?.name,
                          };
                        })}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={2.75}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="employees">Employees</InputLabel>
                      <AntSelect
                        id="employees"
                        showSearch
                        allowClear
                        mode="multiple"
                        size="large"
                        placeholder="Select Employees"
                        labelRender={(props) => {
                          return props?.label?.props?.children;
                        }}
                        optionFilterProp="children"
                        value={shift_obj["employee"]}
                        onChange={(value) => {
                          set_shift_obj({
                            ...shift_obj,
                            employee: value,
                            employee_names: value?.map(
                              (emp_id) =>
                                employee_data?.find(
                                  (item) => item?.employee_id === emp_id
                                )?.employee_name
                            ),
                          });
                        }}
                        filterOption={(input, option) =>
                          (option?.label2 ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        style={{
                          width: "100%",
                          border: "0.5px solid #D9D9D9",
                          borderRadius: "3px",
                        }}
                        bordered={false}
                        dropdownStyle={{
                          maxHeight: 420,
                          overflow: "auto",
                        }}
                        options={employee_data?.map((item) => {
                          return {
                            value: item?.employee_id,
                            label: (
                              <div
                                style={{
                                  maxWidth: "100%",
                                  wordWrap: "break-word",
                                  whiteSpace: "normal",
                                }}
                              >
                                {item?.employee_name}
                              </div>
                            ),
                            label2: item?.employee_name,
                          };
                        })}
                      />
                    </Stack>
                  </Grid>

                  <Grid item xs={1}>
                    <Stack spacing={1}>
                      <Typography>&nbsp;</Typography>
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{ width: "fit-content" }}
                        disabled={Object.values(shift_obj)?.some((item) => {
                          if (Array.isArray(item)) return item?.length === 0;
                          else return !Boolean(item);
                        })}
                        onClick={() => {
                          let add_obj = {
                            ...shift_obj,
                            employee: shift_obj?.employee?.join(","),
                          };
                          set_shift_submit_data((data) => [...data, add_obj]);
                          reset_function();
                        }}
                      >
                        Add
                      </Button>
                    </Stack>
                  </Grid>
                  {shift_submit_data?.length > 0 && (
                    <Grid item xs={12}>
                      <Table
                        dataSource={shift_submit_data}
                        columns={machine_shift_columns}
                        scroll={{ x: 940, y: 410 }}
                        size="small"
                        bordered
                        pagination={false}
                      />
                    </Grid>
                  )}
                  {shift_submit_data?.length > 0 && (
                    <Grid item lg={12}>
                      <Stack direction="row" justifyContent="end">
                        <Button
                          variant="contained"
                          color="primary"
                          disabled={shift_submit_data?.length === 0}
                          onClick={() => {
                            submit_shift();
                          }}
                        >
                          Submit
                        </Button>
                      </Stack>
                    </Grid>
                  )}
                </Grid>
              </>
            ),
          },
        ]}
      />
    </MainCard>
  );
}

export default Shift;
