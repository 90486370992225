import React, { useEffect, useState, useRef } from "react";
import {
  bp_inventory_data,
  bp_material_inentory_data,
  bp_units_data,
  bp_location_data,
  bp_supplier_data,
  bp_material_data,
  bp_material_type_data,
} from "../../../utils/api";
import { toast } from "react-toastify";
import { Table, Pagination } from "antd";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";

import {
  InputLabel,
  OutlinedInput,
  Stack,
  Tooltip,
  Typography,
  FormHelperText,
  MenuItem,
  Grid,
  IconButton,
  Button,
} from "@mui/material";
import { Select } from "antd";
import ConfigModal from "./ConfigModal";
import { useDispatch, useSelector } from "react-redux";
import { set_submit_status } from "store/reducers/submit_status";

function AddIngredient({
  set_is_model_open,
  type_id,
  set_type_id,
  get_submit_status,
  set_get_submit_status,
}) {
  const dispatch = useDispatch();
  const [get_status, set_get_status] = useState(false);
  const submit_status = useSelector((state) => state.submit_status);

  const formRef = useRef();
  const [inventoryData, setInventoryData] = useState();
  const [locationData, setLocationData] = useState();
  const [materialData, setMaterialData] = useState();
  const [material_type_data, set_material_type_data] = useState();
  const [supplierData, setSupplierData] = useState();
  const [unitData, setUnitsData] = useState();
  function validateInput(input) {
    const regex = /^(\d+(\.\d+)?)(,\s*\d+(\.\d+)?)*$/;
    return regex.test(input);
  }
  const [inventoryReq, setInventoryReq] = useState({
    material_name_id: "",
    material_type_id: "",
    // quantity: "",
    unit_id: "",
    packaging_size: "",
    // location_id: "i",
    // supplier_id: "i",
    // batch_number: "",
    specifications: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    setInventoryReq((prevInventoryReq) => ({
      ...prevInventoryReq,
      packaging_size: prevInventoryReq?.packaging_size?.replace(/,\s*$/, ""), // Replace `newValue` with the actual value you want to set
    }));

    if (validateInput(inventoryReq?.packaging_size?.replace(/,\s*$/, ""))) {
      add_Inventory(inventoryReq);
    } else {
      toast.warning(
        "Invalid Packaging Sizes: Sizes should only contain numbers (including decimals) separated by commas"
      );
    }
  };

  useEffect(() => {
    setInventoryReq({
      ...inventoryReq,
      material_type_id: type_id || "",
      material_name_id: "",
      // quantity: "",
      unit_id: "",
      packaging_size: "",
      // supplier_id: "i",
      // batch_number: "",
      specifications: "",
    });
  }, [submit_status, type_id]);

  //####################### Handle Api's######################################################

  const get_all_inventory = async () => {
    try {
      await bp_inventory_data(success_inventory_list_get);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const success_inventory_list_get = (res) => {
    console.log("res", res);
    setInventoryData(res?.data?.data);
  };

  const get_all_dropDown_Data = async () => {
    try {
      await bp_location_data(success_location_list_get);
      await bp_material_data(success_material_list_get);
      await bp_material_type_data(success_material_type_list_get);
      await bp_supplier_data(success_supplier_list_get);
      await bp_units_data(success_units_list_get);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const success_location_list_get = (res) => {
    console.log("res", res);
    setLocationData(res?.data?.data);
  };

  const success_material_list_get = (res) => {
    console.log("res", res);
    setMaterialData(res?.data?.data);
  };
  const success_material_type_list_get = (res) => {
    console.log("res", res);
    set_material_type_data(res?.data?.data);
  };
  const success_supplier_list_get = (res) => {
    console.log("res", res);
    setSupplierData(res?.data?.data);
  };
  const success_units_list_get = (res) => {
    console.log("res", res);
    setUnitsData(res?.data?.data);
  };

  //Add inventory
  const add_Inventory = async (inventoryReq) => {
    try {
      await bp_material_inentory_data(inventoryReq, success_add_inventory);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const success_add_inventory = (res) => {
    console.log(res);
    if (res.status === "failed") {
      toast.warning(res.message);
    } else {
      toast.success(res.message);
      reset_function();
      get_all_inventory();
      set_get_submit_status(!get_submit_status);
      set_type_id(0);
      dispatch(set_submit_status());
      set_is_model_open(false);
    }
  };

  const reset_function = () => {
    setInventoryReq({
      material_name_id: "",
      material_type_id: "",
      packaging_size: "",
      // quantity: "",
      unit_id: "",
      // location_id: "i",
      // supplier_id: "i",
      // batch_number: "",
      specifications: "",
    });
  };
  const get_all_dropDown_Data_on_submit = async () => {
    try {
      add_number === 3 && (await bp_location_data(success_location_list_get));
      add_number === 1 && (await bp_material_data(success_material_list_get));
      add_number === 2 && (await bp_units_data(success_units_list_get));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    get_all_dropDown_Data_on_submit();
  }, [get_status]);

  useEffect(() => {
    get_all_inventory();
    get_all_dropDown_Data();
  }, []);

  //   =============================================================
  const [show_modal, set_show_modal] = useState(false);
  const [add_number, set_add_number] = useState(0);

  return (
    <>
      <form ref={formRef} onSubmit={handleSubmit}>
        <Grid container spacing={2} style={{ display: "flex" }}>
          <Grid item xs={4}>
            <Stack spacing={1}>
              <InputLabel htmlFor="title">Material</InputLabel>
              <Stack direction="row" spacing={1}>
                <Select
                  showSearch
                  id="demo-select-small"
                  value={inventoryReq["material_name_id"] || null}
                  size="large"
                  style={{
                    width: "100%",
                    border: "0.5px solid #D9D9D9",
                    borderRadius: "3px",
                  }}
                  bordered={false}
                  placeholder="Select Material"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children.localeCompare(optionB.children)
                  }
                  dropdownStyle={{
                    maxHeight: 400,
                    overflow: "auto",
                  }}
                  onChange={(value) =>
                    setInventoryReq({
                      ...inventoryReq,
                      material_name_id: value,
                    })
                  }
                >
                  {materialData?.map((mdata) => (
                    <Select.Option
                      key={mdata.material_id}
                      value={mdata.material_id}
                    >
                      {mdata.material_name}
                    </Select.Option>
                  ))}
                </Select>
                <Tooltip title="Add Material">
                  <IconButton
                    onClick={() => {
                      set_show_modal(true);
                      set_add_number(1);
                    }}
                  >
                    <AddIcon
                      style={{
                        color: "#00C853",
                        fontSize: "32px",
                      }}
                    />
                  </IconButton>
                </Tooltip>
              </Stack>
            </Stack>
          </Grid>

          <Grid item xs={4}>
            <Stack spacing={1}>
              <InputLabel htmlFor="title">Material Type</InputLabel>
              <Stack direction="row" spacing={1}>
                <Select
                  showSearch
                  id="demo-select-small"
                  value={inventoryReq["material_type_id"] || null}
                  size="large"
                  style={{
                    width: "100%",
                    border: "0.5px solid #D9D9D9",
                    borderRadius: "3px",
                  }}
                  bordered={false}
                  placeholder="Select Material Type"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children.localeCompare(optionB.children)
                  }
                  dropdownStyle={{
                    maxHeight: 400,
                    overflow: "auto",
                  }}
                  onChange={(value) =>
                    setInventoryReq({
                      ...inventoryReq,
                      material_type_id: value,
                    })
                  }
                >
                  {material_type_data?.map((mdata) => (
                    <Select.Option
                      key={mdata.material_type_id}
                      value={mdata.material_type_id}
                    >
                      {mdata.material_type}
                    </Select.Option>
                  ))}
                </Select>
              </Stack>
            </Stack>
          </Grid>

          <Grid item xs={4}>
            <Stack spacing={1}>
              <InputLabel htmlFor="size">Standard Packaging Size</InputLabel>
              <OutlinedInput
                id="title"
                size="medium"
                type="text"
                name="size"
                placeholder="Packaging Size"
                fullWidth
                inputProps={{ min: 0 }}
                value={inventoryReq?.packaging_size}
                required
                onChange={(e) =>
                  setInventoryReq({
                    ...inventoryReq,
                    packaging_size: e.target.value,
                  })
                }
              />
            </Stack>
            <FormHelperText style={{ fontSize: "13px" }}>
              Enter Sizes like 2, 3, 4, 5
            </FormHelperText>
          </Grid>

          <Grid item xs={4}>
            <Stack spacing={1}>
              <InputLabel htmlFor="title">Unit</InputLabel>
              <Stack direction="row" spacing={1}>
                <Select
                  showSearch
                  id="demo-select-small"
                  value={inventoryReq?.unit_id || null}
                  size="large"
                  style={{
                    width: "100%",
                    border: "0.5px solid #D9D9D9",
                    borderRadius: "3px",
                  }}
                  bordered={false}
                  placeholder="Select Unit"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children.localeCompare(optionB.children)
                  }
                  dropdownStyle={{
                    maxHeight: 400,
                    overflow: "auto",
                  }}
                  onChange={(value) =>
                    setInventoryReq({
                      ...inventoryReq,
                      unit_id: value,
                    })
                  }
                >
                  {unitData?.map((mdata) => (
                    <Select.Option key={mdata.id} value={mdata.id}>
                      {mdata.unit_name}
                    </Select.Option>
                  ))}
                </Select>
                <Tooltip title="Add Unit">
                  <IconButton
                    onClick={() => {
                      set_show_modal(true);
                      set_add_number(2);
                    }}
                  >
                    <AddIcon
                      style={{
                        color: "#00C853",
                        fontSize: "32px",
                      }}
                    />
                  </IconButton>
                </Tooltip>
              </Stack>
            </Stack>
          </Grid>

          {/* <Grid item xs={4}>
            <Stack spacing={1}>
              <InputLabel htmlFor="title">Location</InputLabel>
              <Stack direction="row" spacing={1}>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={inventoryReq.location_id}
                  size="small"
                  required
                  style={{width:260}}
                  onChange={(e) =>
                    setInventoryReq({
                      ...inventoryReq,
                      location_id: e.target.value,
                    })
                  }
                >
                  <MenuItem value="i" disabled>
                    <em> Select Location</em>
                  </MenuItem>
                  {locationData?.map((type) => (
                    <MenuItem value={type.location_id}>
                      {type.location_name}
                    </MenuItem>
                  ))}
                </Select>
                <Tooltip title="Add Location">
                  <IconButton
                    onClick={() => {
                      set_show_modal(true);
                      set_add_number(3);
                    }}
                  >
                    <AddIcon
                      style={{
                        color: "#00C853",
                        fontSize: "32px",
                      }}
                    />
                  </IconButton>
                </Tooltip>
              </Stack>
            </Stack>
          </Grid>

          <Grid item xs={4}>
            <Stack spacing={1}>
              <InputLabel htmlFor="title">Supplier</InputLabel>
              <Stack direction="row" spacing={1}>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={inventoryReq.supplier_id}
                  size="small"
                  required
                  style={{width:300}}
                  onChange={(e) =>
                    setInventoryReq({
                      ...inventoryReq,
                      supplier_id: e.target.value,
                    })
                  }
                >
                  <MenuItem value="i" disabled>
                    <em> Select Supplier</em>
                  </MenuItem>
                  {supplierData?.map((type) => (
                    <MenuItem value={type.supplier_id}>
                      {type.supplier_name}
                    </MenuItem>
                  ))}
                </Select>
              </Stack>
            </Stack>
          </Grid>

          <Grid item xs={4}>
            <Stack spacing={1}>
              <InputLabel htmlFor="title">Batch Number</InputLabel>
              <OutlinedInput
                id="title"
                size="small"
                type="text"
                name="email"
                placeholder="Enter Batch Number"
                fullWidth
                value={inventoryReq.batch_number}
                required
                onChange={(e) =>
                  setInventoryReq({
                    ...inventoryReq,
                    batch_number: e.target.value,
                  })
                }
              />
            </Stack>
          </Grid> */}

          <Grid item xs={4}>
            <Stack spacing={1}>
              <InputLabel htmlFor="title">Specifications</InputLabel>
              <OutlinedInput
                id="title"
                size="medium"
                type="text"
                name="email"
                placeholder="Enter Specifications"
                fullWidth
                value={inventoryReq.specifications}
                required
                onChange={(e) =>
                  setInventoryReq({
                    ...inventoryReq,
                    specifications: e.target.value,
                  })
                }
              />
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <Stack
              direction="row"
              justifyContent="end"
              spacing={1}
              sx={{ m: 1 }}
            >
              <Button
                size="small"
                type="reset"
                variant="contained"
                color="primary"
                className="mx-2"
                onClick={reset_function}
              >
                Reset
              </Button>
              <Button
                size="small"
                type="submit"
                variant="contained"
                color="primary"
                disabled={
                  !(
                    inventoryReq["material_name_id"] !== "" &&
                    //     inventoryReq["quantity"]!== ""&&
                    inventoryReq["unit_id"] !== "" &&
                    inventoryReq["material_type_id"] !== "" &&
                    //    inventoryReq["location_id"]!== "i"&&
                    // inventoryReq["supplier_id"]!== "i"&&
                    //   inventoryReq["batch_number"]!== ""&&
                    inventoryReq["specifications"] !== ""
                  )
                }
              >
                Submit
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </form>
      <ConfigModal
        show_modal={show_modal}
        set_show_modal={set_show_modal}
        add_number={add_number}
        set_add_number={set_add_number}
        get_status={get_status}
        set_get_status={set_get_status}
      />
    </>
  );
}

export default AddIngredient;
