// material-ui
import { Box, useMediaQuery } from "@mui/material";

// project import
import Search from "./Search";
import Profile from "./Profile";
import MobileSection from "./MobileSection";
import Notification from "./Notification";
import Calender from "./Calender";
import ScheduleNotification from "./ScheduleNotification";
import GetMobileApp from "./GetMobileApp";

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  const matchesXs = useMediaQuery((theme) => theme.breakpoints.down("md"));

  return (
    <>
      {!matchesXs && <Search />}
      {matchesXs && <Box sx={{ width: "100%", ml: 1 }} />}
      {/* <Calender /> */}
      {/* <GetMobileApp /> */}
      <Notification />
      <ScheduleNotification />
      {!matchesXs && <Profile />}
      {matchesXs && <MobileSection />}
    </>
  );
};

export default HeaderContent;
