import React from 'react';
import { Modal, Table } from 'antd';

function TableInModal({ isVisible, title, handleOk, handleCancel, sourceData, columnsData,width }) {
  return (
    <Modal
      visible={isVisible}
      title={title}
      width={width? width:800}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
    >
      <div className="mt-4">
        <Table
          dataSource={sourceData}
          columns={columnsData}
          pagination={false}
          showHeader={true}
        />
      </div>
    </Modal>
  );
}

export default TableInModal;