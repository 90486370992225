import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Badge,
  Box,
  ClickAwayListener,
  Divider,
  IconButton,
  ListItem,
  List,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Paper,
  Popper,
  Typography,
  useMediaQuery,
  Stack,
  Tooltip,
  Tab,
  Tabs,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { Typography as Typography1 } from "antd";
// project import
import Transitions from "components/@extended/Transitions";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
// assets

import {
  bp_get_batch_notification_for_employee,
  get_api_function,
  get_ingredient_value_alert,
  get_notification,
} from "utils/api";
import { useNavigate } from "react-router";

// sx styles
const avatarSX = {
  width: 36,
  height: 36,
  fontSize: "1rem",
};

const actionSX = {
  mt: "6px",
  ml: 1,
  top: "auto",
  right: "auto",
  alignSelf: "flex-start",
  transform: "none",
};

const deviation_color = (per) => {
  let color;
  let bg_color;
  color = "green";
  bg_color = "#CCFFCC ";
  return { color: color, bg_color: bg_color };
};

// ==============================|| HEADER CONTENT - NOTIFICATION ||============================== //

function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`profile-tabpanel-${index}`}
      aria-labelledby={`profile-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const ScheduleNotification = () => {
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down("md"));

  const navigate = useNavigate();

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [notificationsData, setNotificationsData] = useState([]);

  useEffect(() => {
    const fetchNotifications = () => {
      get_api_function("/get_schedule_notification", (res) => {
        setNotificationsData(res?.data?.data);
      });
    };

    fetchNotifications();
    const intervalId = setInterval(fetchNotifications, 60000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const iconBackColorOpen = "grey.300";
  const iconBackColor = "grey.100";

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <Tooltip title="Schedule Notification">
        <IconButton
          disableRipple
          color="secondary"
          sx={{
            color: "text.primary",
            bgcolor: open ? iconBackColorOpen : iconBackColor,
          }}
          aria-label="open profile"
          ref={anchorRef}
          aria-controls={open ? "profile-grow" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          <Badge color="primary" badgeContent={notificationsData?.length}>
            <EmailOutlinedIcon />
          </Badge>
        </IconButton>
      </Tooltip>
      <Popper
        placement={matchesXs ? "bottom" : "bottom-end"}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [matchesXs ? -5 : 0, 9],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="fade" in={open} {...TransitionProps}>
            <Paper
              sx={{
                boxShadow: theme.customShadows.z1,
                width: "100%",
                minWidth: 285,
                maxWidth: 420,
                [theme.breakpoints.down("md")]: {
                  maxWidth: 285,
                },
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <List
                  component="nav"
                  sx={{
                    p: 0,
                    "& .MuiListItemButton-root": {
                      py: 0.5,
                      "& .MuiAvatar-root": avatarSX,
                      "& .MuiListItemSecondaryAction-root": {
                        ...actionSX,
                        position: "relative",
                      },
                    },
                  }}
                >
                  {notificationsData.slice(0, 5).map((item, index) => (
                    <React.Fragment key={index}>
                      <ListItemButton>
                        <ListItemAvatar>
                          <Avatar
                            sx={{
                              color: deviation_color(item?.quantity_deviation)
                                ?.color,
                              bgcolor: deviation_color(item?.quantity_deviation)
                                ?.bg_color,
                            }}
                          >
                            {item.recipe_name
                              .split(" ")
                              .map((word) => word[0])
                              .join("")
                              .toUpperCase()}
                          </Avatar>
                        </ListItemAvatar>
                        <Stack>
                          <ListItemText
                            primary={
                              <Typography1.Paragraph
                                ellipsis={{
                                  rows: 2,
                                  expandable: true,
                                  symbol: "more",
                                }}
                              >
                                {item?.msg}
                              </Typography1.Paragraph>
                            }
                            secondary={
                              <Stack
                                direction="row"
                                justifyContent="space-between"
                                spacing={0.5}
                              >
                                <Stack>
                                  <div>{`Recipe : ${item?.recipe_name}`}</div>
                                  <div>{`Batch : ${item?.batch_number}`}</div>
                                  <div>{`Process : ${item?.process_name}`}</div>
                                </Stack>
                              </Stack>
                            }
                          />
                        </Stack>
                        <ListItemSecondaryAction>
                          <Stack>
                            <Typography variant="caption" noWrap>
                              {item?.batch_schedule_time?.slice(0, 10)}
                            </Typography>
                            <Typography variant="caption" noWrap>
                              {item?.batch_schedule_time?.slice(11, 19)}
                            </Typography>
                          </Stack>
                        </ListItemSecondaryAction>
                      </ListItemButton>
                      <Divider />
                    </React.Fragment>
                  ))}

                  <Divider />
                  <ListItemButton
                    sx={{ textAlign: "center", py: `${12}px !important` }}
                    onClick={() => {
                      navigate("/messages");
                      setOpen(false);
                    }}
                  >
                    <ListItemText
                      primary={
                        <Typography variant="h6" color="primary">
                          View All
                        </Typography>
                      }
                    />
                  </ListItemButton>
                </List>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </Box>
  );
};

export default ScheduleNotification;
