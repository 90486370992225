import React, { useEffect, useState } from "react";
import { Badge, Calendar, Tag } from "antd";
import { Tooltip, Stack, Button } from "@mui/material";
import MainCard from "components/MainCard";
import {  useNavigate } from "react-router-dom";
import "dayjs/locale/zh-cn";
import { Col, Radio, Row, Select } from "antd";
import SquareIcon from "@mui/icons-material/Square";

const Calender = () => {
  const navigate = useNavigate();
  const [calender_data, set_calender_data] = useState([]);
  const success_PendingData_get = (res) => {
    set_calender_data(res.data.data);
    console.log("res.data.data", res.data.data);
  };

  useEffect(() => {
    // machine_calender(success_PendingData_get);
  }, []);

  const getListData = (data) => {
    const year = data.$y;
    const month = data.$M + 1 < 10 ? `0${data.$M + 1}` : data.$M + 1;
    const day = data.$D < 10 ? `0${data.$D}` : data.$D;

    return calender_data?.filter((item) => {
      return item.datetime?.slice(0, 10) === `${year}-${month}-${day}`;
    });
  };

  const dateCellRender = (value) => {
    const listData = getListData(value);
    return (
      <Stack spacing={0.5}>
        {listData.map((item) => (
          <Tooltip title={``} placement="right">
            <Tag color={item?.status === true && "#00C853"}>{item?.label}</Tag>
          </Tooltip>
        ))}
      </Stack>
    );
  };
  const cellRender = (current, info) => {
    if (info.type === "date") return dateCellRender(current);
    return info.originNode;
  };
  return (
    <MainCard>
      <Calendar
        headerRender={({ value, type, onChange, onTypeChange }) => {
          const start = 0;
          const end = 12;
          const monthOptions = [];
          let current = value.clone();
          const localeData = value.localeData();
          const months = [];
          for (let i = 0; i < 12; i++) {
            current = current.month(i);
            months.push(localeData.monthsShort(current));
          }
          for (let i = start; i < end; i++) {
            monthOptions.push(
              <Select.Option key={i} value={i} className="month-item">
                {months[i]}
              </Select.Option>
            );
          }
          const year = value.year();
          const month = value.month();
          const options = [];
          for (let i = year - 10; i < year + 10; i += 1) {
            options.push(
              <Select.Option key={i} value={i} className="year-item">
                {i}
              </Select.Option>
            );
          }
          return (
            <div
              style={{
                padding: 8,
              }}
            >
              <Row gutter={8} align="middle">
                <Col>
                  <Radio.Group
                    size="small"
                    onChange={(e) => onTypeChange(e.target.value)}
                    value={type}
                  >
                    <Radio.Button value="month">Month</Radio.Button>
                    <Radio.Button value="year">Year</Radio.Button>
                  </Radio.Group>
                </Col>
                <Col>
                  <Select
                    size="small"
                    dropdownMatchSelectWidth={false}
                    className="my-year-select"
                    value={year}
                    onChange={(newYear) => {
                      const now = value.clone().year(newYear);
                      onChange(now);
                    }}
                  >
                    {options}
                  </Select>
                </Col>
                <Col>
                  <Select
                    size="small"
                    dropdownMatchSelectWidth={false}
                    value={month}
                    onChange={(newMonth) => {
                      const now = value.clone().month(newMonth);
                      onChange(now);
                    }}
                  >
                    {monthOptions}
                  </Select>
                </Col>
                <Col
                  lg={19}
                  style={{
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <Button
                    disabled={true}
                    startIcon={<SquareIcon style={{ color: "#2196F3" }} />}
                  >
                    LEGEND 1
                  </Button>
                  <Button
                    disabled={true}
                    startIcon={<SquareIcon style={{ color: "#F9A825" }} />}
                  >
                    LEGEND 2
                  </Button>
                  <Button
                    disabled={true}
                    startIcon={<SquareIcon style={{ color: "#00C853" }} />}
                  >
                    LEGEND 3
                  </Button>
                </Col>
              </Row>
            </div>
          );
        }}
        cellRender={cellRender}
        fullscreen={true}
      />
    </MainCard>
  );
};
export default Calender;
