import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

// material-ui
import MuiBreadcrumbs from "@mui/material/Breadcrumbs";
import { Grid, Typography, Button, useMediaQuery } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

// project imports
import MainCard from "../MainCard";
import { useTranslation } from "react-i18next";

// ==============================|| BREADCRUMBS ||============================== //

const Breadcrumbs = ({ navigation, title, ...others }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [main, setMain] = useState();
  const [item, setItem] = useState();
  const [refresh, setRefresh] = useState(true);
  const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up("lg"));

  // set active item state
  const getCollapse = (menu) => {
    if (menu.children) {
      menu.children.filter((collapse) => {
        if (collapse?.type && collapse?.type === "collapse") {
          getCollapse(collapse);
        } else if (collapse?.type && collapse?.type === "item") {
          if (location.pathname === collapse.url) {
            setMain(menu);
            setItem(collapse);
            localStorage.setItem("menu", JSON.stringify(menu));
            localStorage.setItem("item", JSON.stringify(collapse));
          } else {
            setRefresh(false);
          }
        }
        return false;
      });
    }
  };

  useEffect(() => {
    navigation?.items?.map((menu) => {
      if (menu.type && menu.type === "group") {
        getCollapse(menu);
      }
      if (refresh === false) {
        setMain(JSON.parse(localStorage.getItem("menu")));
        setItem(JSON.parse(localStorage.getItem("item")));
        setRefresh(true);
      }
      return false;
    });
  }, [navigation]);

  // only used for component demo breadcrumbs
  if (location.pathname === "/breadcrumbs") {
    location.pathname = "/dashboard/analytics";
  }

  let mainContent;
  let itemContent;
  let breadcrumbContent = <Typography />;
  let itemTitle = "";

  // collapse item
  if (main && main.type === "collapse") {
    mainContent = (
      <Typography
        component={Link}
        to={main.children[0].url}
        variant="h6"
        sx={{ textDecoration: "none" }}
        color="textSecondary"
      >
        {main.title}
      </Typography>
    );
  }

  // items
  if (item && item.type === "item") {
    itemTitle = item.title;
    itemContent = (
      <Typography variant="subtitle1" color="textPrimary">
        {itemTitle}
      </Typography>
    );

    // main
    if (item.breadcrumbs !== false) {
      breadcrumbContent = (
        <MainCard
          border={false}
          sx={{ mb: 1, bgcolor: "transparent" }}
          {...others}
          content={false}
        >
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{ mt: 1 }}
          >
            <Grid item>
              <MuiBreadcrumbs aria-label="breadcrumb">
                <Typography
                  component={Link}
                  to="/viewRecipe"
                  color="textSecondary"
                  variant="h6"
                  sx={{ textDecoration: "none" }}
                >
                  {t("Home")}
                </Typography>
                {mainContent}
                {itemContent}
              </MuiBreadcrumbs>
            </Grid>
            {/* <Grid
              item
              container
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              {title && (
                <Grid item sx={{ mt: 1 }}>
                  <Typography variant="h5">{item.title}</Typography>
                </Grid>
              )} */}
            {/* <Button
                size="medium"
                variant="outlined"
                onClick={(e) => {
                  e.preventDefault();
                  navigate(-1);
                }}
                startIcon={<ArrowBackIcon />}
              >
                {isLargeScreen ? "Back" : ''}
              </Button> */}
            {/* </Grid> */}
          </Grid>
        </MainCard>
      );
    }
  }

  return breadcrumbContent;
};

Breadcrumbs.propTypes = {
  navigation: PropTypes.object,
  title: PropTypes.bool,
};

export default Breadcrumbs;
