import { createSlice } from "@reduxjs/toolkit";

const setabSlice = createSlice({
  name: "tab_number",
  initialState: "1",
  reducers: {
    settab_number: (state, action) => {
      return action.payload;
    },
  },
});

export const { settab_number } = setabSlice.actions;
export default setabSlice.reducer;
