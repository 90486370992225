import React, { useState, useEffect } from "react";
import MainCard from "components/MainCard";
import { Table } from "antd";
import {
  bp_get_batch_process_active,
  bp_get_batch_process_schedule,
} from "utils/api";
import { IconButton, Button } from "@mui/material";
import StartOutlinedIcon from "@mui/icons-material/StartOutlined";
import { useNavigate } from "react-router-dom";

const Batch = () => {
  const [user_access_data, set_user_access_data] = useState([]);

  useEffect(() => {
    set_user_access_data(JSON.parse(localStorage.getItem("user_access_data")));
  }, []);

  const navigate = useNavigate();
  const [operation_data, set_operation_data] = useState([]);

  const get_data = async () => {
    try {
      await bp_get_batch_process_active(success_bp_get_batch_process_active);
      //   await bp_get_batch_process_schedule(success_bp_get_batch_process_active);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const success_bp_get_batch_process_active = (res) => {
    set_operation_data(res?.data?.data);
    console.log("operation_data", res?.data?.data);
  };

  useEffect(() => {
    get_data();
  }, []);

  const get_add_routes = () => {
    return user_access_data?.find((item) => item.module_name === "Batch")
      ?.create_access
      ? [
          {
            title: "Ingredients",
            dataIndex: "",
            key: "ingredients",
            align: "center",
            render: (_, record) => {
              return (
                record?.ingredients?.length > 0 &&
                record?.ingredients?.some(
                  (item) => item.ingredient_qty === ""
                ) && (
                  <IconButton
                    onClick={() => {
                      navigate("/ingredients", {
                        state: {
                          operation_data: record,
                        },
                      });
                    }}
                  >
                    <StartOutlinedIcon color="primary" />
                  </IconButton>
                )
              );
            },
          },
          {
            title: "Parameters",
            dataIndex: "",
            key: "parameters",
            align: "center",
            render: (_, record) => {
              return (
                record?.paramaters?.length > 0 && (
                  <IconButton
                    onClick={() => {
                      navigate("/parameters", {
                        state: {
                          operation_data: record,
                        },
                      });
                    }}
                  >
                    <StartOutlinedIcon color="primary" />
                  </IconButton>
                )
              );
            },
          },
          {
            title: "Product",
            dataIndex: "",
            align: "center",
            key: "product",
            render: (_, record) => {
              return (
                record?.products?.length > 0 && (
                  <IconButton
                    onClick={() => {
                      navigate("/product", {
                        state: {
                          operation_data: record,
                        },
                      });
                    }}
                  >
                    <StartOutlinedIcon color="primary" />
                  </IconButton>
                )
              );
            },
          },
          {
            title: "Sampling",
            dataIndex: "",
            align: "center",
            key: "product",
            render: (_, record) => {
              return (
                record?.products?.length > 0 && (
                  <IconButton
                    onClick={() => {
                      navigate("/sampling", {
                        state: {
                          operation_data: record,
                        },
                      });
                    }}
                  >
                    <StartOutlinedIcon color="primary" />
                  </IconButton>
                )
              );
            },
          },
        ]
      : [];
  };

  const batchColumns = [
    {
      title: "Batch",
      dataIndex: "batch_number",
      key: "batch_number",
    },
  ];

  const processColumns = [
    { title: "Process", dataIndex: "process_name", key: "process_name" },
  ];

  const operationColumns = [
    {
      title: "Operation",
      dataIndex: "operation_number",
      key: "operation_number",
      render: (_, record) =>
        `${record?.operation_type}-${record?.operation_number}`,
    },
    {
      title: "Instruction",
      dataIndex: "instruction",
      key: "instruction",
    },
    {
      title: "Minimum Duration",
      dataIndex: "min_duration",
      key: "min_duration",
    },
    {
      title: "Maximum Duration",
      dataIndex: "max_duration",
      key: "max_duration",
    },
    ...get_add_routes(),
  ];

  const expandableRowRender = (record) => {
    return (
      <Table
        columns={operationColumns}
        dataSource={record.operations}
        bordered
        size="small"
        pagination={false}
        rowKey={(record) => record.operation_id}
      />
    );
  };

  const expandableProcessRowRender = (record) => {
    return (
      <Table
        columns={processColumns}
        dataSource={record.process}
        bordered
        size="small"
        expandable={{
          expandedRowRender: expandableRowRender,
          rowExpandable: (record) =>
            record.operations && record.operations.length > 0,
        }}
        pagination={false}
        rowKey={(record) => record.process_id}
      />
    );
  };

  return (
    <MainCard>
      <Table
        columns={batchColumns}
        dataSource={operation_data}
        bordered
        size="small"
        expandable={{
          expandedRowRender: expandableProcessRowRender,
          rowExpandable: (record) =>
            record.process && record.process.length > 0,
        }}
        pagination={false}
        rowKey={(record) => record.batch_id}
      />
    </MainCard>
  );
};

export default Batch;
