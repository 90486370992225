import { createSlice } from "@reduxjs/toolkit";

const notification_refreshSlice = createSlice({
  name: "notification_refresh",
  initialState: false,
  reducers: {
    set_notification_refresh: (state, action) => {
      return action.payload;
    },
  },
});

export const { set_notification_refresh } = notification_refreshSlice.actions;
export default notification_refreshSlice.reducer;
