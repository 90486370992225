import React, { useEffect, useState, useRef } from "react";
import {
  bp_add_meta_data,
  bp_get_machine_by_machine_typ,
  bp_machine_data,
  bp_save_batch_ingredients,
} from "../../../utils/api";
import { toast } from "react-toastify";

import {
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
  Grid,
  Select,
  MenuItem,
  Button,
  InputAdornment,
  IconButton,
  Avatar,
} from "@mui/material";
import MainCard from "components/MainCard";
import { useDispatch, useSelector } from "react-redux";
import { settab_number } from "store/reducers/tabNumber";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { Badge, Descriptions } from "antd";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

function AddIngredient() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const operation_data = state?.operation_data;

  const dispatch = useDispatch();
  const [batch_no, set_batch_no] = useState("2");
  const [operation, set_operation] = useState("Operation 1");
  const [machine, set_machine] = useState("i");
  const [time, set_time] = useState(dayjs());
  const [ingredient, set_ingredient] = useState([]);

  const handle_ingredient = (ingredient_id, e, index) => {
    if (e.target.value === "") {
      let temp = [...ingredient];
      temp.splice(index, 1);
      set_ingredient(temp);
    } else {
      let temp = [...ingredient];
      temp[index] = {
        process_ingredient_id: ingredient_id,
        quantity: e.target.value,
      };
      set_ingredient(temp);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    submit_function(ingredient);
  };

  //####################### Handle Api's######################################################
  const [machine_data, setMachineData] = useState([]);
  const get_all_machine = async () => {
    try {
      await bp_get_machine_by_machine_typ(success_machine_list_get, {
        machine_type_id: operation_data?.machine_id,
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const success_machine_list_get = (res) => {
    setMachineData(res?.data?.data);
  };

  useEffect(() => {
    get_all_machine();
  }, [operation_data]);

  const submit_function = async (process_ingredient) => {
    try {
      await bp_save_batch_ingredients(
        {
          process_ingredient: process_ingredient?.filter(Boolean),
          operation_id: operation_data?.operation_id,
          machine_id: machine,
          batch_number: operation_data?.batch_number,
        },
        success_bp_save_batch_ingredients
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const success_bp_save_batch_ingredients = (res) => {
    if (res.status === "failed") {
      toast.warning(res.message);
    } else {
      resetFunction();
      navigate("/batch");
      toast.success("Metadata added Successfully");
    }
  };

  const resetFunction = () => {
    set_ingredient([]);
    set_machine("");
  };

  const handle_single_add = (index) => {
    let temp = ingredient[index];
    submit_function([temp]);
  };

  return (
    <MainCard darkTitle title="Ingredients" backbutton>
      <form onSubmit={handleSubmit}>
        <Descriptions
          title=""
          // layout="vertical"
          bordered
          column={2}
          contentStyle={{
            fontSize: "24px",
          }}
          labelStyle={{
            fontSize: "32px",
            fontWeight: "bold",
          }}
          items={[
            {
              key: "5",
              label: "Instruction",
              children: operation_data?.instruction,
              span: 2,
            },
            {
              key: "1",
              label: "Batch No",
              children: operation_data?.batch_number,
            },
            {
              key: "2",
              label: "Operation",
              children: `${operation_data?.operation_type} - ${operation_data?.operation_number}`,
            },
            {
              key: "3",
              label: "Machine",
              children: (
                <Select
                  value={machine}
                  size="medium"
                  required
                  fullWidth
                  onChange={(e) => set_machine(e.target.value)}
                  style={{
                    fontSize: "24px",
                  }}
                >
                  <MenuItem value="i" disabled>
                    <em> Select Machine</em>
                  </MenuItem>
                  {machine_data?.map((item) => (
                    <MenuItem value={item.machine_id}>
                      {item.machine_name}
                    </MenuItem>
                  ))}
                </Select>
              ),
            },
            {
              key: "4",
              label: "Duration",
              children: operation_data?.max_duration,
            },
          ]}
        />
        <Grid
          container
          rowSpacing={10}
          sx={{ my: 0 }}
          direction="row"
          justifyContent="center"
        >
          <Grid container item lg={12} spacing={5}>
            {operation_data?.ingredients?.map((item, index) => (
              <Grid container item sx={12} lg={12}>
                <Grid item xs={12} lg={4}>
                  <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ height: "100%" }}
                  >
                    <InputLabel sx={{ fontSize: "24px" }} htmlFor="title">
                      {item?.ingredient_name}
                    </InputLabel>
                  </Stack>
                </Grid>
                <Grid item xs={12} lg={8}>
                  <Stack spacing={2} alignItems="center" direction="row">
                    {item?.ingredient_qty === "" ? (
                      <OutlinedInput
                        id="title"
                        type="number"
                        name="name"
                        fullWidth
                        value={ingredient[index]?.quantity}
                        onChange={(e) => {
                          handle_ingredient(item?.ingredient_id, e, index);
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            <Avatar
                              variant="square"
                              sx={{
                                color: "#000",
                                bgcolor: "#fff",
                              }}
                            >
                              {`${item?.unit}`}
                            </Avatar>
                          </InputAdornment>
                        }
                        inputProps={{
                          style: {
                            height: "50px",
                            fontSize: "24px",
                          },
                        }}
                      />
                    ) : (
                      <OutlinedInput
                        id="title"
                        type="number"
                        name="name"
                        fullWidth
                        disabled
                        value={item?.ingredient_qty}
                        endAdornment={
                          <InputAdornment position="end">
                            <Avatar
                              variant="square"
                              sx={{
                                color: "#000",
                                bgcolor: "#fff",
                              }}
                            >
                              {`${item?.unit}`}
                            </Avatar>
                          </InputAdornment>
                        }
                        inputProps={{
                          style: {
                            height: "50px",
                            fontSize: "24px",
                          },
                        }}
                      />
                    )}

                    <Button
                      size="medium"
                      variant="contained"
                      color="primary"
                      sx={{
                        height: "50px",
                        fontSize: "24px",
                      }}
                      onClick={() => {
                        handle_single_add(index);
                      }}
                    >
                      Add
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            ))}

            <Grid item xs={12} lg={12}>
              <Stack
                direction="row"
                alignItems="end"
                justifyContent="end"
                spacing={2}
              >
                {/* <Button
                  size="medium"
                  type="reset"
                  variant="contained"
                  color="primary"
                  onClick={resetFunction}
                  sx={{
                    height: "50px",
                    fontSize: "24px",
                  }}
                >
                  Reset
                </Button> */}
                <Button
                  size="medium"
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{
                    height: "50px",
                    fontSize: "24px",
                  }}
                >
                  Submit
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </MainCard>
  );
}

export default AddIngredient;
