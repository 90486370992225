import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactFlow, {
  addEdge,
  useNodesState,
  useEdgesState,
  Background,
  BackgroundVariant,
  ReactFlowProvider,
  useStoreApi,
  MarkerType,
  Controls,
  Panel,
  useReactFlow,
  ControlButton,
} from "reactflow";
import "reactflow/dist/style.css";
import "../../assets/styles/flow.css";
import { Handle, Position } from "reactflow";
import { Stack, IconButton, Tooltip } from "@mui/material";
import { Badge, Typography } from "antd";
import { get_api_function } from "utils/api";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FitScreenIcon from "@mui/icons-material/FitScreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";

const MIN_DISTANCE = 150;

const nodeDefaults = {
  sourcePosition: Position.Right,
  targetPosition: Position.Left,
  style: {
    color: "#333",
    width: "fit-content",
    maxWidth: "250px",
    height: "70px",
    fontFamily: "sans-serif",
  },
};

const color_json = {
  Completed: "#28A745",
  "In Progress": "#ff6d00",
  Pending: "#1e90ff",
};

const getNeonShadow = (status) => {
  switch (status) {
    case "In Progress":
      return "#cce5ff 0px 3px 6px, #cce5ff 0px 3px 6px";
    case "Pending":
      return "#fff3cd 0px 3px 6px, #fff3cd 0px 3px 6px";
    case "Completed":
      return "#d4edda 0px 3px 6px, #d4edda 0px 3px 6px";
    default:
      return "#ccc 0px 3px 6px, #ccc 0px 3px 6px";
  }
};

const getStatusStyles = (status) => {
  switch (status) {
    case "In Progress":
      return {
        // backgroundColor: "#ffe0b2", // Light orange background (existing)
        backgroundColor: "#ff6d00", // Light orange background (existing)
        // color: "#ff6d00", // Darker orange text color (existing)
        color: "#fff", // Darker orange text color (existing)
        // borderColor: "#ffa726", // Orange border color
        border: "0.5px outset #ffa726", // Outset border with orange color
        // filter:
        //   "brightness(0) saturate(100%) invert(53%) sepia(60%) saturate(4130%) hue-rotate(360deg) brightness(99%) contrast(103%)",
      };
    case "Pending":
      return {
        // backgroundColor: "#cfe2f3", // Light bluish background
        backgroundColor: "#1e90ff", // Light bluish background
        // color: "#1e90ff", // Darker bluish text color
        color: "#fff", // Darker orange text color (existing)
        // borderColor: "#b0cfe3", // Bluish border color
        border: "0.5px outset #b0cfe3", // Outset border with bluish color
        // filter:
        //   "brightness(0) saturate(100%) invert(43%) sepia(79%) saturate(2386%) hue-rotate(193deg) brightness(101%) contrast(107%)",
      };
    case "Completed":
      return {
        // backgroundColor: "#d4edda", // Light green background (existing)
        backgroundColor: "#28A745", // Light green background (existing)
        // color: "#28A745", // Dark green text color (existing)
        color: "#fff", // Darker orange text color (existing)
        // borderColor: "#c3e6cb", // Green border color
        border: "0.5px outset #c3e6cb", // Outset border with green color
        // filter:
        //   "brightness(0) saturate(100%) invert(48%) sepia(100%) saturate(362%) hue-rotate(82deg) brightness(91%) contrast(90%)",
      };
    default:
      return {};
  }
};

const edgeStyle = (targetNodeId, nodes) => {
  const targetNode = nodes?.find((item) => item?.id === targetNodeId);
  const status = targetNode?.data?.status;

  switch (status) {
    case "In Progress":
      return {
        stroke: "#ff6d00", // Orange color for In Progress
        strokeWidth: 2,
      };
    case "Pending":
      return {
        stroke: "#1e90ff", // Bluish color for Pending
        strokeWidth: 2,
      };
    case "Completed":
      return {
        stroke: "#28A745", // Green color for Completed
        strokeWidth: 2,
      };
    default:
      return {
        stroke: "#888", // Default color
        strokeWidth: 2,
      };
  }
};

export const CustomNode = ({ data }) => {
  const styles = getStatusStyles(data?.status);
  const neonShadow = getNeonShadow(data?.status);

  return (
    <div
      style={{
        ...nodeDefaults.style,
        ...styles,
        // boxShadow: neonShadow,
      }}
    >
      {/* <img src={MixingandBlending} alt="MixingandBlending" height={70} /> */}
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{ height: "100%", cursor: "pointer" }}
      >
        <Typography
          ellipsis={{ rows: 2, expandable: true, symbol: "more" }}
          style={{ color: styles.color }}
        >
          {data?.process}
        </Typography>
        {data?.operation && (
          <Typography.Text
            italic
            ellipsis={{ rows: 2, expandable: true, symbol: "more" }}
            style={{ color: styles.color }}
          >
            ({data?.operation})
          </Typography.Text>
        )}
      </Stack>

      <Handle
        type="target"
        position={Position.Left}
        style={{ borderRadius: 0, background: "none" }}
      />
      <Handle
        type="source"
        position={Position.Right}
        style={{ borderRadius: 0, background: "none" }}
      />
    </div>
  );
};

const Flow = ({ batch, data, dag_nodes, view_operation_function }) => {
  const store = useStoreApi();
  const onNodeClick = (event, node) => {
    // view_operation_function({ process_id: node?.data?.process_id }, batch);
  };
  const handle = useFullScreenHandle();

  const [nodes, setNodes, onNodesChange] = useNodesState([]);

  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  const onConnect = useCallback(
    (params) =>
      setEdges((eds) =>
        addEdge({ ...params, arrowHeadType: "arrowclosed" }, eds)
      ),
    [setEdges]
  );

  const getClosestEdge = useCallback((node) => {
    const { nodeInternals } = store.getState();
    const storeNodes = Array.from(nodeInternals.values());

    const closestNode = storeNodes.reduce(
      (res, n) => {
        if (n.id !== node.id) {
          const dx = n.positionAbsolute.x - node.positionAbsolute.x;
          const dy = n.positionAbsolute.y - node.positionAbsolute.y;
          const d = Math.sqrt(dx * dx + dy * dy);

          if (d < res.distance && d < MIN_DISTANCE) {
            res.distance = d;
            res.node = n;
          }
        }
        return res;
      },
      {
        distance: Number.MAX_VALUE,
        node: null,
      }
    );

    if (!closestNode.node) {
      return null;
    }

    const closeNodeIsSource =
      closestNode.node.positionAbsolute.x < node.positionAbsolute.x;

    return {
      id: closeNodeIsSource
        ? `${closestNode.node.id}-${node.id}`
        : `${node.id}-${closestNode.node.id}`,
      source: closeNodeIsSource ? closestNode.node.id : node.id,
      target: closeNodeIsSource ? node.id : closestNode.node.id,
      arrowHeadType: "arrowclosed",
    };
  }, []);

  const onNodeDrag = useCallback(
    (_, node) => {
      const closeEdge = getClosestEdge(node);

      setEdges((es) => {
        const nextEdges = es.filter((e) => e.className !== "temp");

        if (
          closeEdge &&
          !nextEdges.find(
            (ne) =>
              ne.source === closeEdge.source && ne.target === closeEdge.target
          )
        ) {
          closeEdge.className = "temp";
          nextEdges.push(closeEdge);
        }

        return nextEdges;
      });
    },
    [getClosestEdge, setEdges]
  );

  const onNodeDragStop = useCallback(
    (_, node) => {
      const closeEdge = getClosestEdge(node);

      setEdges((es) => {
        const nextEdges = es.filter((e) => e.className !== "temp");

        if (
          closeEdge &&
          !nextEdges.find(
            (ne) =>
              ne.source === closeEdge.source && ne.target === closeEdge.target
          )
        ) {
          nextEdges.push(closeEdge);
        }

        return nextEdges;
      });
    },
    [getClosestEdge]
  );

  const nodeTypes = { customNode: CustomNode };

  const { fitView, setViewport, setCenter, zoomTo } = useReactFlow();
  const divRef = useRef(null);
  const handleFitView = () => {
    if (divRef.current) {
      // const containerHeight = divRef.current.clientHeight;
      // const centerY = containerHeight / 2;
      fitView();

      // fitView({
      //   padding: 5,
      //   nodes: [{ id: "86" }, { id: "87" }, { id: "88" }, { id: "89" }],
      // });
    }
  };
  useEffect(() => {
    fitView({
      padding: 5,
      nodes: dag_nodes?.map((ids) => ({ id: ids })),
    });
  }, [dag_nodes]);

  useEffect(() => {
    get_api_function(`/get_batch_drag_node?batch_number=${batch}`, (res) => {
      if (res?.data?.status === "success") {
        const temp_nodes = res?.data?.data?.nodes?.map((item) => ({
          ...item,
          type: "customNode",
          ...nodeDefaults,
        }));
        setNodes(
          res?.data?.data?.nodes?.map((item) => ({
            ...item,
            type: "customNode",
            ...nodeDefaults,
          }))
        );
        setEdges(
          res?.data?.data?.edges?.map((item) => ({
            ...item,
            type: "step",
            style: edgeStyle(item?.source, temp_nodes),
            markerEnd: {
              type: MarkerType.ArrowClosed,
              width: 20,
              height: 20,
              color: edgeStyle(item?.source, temp_nodes)?.stroke,
            },
          }))
        );
        setTimeout(() => {
          fitView();
        }, 200);
      }
    });
  }, [batch]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      // fitView();
      fitView();
    });

    if (divRef.current) {
      resizeObserver.observe(divRef.current);
    }

    return () => {
      if (divRef.current) {
        resizeObserver.unobserve(divRef.current);
      }
    };
  }, [fitView]);

  return (
    <FullScreen handle={handle}>
      <div
        ref={divRef}
        style={{
          height: handle.active ? "100vh" : "20vh",
          minHeight: "20vh",
          maxHeight: handle.active ? "100vh" : "60vh",
          resize: "vertical",
          overflow: "auto",
        }}
      >
        <ReactFlow
          // nodes={nodes}
          // edges={edges}
          nodes={[
            {
              id: "2",
              data: { label: "Process-ABC-001" },
              position: { x: 0, y: 0 },
              className: "light",
              sourcePosition: "right",
              targetPosition: "left",
              style: {
                backgroundColor: "rgba(255, 0, 0, 0.2)",
                width: 200,
                height: 50 + 4 * 50 + 4 * 20,
              },
            },
            {
              id: "2a",
              data: { label: "Cleaning" },
              position: { x: 0, y: 50 },
              parentId: "2",
              style: {
                backgroundColor: "rgba(255, 0, 0, 0.2)",
                width: 50,
                height: 50,
              },
            },
            {
              id: "2b",
              data: { label: "Charging" },
              position: { x: 0, y: 120 },
              parentId: "2",
              style: {
                backgroundColor: "rgba(255, 0, 0, 0.2)",
                width: 50,
                height: 50,
              },
            },
            {
              id: "2c",
              data: { label: "Cooling" },
              position: { x: 0, y: 190 },
              parentId: "2",
              style: {
                backgroundColor: "rgba(255, 0, 0, 0.2)",
                width: 50,
                height: 50,
              },
            },
            {
              id: "2d",
              data: { label: "Cooling" },
              position: { x: 0, y: 260 },
              parentId: "2",
              style: {
                backgroundColor: "rgba(255, 0, 0, 0.2)",
                width: 50,
                height: 50,
              },
            },
            // {
            //   id: "2e",
            //   data: { label: "Cooling" },
            //   position: { x: 0, y: 330 },
            //   parentId: "2",
            //   style: {
            //     backgroundColor: "rgba(255, 0, 0, 0.2)",
            //     width: 50,
            //     height: 50,
            //   },
            // },
            {
              id: "3",
              data: { label: "Process-ABC-0002" },
              position: { x: 300, y: 0 },
              className: "light",
              sourcePosition: "right",
              targetPosition: "left",
              style: {
                backgroundColor: "rgba(255, 0, 0, 0.2)",
                width: 200,
                height: 300,
              },
            },
            {
              id: "3a",
              data: { label: "Cleaning" },
              position: { x: 10, y: 70 },

              parentId: "3",
            },
            {
              id: "3b",
              data: { label: "Charging" },
              position: { x: 10, y: 140 },
              parentId: "3",
            },
            {
              id: "3c",
              data: { label: "Cooling" },
              position: { x: 10, y: 210 },
              parentId: "3",
            },
            {
              id: "4",
              data: { label: "Process-ABC-003" },
              position: { x: 600, y: 0 },
              className: "light",
              sourcePosition: "right",
              targetPosition: "left",
              style: {
                backgroundColor: "rgba(255, 0, 0, 0.2)",
                width: 200,
                height: 300,
              },
            },
            {
              id: "4a",
              data: { label: "Cleaning" },
              position: { x: 10, y: 70 },

              parentId: "4",
            },
            {
              id: "4b",
              data: { label: "Charging" },
              position: { x: 10, y: 140 },
              parentId: "4",
            },
            {
              id: "4c",
              data: { label: "Cooling" },
              position: { x: 10, y: 210 },
              parentId: "4",
            },
          ]}
          edges={[
            { id: "1", source: "1", target: "2" },
            { id: "2", source: "2a", target: "2b" },
            { id: "3", source: "2b", target: "2c" },
            { id: "4", source: "2", target: "3" },
            { id: "5", source: "3a", target: "3b" },
            { id: "6", source: "3b", target: "3c" },
            { id: "7", source: "3", target: "4" },
            { id: "8", source: "4a", target: "4b" },
            { id: "9", source: "4b", target: "4c" },
          ]}
          onConnect={onConnect}
          // onNodesChange={onNodesChange}
          // onEdgesChange={onEdgesChange}
          // onNodeDrag={onNodeDrag}
          // onNodeDragStop={onNodeDragStop}
          fitView
          onNodeClick={onNodeClick}
          nodeTypes={nodeTypes}
          style={{
            height: "100%",
            backgroundColor: "#FAFAFB",
          }}
          nodesDraggable={false}
          nodesConnectable={false}
        >
          <Controls
            showZoom={false}
            showFitView={false}
            showInteractive={false}
          >
            <Stack direction="column" justifyContent="center">
              {!handle.active ? (
                <Tooltip title="Enter Full Screen" placement="right">
                  <IconButton
                    onClick={() => {
                      handle.enter();
                      fitView();
                    }}
                    size="small"
                  >
                    <FullscreenIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title="Exit Full Screen" placement="right">
                  <IconButton
                    onClick={() => {
                      handle.exit();
                      fitView();
                    }}
                    size="small"
                  >
                    <FullscreenExitIcon />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip title="Fit View" placement="right">
                <IconButton
                  onClick={() => {
                    // fitView();
                    handleFitView();
                  }}
                  size="small"
                >
                  <FitScreenIcon />
                </IconButton>
              </Tooltip>
            </Stack>
          </Controls>
          <Background variant={false} />
          <Panel position="top-left">
            <Typography variant="h4">{`${data?.recipe_name} ${
              data?.batch_number ? "(" + data?.batch_number + ")" : ""
            }`}</Typography>
          </Panel>
          <Panel position="top-right">
            <Stack direction="row" spacing={0.5}>
              {Object.keys(color_json)?.map((item) => (
                <Badge color={color_json[item]} count={item} />
              ))}
            </Stack>
          </Panel>
        </ReactFlow>
      </div>
    </FullScreen>
  );
};

export default ({ data, dag_nodes, view_operation_function }) => (
  <ReactFlowProvider>
    <Flow
      batch={data?.batch_number}
      data={data}
      dag_nodes={dag_nodes}
      view_operation_function={view_operation_function}
    />
  </ReactFlowProvider>
);
