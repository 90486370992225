import MainCard from "components/MainCard";
import React, { useEffect, useRef, useState } from "react";
import {
  Steps,
  Descriptions,
  Table,
  Empty,
  Collapse,
  Modal,
  Badge,
  Checkbox,
  Popconfirm,
  Select as AntSelect,
} from "antd";
import {
  IconButton,
  Stack,
  Select,
  Typography,
  Chip,
  Tooltip,
  MenuItem,
  OutlinedInput,
  Button,
} from "@mui/material";
import {
  bp_update_employee_for_batch_number,
  get_api_function,
  get_batch_details_by_process_id,
  post_api_function,
  update_all_employee_for_process,
} from "utils/api";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import "../.././assets/styles/expand.css";
import BorderColorRoundedIcon from "@mui/icons-material/BorderColorRounded";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import { accessControlUtils } from "../../components/AccessControlUtils";

function OperationsSkeleton({
  show,
  setShow,
  scheduletime,
  tab,
  selected_recipe,
  assignStatus,
  setAssignStatus,
  current_batch,
  selectedProcessId,
  set_selectedProcessId,
  selectedProcessName,
  set_showSummaryIndex,
  bulk_assign_list,
}) {
  const accessData = accessControlUtils("Batches");
  const tab_value = tab || "";
  const { state } = useLocation();
  const { Panel } = Collapse;
  const [selectedEmp, setSelectedEmp] = useState([]);
  const [expanded, set_expanded] = useState([]);
  const [current_expand, set_current_expand] = useState();
  const [scheduleTimeStatus, setScheduleTimeStatus] = useState(
    scheduletime || []
  );
  const [report2, set_report2] = useState([]);
  const [employee, set_employee] = useState([]);
  const formatDate = (date) => {
    const dateObject = new Date(date);
    const formattedDateTime = `${dateObject.getFullYear()}-${(
      dateObject.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${dateObject
      .getDate()
      .toString()
      .padStart(2, "0")} ${dateObject
      .getHours()
      .toString()
      .padStart(2, "0")}:${dateObject
      .getMinutes()
      .toString()
      .padStart(2, "0")}:${dateObject
      .getSeconds()
      .toString()
      .padStart(2, "0")}`;
    return formattedDateTime;
  };

  const controllerRef = useRef();
  const [loading, set_loading] = useState(false);

  const get_operations_by_process_and_batch = () => {
    if (controllerRef.current) {
      controllerRef.current.abort();
    }
    controllerRef.current = new AbortController();
    const signal = controllerRef.current.signal;
    get_api_function(
      `/get_batch_process__details_by_schedule?batch_number=${current_batch}&process_id=${selectedProcessId}`,
      (res) => {
        set_report2(res.data.data);
        const reqList = res?.data?.data?.map((item, index) => {
          return {
            status: false,
            value: "",
            emp_status: "", // Update the key to the index converted to a string
          };
        });
        setSelectedEmp(res.data.data?.map((item) => item?.employee_id));
        setScheduleTimeStatus(reqList);
      },
      {},
      signal
    );
  };

  const get_batch_details_by_process = async () => {
    if (controllerRef.current) {
      controllerRef.current.abort();
    }
    controllerRef.current = new AbortController();
    const signal = controllerRef.current.signal;
    set_loading(true);
    try {
      await get_batch_details_by_process_id(
        (res) => {
          set_loading(false);
          if (res?.data?.status === "success") {
            if (res?.data?.data && res?.data?.data?.length > 0) {
              set_report2(res?.data?.data[0]?.operations);
              const reqList = res?.data?.data[0]?.operations?.map(
                (item, index) => {
                  return {
                    status: false,
                    value: "",
                    emp_status: "", // Update the key to the index converted to a string
                  };
                }
              );
              setSelectedEmp(
                res?.data?.data[0]?.operations?.map((item) => item?.employee_id)
              );
              setScheduleTimeStatus(reqList);
              setScheduleTimeStatus(reqList);
            } else {
              setScheduleTimeStatus([]);
            }
          }
        },
        {
          process_id: selectedProcessId,
          batch_number: current_batch,
        },
        signal
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (selectedProcessId) {
      tab === "1" && get_operations_by_process_and_batch();
      (tab === "2" || tab === "3") && get_batch_details_by_process();
    }
  }, [selectedProcessId]);

  // useEffect(() => {
  //   const reqList = report?.map((item, index) => {
  //     return {
  //       status: false,
  //       value: "",
  //       emp_status: "", // Update the key to the index converted to a string
  //     };
  //   });
  //   setScheduleTimeStatus(reqList);
  // }, [scheduletime]);

  // useEffect(() => {
  //   set_report2(report);
  // }, [report]);

  const assign_employee = async (assignReq) => {
    try {
      await bp_update_employee_for_batch_number(
        assignReq,
        success_assign_employee
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const success_assign_employee = (res) => {
    if (res.status === "failed") {
      toast.warning(res.message);
    } else {
      setAssignStatus(!assignStatus);
      toast.success(res.message);
      set_employee([]);
      tab === "1" && get_operations_by_process_and_batch();
      (tab === "2" || tab === "3") && get_batch_details_by_process();
      // handleExpand(true, {
      //   batch_number: current_batch,
      //   batch_id: currentExpandedbatchId,
      //   key: current_expand,
      // });
    }
  };
  // const ingredientStrings = (data, header) => {
  //   return data?.map((ingredient, index) => {
  //     let ingredientString = `${ingredient?.ingredient_name} `;
  //     if (header === "operation") {
  //       // Conditionally include the parameter with its min and max values if they exist
  //       ingredientString = `${ingredientString.split("(")[0]}`;
  //     } else {
  //       ingredientString += `${ingredient.ingredient_qty + " "} ${
  //         ingredient.conditinal_para
  //       } `;
  //       // if (ingredient.parameter_name !== "") {
  //       //   ingredientString += ` (${ingredient.parameter_name} with min ${ingredient.min_value}${ingredient.parameter_name_unit} and max ${ingredient.max_value}${ingredient.parameter_name_unit})`;
  //       // }
  //     }
  //     ingredientString += `${index === data?.length - 1 ? "" : ","}`;
  //     return ingredientString;
  //   });
  // };
  const ingredientStrings = (data, header) => {
    return data?.map((ingredient, index) => {
      let ingredientString = `${ingredient?.ingredient_name?.split("(")[0]} `;
      if (header === "operation") {
        // Conditionally include the parameter with its min and max values if they exist
        ingredientString += "";
        // ingredient.parameter_name && `(${ingredient.parameter_name}), `;"
      } else {
        ingredientString += `: ${
          ingredient.ingredient_name?.split("(")[1]?.replace(")", " #") + ""
        }`;
        if (ingredient.conditinal_para !== "") {
          ingredientString += `${ingredient.conditinal_para}`;
        } else {
          ingredientString += `-`;
        }
      }
      ingredientString += index === data?.length - 1 ? "" : ",";
      return ingredientString;
    });
  };

  const deviation_color = (per) => {
    let color;
    let bg_color;

    switch (true) {
      case per === "":
        break;
      case per >= 0 && per <= 5:
        color = "green";
        bg_color = "#CCFFCC ";
        break;
      case (per >= -10 && per < 0) || (per > 5 && per <= 50):
        color = "orange";
        bg_color = "#FFF59E";
        break;
      case per < -10 || per > 50:
        color = "red";
        bg_color = "#FFCCCC";
        break;
      default:
        color = "black";
    }
    return { color: color, bg_color: bg_color };
  };

  const handleExpand = (expanded, record) => {
    if (expanded) {
      set_current_expand(record?.key);
    } else {
      set_current_expand("");
    }
  };
  const addKeysInFilterDataForCollapse = (filterData) => {
    const updatedFilterList = filterData?.map((item, index) => {
      return {
        ...item,
        key: index.toString(), // Update the key to the index converted to a string
      };
    });
    return updatedFilterList;
  };

  const columns =
    report2 && report2?.length > 0 && report2[0]?.opNo
      ? [
          {
            title: "Op No",
            dataIndex: "opNo",
            key: "opNo",
          },
          {
            title: "Operation",
            dataIndex: "operation",
            key: "operation",
            render: (operation, record) => {
              return (
                <Badge
                  color={
                    record?.timeFrom !== "None" && record?.timeTo === "None"
                      ? "orange"
                      : record?.timeFrom !== "None" && record?.timeTo !== "None"
                      ? "green"
                      : "red"
                  }
                  text={operation}
                />
              );
            },
          },
          // {
          //   title: "Equipment",
          //   dataIndex: "equipmentNo",
          //   key: "equipmentNo",
          //   align: "center",
          //   render: (data, machine) => {
          //     let machineStr = "";
          //     if (
          //       machine?.detailsRemarks?.machines &&
          //       machine?.detailsRemarks?.machines.length > 0
          //     ) {
          //       machineStr = machine?.detailsRemarks?.machines
          //         .map((mac) => mac?.machine_name)
          //         .join(", ");
          //     } else {
          //       machineStr = "";
          //     }
          //     if (machineStr !== "") {
          //       return `${data}-(${machineStr})`;
          //     } else {
          //       return `${data}`;
          //     }
          //   },
          // },
          {
            title: "Used Machines",
            dataIndex: "used_machines",
            key: "used_machines",
            align: "center",
            render: (data, machine) => {
              let machineStr = "";
              if (
                machine?.detailsRemarks?.selected_machine &&
                machine?.detailsRemarks?.selected_machine.length > 0
              ) {
                machineStr = machine?.detailsRemarks?.selected_machine
                  .map((mac) => mac?.machine_name)
                  .join(", ");
              } else {
                machineStr = "";
              }
              return machineStr;
            },
          },
          {
            title: "From",
            dataIndex: "timeFrom",
            key: "timeFrom",
            align: "center",
            render: (timeFrom) => timeFrom?.slice(0, 19),
          },
          {
            title: "To",
            dataIndex: "timeTo",
            key: "timeTo",
            align: "center",
            render: (timeTo) => timeTo?.slice(0, 19),
          },
          {
            title: "Duration",
            dataIndex: "duration",
            key: "duration",
            align: "center",
            // render: (dataIndex) => dataIndex?.split(".")[0],
            render: (duration, record) => {
              let text = duration;
              let color = "black";

              if (duration === "" && record?.timeFrom !== "None") {
                text = <span style={{ color: "orange" }}>In Progress</span>;
              } else if (duration === "" && record?.timeFrom === "None") {
                text = <span style={{ color: "red" }}>Pending</span>;
              } else {
                text = <span>{duration?.split(".")[0]}</span>;
              }

              return text;
            },
          },
          {
            title: "Deviation",
            dataIndex: "time_deviation",
            key: "duration",
            render: (data) => {
              return (
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography variant="h5">Time:</Typography>
                  <Typography>{data}</Typography>
                </Stack>
              );
            },
          },
          ...(tab && tab === "2"
            ? [
                {
                  title: "Schedule Time",
                  dataIndex: "batch_schedule_time",
                  key: "schedule_time",
                  width: "20%",
                  align: "center",
                  render: (data, record, index) => {
                    if (scheduleTimeStatus[index]?.status) {
                      return (
                        <>
                          <Stack spacing={1}>
                            <OutlinedInput
                              id="des"
                              type="datetime-local"
                              size="small"
                              name="des"
                              fullWidth
                              inputProps={{
                                min:
                                  index === 0
                                    ? formatDate(data)
                                    : formatDate(
                                        report2[index - 1]?.schedule_date
                                      ), // for schedule nma efor operation1 should be greater than actual value and 2nd index should be greater than privious
                              }}
                              disabled={!scheduleTimeStatus[index]?.status}
                              //   onClick={() => setIsScheduledDateEdit(true)} // Set isEditing to true on click
                              onChange={(e) => {
                                const updatedScheduleTimeStatus = [
                                  ...scheduleTimeStatus,
                                ];
                                updatedScheduleTimeStatus[index].value =
                                  e.target.value;
                                setScheduleTimeStatus(
                                  updatedScheduleTimeStatus
                                );
                              }}
                            />
                            <Stack direction="row" spacing={1}>
                              <Tooltip title="Close">
                                <IconButton
                                  onClick={() => {
                                    const updatedScheduleTimeStatus = [
                                      ...scheduleTimeStatus,
                                    ];
                                    updatedScheduleTimeStatus[
                                      index
                                    ].status = false;
                                    updatedScheduleTimeStatus[index].value = "";
                                    setScheduleTimeStatus(
                                      updatedScheduleTimeStatus
                                    );
                                  }}
                                >
                                  <CancelIcon color="error" />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Save">
                                <IconButton
                                  disabled={!scheduleTimeStatus[index].value}
                                  onClick={() => {
                                    const updatedScheduleTimeStatus = [
                                      ...scheduleTimeStatus,
                                    ];
                                    updatedScheduleTimeStatus[
                                      index
                                    ].status = false;
                                    assign_employee({
                                      batch_schedule_time:
                                        scheduleTimeStatus[index].value,
                                      batch_id: record?.batch_id,
                                    });

                                    setScheduleTimeStatus(
                                      updatedScheduleTimeStatus
                                    );
                                  }}
                                >
                                  <CheckCircleIcon color="success" />
                                </IconButton>
                              </Tooltip>
                            </Stack>
                          </Stack>
                        </>
                      );
                    } else {
                      const dateObject = new Date(data);
                      const formattedDateTime = `${dateObject.getFullYear()}-${(
                        dateObject.getMonth() + 1
                      )
                        .toString()
                        .padStart(2, "0")}-${dateObject
                        .getDate()
                        .toString()
                        .padStart(2, "0")} ${dateObject
                        .getHours()
                        .toString()
                        .padStart(2, "0")}:${dateObject
                        .getMinutes()
                        .toString()
                        .padStart(2, "0")}:${dateObject
                        .getSeconds()
                        .toString()
                        .padStart(2, "0")}`;
                      return (
                        <>
                          <Stack direction="row" spacing={1}>
                            <span>
                              {record?.batch_schedule_time?.slice(0, 19)}
                            </span>
                            {record?.timeFrom === "None" &&
                              accessData?.update_access && (
                                <Tooltip title="Edit">
                                  <IconButton
                                    onClick={() => {
                                      const updatedScheduleTimeStatus = [
                                        ...scheduleTimeStatus,
                                      ];
                                      updatedScheduleTimeStatus[
                                        index
                                      ].status = true;
                                      setScheduleTimeStatus(
                                        updatedScheduleTimeStatus
                                      );
                                    }}
                                  >
                                    <BorderColorRoundedIcon color="primary" />
                                  </IconButton>
                                </Tooltip>
                              )}
                          </Stack>
                        </>
                      );
                    }
                  },
                },
                {
                  title: "Employee",
                  dataIndex: "",
                  key: "assign",
                  width: "18%",
                  align: "center",
                  render: (data, record, index) => {
                    return record?.select_emp_list?.length <= 0 ||
                      (scheduleTimeStatus?.length > 0 &&
                        scheduleTimeStatus[index]?.emp_status) ? (
                      <Stack direction="row" spacing={1}>
                        <Select
                          multiple
                          labelId="demo-select-small-label"
                          id="demo-select-small"
                          value={selectedEmp[index] || []}
                          style={{ width: 150 }}
                          required
                          onChange={(e) => {
                            let temp = [...selectedEmp];
                            temp[index] = e.target.value;
                            setSelectedEmp(temp);
                          }}
                        >
                          {record?.emp_list?.map((mdata) => (
                            <MenuItem value={mdata.employee_id}>
                              {mdata.employee_name}
                            </MenuItem>
                          ))}
                        </Select>
                        {accessData?.create_access && (
                          <>
                            <Tooltip title="Close">
                              <IconButton
                                onClick={() => {
                                  const updatedScheduleTimeStatus = [
                                    ...scheduleTimeStatus,
                                  ];
                                  updatedScheduleTimeStatus[
                                    index
                                  ].emp_status = false;
                                  setScheduleTimeStatus(
                                    updatedScheduleTimeStatus
                                  );
                                }}
                              >
                                <CancelIcon color="error" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Assign">
                              <IconButton
                                disabled={!selectedEmp[index]?.length || false}
                                onClick={() => {
                                  assign_employee({
                                    employee_id: selectedEmp[index],
                                    batch_id: record?.batch_id,
                                  });
                                  const updatedScheduleTimeStatus = [
                                    ...scheduleTimeStatus,
                                  ];
                                  updatedScheduleTimeStatus[
                                    index
                                  ].emp_status = false;
                                  setScheduleTimeStatus(
                                    updatedScheduleTimeStatus
                                  );
                                }}
                              >
                                <CheckCircleIcon color="success" />
                              </IconButton>
                            </Tooltip>
                          </>
                        )}
                      </Stack>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          flexWrap: "wrap",
                          gap: "5px",
                        }}
                      >
                        {record?.detailsRemarks?.employee?.map((emp) => (
                          <Chip
                            sx={{
                              m: 1,
                            }}
                            label={emp?.employee_name}
                          />
                        ))}
                        {record?.timeFrom === "None" &&
                          accessData?.update_access && (
                            <Tooltip title="Reassign">
                              <IconButton
                                onClick={() => {
                                  const updatedScheduleTimeStatus = [
                                    ...scheduleTimeStatus,
                                  ];
                                  updatedScheduleTimeStatus[
                                    index
                                  ].emp_status = true;
                                  setScheduleTimeStatus(
                                    updatedScheduleTimeStatus
                                  );
                                }}
                              >
                                <BorderColorRoundedIcon color="primary" />
                              </IconButton>
                            </Tooltip>
                          )}
                      </div>
                    );
                  },
                },
              ]
            : []),
        ]
      : [
          {
            title: "Operation No.",
            dataIndex: "operation_number",
            key: "Operation",
            align: "center",
          },
          {
            title: "Operation",
            dataIndex: "operation_type",
            key: "operation_type",
            align: "center",
            render: (data, record, index) => {
              const ingred = ingredientStrings(record.ingredients, "operation");

              return (
                <div style={{ textAlign: "left" }}>
                  <p>
                    <strong> Operation Type: </strong>
                    {`${data}, `} <br />
                    {record.instruction && (
                      <>
                        <strong>Instruction:</strong>
                        <span>{`${record.instruction}`}</span>
                        <br />
                      </>
                    )}
                    {record?.dependencie && (
                      <>
                        <strong> Depending on Operations: </strong>
                        <span>{`${record?.dependencie}`}</span>
                      </>
                    )}
                  </p>
                  <p>
                    {ingred && ingred?.length > 0 && (
                      <>
                        <strong> Ingredients: </strong>
                        {ingred.join("\n")}
                      </>
                    )}
                  </p>
                </div>
              );
            },
          },
          {
            title: "Equipment",
            dataIndex: "machine_name",
            align: "center",
            key: "machine_name",
          },
          {
            title: "Duration",
            dataIndex: "max_duration",
            key: "max_duration",
            align: "center",
          },
          {
            title: "Details / Remarks",
            dataIndex: "products",
            key: "process_products",
            width: "25%",
            align: "center",
            render: (data, record, index) => {
              const ingred = ingredientStrings(record?.ingredients, "");
              let mul = "X Batch Size";
              let perc = "% of Batch Size";
              return (
                <div style={{ textAlign: "left" }}>
                  {ingred?.length > 0 && (
                    <>
                      <strong> Ingredients: </strong>
                      {/* {ingred?.map((data, index) => (
                            <div key={index}>{data}</div>
                          ))} */}
                      <div className="mb-4">
                        {ingred?.map((data, index) => {
                          return (
                            <>
                              <div key={index}>
                                <strong>{`${index + 1}.  `}</strong>
                                {data?.split("#")?.length > 1
                                  ? `${data?.split("#")[0]}-- [${
                                      data?.split("#")[1]
                                    }] `
                                  : data?.replace("-", "")}
                                {/* {record?.ingredients[index]?.is_percentage
                                  ? "" + perc
                                  : record?.ingredients[index]?.unit + mul} */}
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </>
                  )}
                </div>
              );
            },
          },
          {
            title: "Schedule Time",
            dataIndex: "schedule_date",
            key: "schedule_time",
            width: "14%",
            align: "center",
            render: (data, record, index) => {
              if (scheduleTimeStatus[index]?.status) {
                return (
                  <>
                    <Stack spacing={1}>
                      <OutlinedInput
                        id="des"
                        type="datetime-local"
                        size="small"
                        name="des"
                        fullWidth
                        inputProps={{
                          min:
                            index === 0
                              ? formatDate(data)
                              : formatDate(report2[index - 1]?.schedule_date), // for schedule nma efor operation1 should be greater than actual value and 2nd index should be greater than privious
                          ...(index !== 0
                            ? {
                                max:
                                  index < report2?.length
                                    ? formatDate(
                                        report2[index + 1]?.schedule_date
                                      )
                                    : "",
                              }
                            : {}),
                        }}
                        disabled={!scheduleTimeStatus[index]?.status}
                        //   onClick={() => setIsScheduledDateEdit(true)} // Set isEditing to true on click
                        onChange={(e) => {
                          const updatedScheduleTimeStatus = [
                            ...scheduleTimeStatus,
                          ];
                          updatedScheduleTimeStatus[index].value =
                            e.target.value;
                          setScheduleTimeStatus(updatedScheduleTimeStatus);
                        }}
                      />
                      {accessData?.create_access && (
                        <>
                          <Stack direction="row" justifyContent="center">
                            <Tooltip title="Close">
                              <IconButton
                                onClick={() => {
                                  const updatedScheduleTimeStatus = [
                                    ...scheduleTimeStatus,
                                  ];
                                  updatedScheduleTimeStatus[
                                    index
                                  ].status = false;
                                  updatedScheduleTimeStatus[index].value = "";
                                  setScheduleTimeStatus(
                                    updatedScheduleTimeStatus
                                  );
                                }}
                              >
                                <CancelIcon color="error" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Save">
                              <IconButton
                                disabled={!scheduleTimeStatus[index].value}
                                onClick={() => {
                                  const updatedScheduleTimeStatus = [
                                    ...scheduleTimeStatus,
                                  ];
                                  updatedScheduleTimeStatus[
                                    index
                                  ].status = false;
                                  assign_employee({
                                    batch_schedule_time:
                                      scheduleTimeStatus[index].value,
                                    batch_id: record?.batch_id,
                                  });

                                  setScheduleTimeStatus(
                                    updatedScheduleTimeStatus
                                  );
                                }}
                              >
                                <CheckCircleIcon color="success" />
                              </IconButton>
                            </Tooltip>
                          </Stack>
                        </>
                      )}
                    </Stack>
                  </>
                );
              } else {
                const dateObject = new Date(data);
                const formattedDateTime = `${dateObject.getFullYear()}-${(
                  dateObject.getMonth() + 1
                )
                  .toString()
                  .padStart(2, "0")}-${dateObject
                  .getDate()
                  .toString()
                  .padStart(2, "0")} ${dateObject
                  .getHours()
                  .toString()
                  .padStart(2, "0")}:${dateObject
                  .getMinutes()
                  .toString()
                  .padStart(2, "0")}:${dateObject
                  .getSeconds()
                  .toString()
                  .padStart(2, "0")}`;
                return (
                  <>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <span>{data?.slice(0, 16)}</span>
                      {accessData?.update_access && (
                        <Tooltip title="Edit">
                          <IconButton
                            onClick={() => {
                              const updatedScheduleTimeStatus = [
                                ...scheduleTimeStatus,
                              ];
                              updatedScheduleTimeStatus[index].status = true;
                              setScheduleTimeStatus(updatedScheduleTimeStatus);
                            }}
                          >
                            <BorderColorRoundedIcon color="primary" />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Stack>
                  </>
                );
              }
            },
          },
          {
            title: (
              <Stack spacing={1} alignItems="center" justifyContent="center">
                <strong>Assign</strong>
                <Popconfirm
                  title={
                    <Stack spacing={1} sx={{ width: "300px" }}>
                      <Typography>
                        Are you certain you want to assign the employees to
                        every operation?
                      </Typography>
                      <AntSelect
                        allowClear
                        showSearch
                        mode="multiple"
                        value={employee}
                        style={{ width: "100%" }}
                        required
                        placeholder="Select Employees"
                        optionFilterProp="children"
                        onChange={(value) => set_employee(value)}
                      >
                        {bulk_assign_list?.map((mdata) => (
                          <AntSelect.Option
                            key={mdata.employee_id}
                            value={mdata.employee_id}
                            label={mdata.employee_name}
                          >
                            {mdata.employee_name}
                          </AntSelect.Option>
                        ))}
                      </AntSelect>
                    </Stack>
                  }
                  placement="bottom"
                  onConfirm={() => {
                    post_api_function(
                      "/update_all_employee_for_process",
                      success_assign_employee,
                      {
                        batch_number: current_batch,
                        process_id: selectedProcessId,
                        employee_id: employee,
                      }
                    );
                  }}
                  okText="Assign"
                  cancelText="Close"
                  onCancel={() => {
                    set_employee([]);
                  }}
                  okButtonProps={{ disabled: employee.length === 0 }}
                >
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    endIcon={<HowToRegIcon />}
                  >
                    Assign To All
                  </Button>
                </Popconfirm>
              </Stack>
            ),
            dataIndex: "",
            key: "assign",
            width: "18%",
            align: "center",
            render: (data, record, index) => {
              return record?.select_emp_list?.length <= 0 ||
                (scheduleTimeStatus?.length > 0 &&
                  scheduleTimeStatus[index]?.emp_status) ? (
                <Stack spacing={1}>
                  <Select
                    multiple
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={selectedEmp[index] || []}
                    required
                    fullWidth
                    onChange={(e) => {
                      let temp = [...selectedEmp];
                      temp[index] = e.target.value;
                      setSelectedEmp(temp);
                    }}
                  >
                    {record.emp_list?.map((mdata) => (
                      <MenuItem value={mdata.employee_id}>
                        {mdata.employee_name}
                      </MenuItem>
                    ))}
                  </Select>
                  <Stack direction="row" spacing={1} justifyContent="center">
                    <Tooltip title="Close">
                      <IconButton
                        onClick={() => {
                          const updatedScheduleTimeStatus = [
                            ...scheduleTimeStatus,
                          ];
                          updatedScheduleTimeStatus[index].emp_status = false;
                          setScheduleTimeStatus(updatedScheduleTimeStatus);
                        }}
                      >
                        <CancelIcon color="error" />
                      </IconButton>
                    </Tooltip>
                    {accessData?.update_access && (
                      <Tooltip title="Assign">
                        <IconButton
                          disabled={!selectedEmp[index]?.length || false}
                          onClick={() => {
                            assign_employee({
                              employee_id: selectedEmp[index],
                              batch_id: record?.batch_id,
                            });
                            const updatedScheduleTimeStatus = [
                              ...scheduleTimeStatus,
                            ];
                            updatedScheduleTimeStatus[index].emp_status = false;
                            setScheduleTimeStatus(updatedScheduleTimeStatus);
                          }}
                        >
                          <CheckCircleIcon color="success" />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Stack>
                </Stack>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    flexWrap: "wrap",
                    gap: "5px",
                  }}
                >
                  {record?.select_emp_list?.map((emp) => (
                    <Chip label={emp} />
                  ))}
                  {accessData?.update_access && (
                    <Tooltip title="Reassign">
                      <IconButton
                        onClick={() => {
                          const updatedScheduleTimeStatus = [
                            ...scheduleTimeStatus,
                          ];
                          updatedScheduleTimeStatus[index].emp_status = true;
                          setScheduleTimeStatus(updatedScheduleTimeStatus);
                        }}
                      >
                        <BorderColorRoundedIcon color="primary" />
                      </IconButton>
                    </Tooltip>
                  )}
                </div>
              );
            },
          },
        ];

  const expandedRowRender = (record) => {
    if (record?.opNo) {
      return (
        <Collapse accordion>
          {record?.detailsRemarks?.batch_ingredients?.length > 0 && (
            <Panel header="Batch Ingredients" key="1">
              <>
                <Typography variant="h5">Batch Ingredients</Typography>
                <Table
                  dataSource={record?.detailsRemarks?.batch_ingredients}
                  size="small"
                  columns={[
                    {
                      title: "Date-Time",
                      dataIndex: "created_on",
                      key: "created_on",
                      render: (created_on) => created_on?.slice(0, 19),
                    },
                    {
                      title: "Ingredient",
                      dataIndex: "ingredients_name",
                      key: "ingredients_name",
                      render: (_, record) => `${record?.ingredients_name}`,
                    },
                    {
                      title: "Expected Quantity",
                      dataIndex: "expected_quantity",
                      key: "expected_quantity",
                      render: (_, record) =>
                        `${record?.expected_quantity} ${record?.unit}`,
                    },
                    {
                      title: "Quantity",
                      dataIndex: "quantity",
                      key: "quantity",
                      render: (_, record) =>
                        record?.quantity ? record.quantity + record?.unit : "",
                    },
                    {
                      title: "Quantity Deviation",
                      dataIndex: "quantity_deviation",
                      key: "quantity_deviation",
                      width: "20%",
                      render: (_, record) => {
                        if (record.quantity_deviation === "") {
                          return "";
                        } else {
                          return record.quantity_deviation + " %";
                        }
                      },
                      onCell: (record) => ({
                        style: {
                          backgroundColor: deviation_color(
                            record?.quantity_deviation
                          )?.bg_color,
                          color: deviation_color(record?.quantity_deviation)
                            ?.color,
                        },
                      }),
                    },
                  ]}
                  pagination={false}
                  bordered
                />
              </>
            </Panel>
          )}
          {/* {record?.detailsRemarks?.machine_parameters_value?.length > 0 && (
            <Panel header="Machines" key="2">
              <Descriptions
                column={1}
                size="small"
                bordered
                items={[
                  {
                    key: 1,
                    label: "Machines",
                    children:
                      record?.detailsRemarks?.machine_parameters_value?.map(
                        (item) => (
                          <Chip
                            size="small"
                            sx={{ m: 0.5 }}
                            label={item?.equipment}
                          />
                        )
                      ),
                  },
                ]}
              />
            </Panel>
          )} */}

          {record?.detailsRemarks?.batch_sample_value?.length > 0 && (
            <Panel header="Sampling" key="2">
              <Table
                dataSource={record?.detailsRemarks?.batch_sample_value}
                size="small"
                columns={[
                  {
                    title: "Ingredients",
                    dataIndex: "material_inventory_name",
                    key: "ingredients",
                    render: (_, record) =>
                      `${record?.material_inventory_name} (${record?.material_inventory_unit})`,
                  },
                  {
                    title: "Testing Type",
                    dataIndex: "testing_type_name",
                    key: "testing_type",
                  },
                  {
                    title: "Sample Number",
                    dataIndex: "sample_number",
                    key: "sample_number",
                  },
                  {
                    title: "Date",
                    dataIndex: "created_on",
                    key: "created_on",
                    render: (created_on) => created_on?.slice(0, 19),
                  },
                  {
                    title: "Quantity",
                    dataIndex: "quantity",
                    key: "quantity",
                  },
                ]}
                pagination={false}
                bordered
              />
            </Panel>
          )}
          {record?.detailsRemarks?.batch_parameters?.length > 0 && (
            <Panel header="Batch Parameters" key="3">
              <Table
                dataSource={record?.detailsRemarks?.batch_parameters}
                size="small"
                columns={[
                  {
                    title: "Date-Time",
                    dataIndex: "created_on",
                    key: "created_on",
                    render: (created_on) => created_on?.slice(0, 19),
                  },
                  {
                    title: "Parameter",
                    dataIndex: "parameter_name",
                    key: "parameter_name",
                    render: (_, record) =>
                      `${record?.parameter_name} (${record?.parameter_unit})`,
                  },
                  {
                    title: "Expected",
                    dataIndex: "quantity",
                    key: "value",
                    align: "center",
                  },
                  {
                    title: "Min",
                    dataIndex: "min_value",
                    key: "value",
                    align: "center",
                  },
                  {
                    title: "Max",
                    dataIndex: "max_value",
                    key: "value",
                    align: "center",
                  },
                  {
                    title: "Actual",
                    dataIndex: "value",
                    key: "value",
                    align: "center",
                  },
                ]}
                pagination={false}
                bordered
              />
            </Panel>
          )}
          {record?.detailsRemarks?.batch_product_inventory?.length > 0 && (
            <Panel header="Process Products" key="4">
              <Table
                dataSource={record?.detailsRemarks?.batch_product_inventory}
                size="small"
                columns={[
                  {
                    title: "Product",
                    dataIndex: "product_name",
                    key: "product_name",
                  },
                  {
                    title: "Container No",
                    dataIndex: "Container No",
                    key: "Container No",
                  },
                  {
                    title: "Tare Wt",
                    dataIndex: "Tare Wt.",
                    key: "Tare Wt.",
                  },
                  {
                    title: "Gross Wt",
                    dataIndex: "Gross Wt.",
                    key: "Gross Wt.",
                  },
                  {
                    title: "Net Wt",
                    dataIndex: "Net Wt.",
                    key: "Net Wt.",
                  },
                ]}
                pagination={false}
                bordered
              />
            </Panel>
          )}
        </Collapse>
      );
    } else {
      return (
        <Collapse accordion>
          {record?.products?.length > 0 && (
            <>
              <Panel header="Product" key="1">
                <Table
                  size="small"
                  pagination={false}
                  columns={[
                    {
                      title: "Product Name",
                      dataIndex: "product_name",
                      key: "product_name",
                    },
                    {
                      title: "Process Thero Quantity",
                      dataIndex: "process_thero_quantity",
                      key: "operation_number",
                      render: (data, record, index) => {
                        return <p>{`${data} (${record.product_name_unit})`}</p>;
                      },
                    },
                    {
                      title: "Expected Min Value",
                      dataIndex: "process_expec_min_value",
                      key: "process_expec_min_value",
                      render: (data, record, index) => {
                        return <p>{`${data} (${record.product_name_unit})`}</p>;
                      },
                    },
                    {
                      title: "Expected Max Value",
                      dataIndex: "process_expec_max_value",
                      key: "process_expec_max_value",
                      render: (data, record, index) => {
                        return <p>{`${data} (${record.product_name_unit})`}</p>;
                      },
                    },
                  ]}
                  dataSource={record?.products}
                  bordered
                  style={{ width: "100%" }}
                />
              </Panel>
            </>
          )}

          {record.paramaters && record.paramaters?.length > 0 && (
            <>
              <Panel header="Process Parameters" key="2">
                <Table
                  size="small"
                  pagination={false}
                  columns={[
                    {
                      title: "Parameter",
                      dataIndex: "parameter_name",
                      rowScope: "row",
                    },
                    {
                      title: "Readings Per Interval (HH:MM:SS)",
                      dataIndex: "parameter_max_value",
                      key: "parameter_max_value",
                      render: (data, record, index) => {
                        return <p>{`${data}`}</p>;
                      },
                    },
                    {
                      title: "Min",
                      dataIndex: "min_value",
                      key: "min_value",
                      align: "center",
                    },
                    {
                      title: "Max",
                      dataIndex: "max_value",
                      key: "max_value",
                      align: "center",
                    },
                  ]}
                  dataSource={record.paramaters}
                  bordered
                  style={{ width: "100%" }}
                />
              </Panel>
            </>
          )}
          {record.operation_type === "Sampling" &&
            record.sampling &&
            record.sampling.length > 0 && (
              <Panel header="Sampling" key="3">
                <Table
                  size="small"
                  pagination={false}
                  columns={[
                    {
                      title: "Output",
                      align: "center",
                      dataIndex: "material_inventory_name",
                      key: "material_inventory_name",
                    },
                    {
                      title: "Quantity",
                      align: "center",
                      dataIndex: "quantity",
                      key: "quantity",
                      render: (data, record, index) => {
                        return (
                          <p>{`${data} (${record.material_inventory_unit})`}</p>
                        );
                      },
                    },
                  ]}
                  dataSource={record.sampling}
                  bordered
                  style={{ width: "100%" }}
                />
              </Panel>
            )}

          {record?.machine_list && record?.machine_list?.length > 0 && (
            <Panel header="Machines" key="4">
              <Table
                size="small"
                pagination={false}
                columns={[
                  {
                    title: "Machine Name",
                    dataIndex: "machine_name",
                    key: "machine_name",
                  },
                ]}
                dataSource={record?.machine_list}
                bordered
                style={{ width: "100%" }}
              />
            </Panel>
          )}
        </Collapse>
      );
    }
  };

  return (
    <div className="mt-4">
      <Modal
        open={show}
        width={"1450px"}
        title={`${selected_recipe} - ${selectedProcessName}  (Operations) `}
        size="large"
        footer={[]}
        onCancel={() => {
          setShow(false);
          set_selectedProcessId("");
          set_report2([]);
          set_showSummaryIndex();
        }}
        zIndex={1300}
      >
        {/* {report2?.length > 0 && ( */}
        <Table
          dataSource={addKeysInFilterDataForCollapse(report2)}
          columns={columns}
          loading={loading}
          // pagination={{ pageSize: 3 }}
          scroll={{ y: "65vh" }}
          pagination={false}
          bordered
          rowClassName={(record, index) => {
            if (expanded?.includes(index)) {
              return "col";
            }
          }}
          expandable={{
            expandedRowRender: expandedRowRender,
            onExpand: handleExpand,
            onExpandedRowsChange: (expanded) => set_expanded(expanded),
            expandedRowKeys: [current_expand],
            expandedRowClassName: () => "col2",
          }}
        />
        {/* ) : (
          // <Empty />
          <Table
            columns={columns}
            scroll={{ y: "65vh" }}
            pagination={false}
            bordered
            loading={true}
          />
        )} */}
      </Modal>
    </div>
  );
}

export default OperationsSkeleton;
