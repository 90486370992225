import jsPDF from "jspdf";

export const downloadPDF = (barcode) => {
  const base64Image = barcode; // Replace with your actual Base64 image
  const img = new Image();

  img.onload = () => {
    const canvas = document.createElement("canvas");
    canvas.width = 220; // Adjust the canvas size to accommodate padding
    canvas.height = 220; // Adjust the canvas size to accommodate padding
    const ctx = canvas.getContext("2d");
    ctx.fillStyle = "#ffffff"; // Set background color to white
    ctx.fillRect(0, 0, canvas.width, canvas.height); // Fill the background

    // Set image smoothing to false for better quality
    ctx.imageSmoothingEnabled = false;

    ctx.drawImage(img, 10, 10, 200, 200); // Adjust dimensions and padding as needed

    const pdf = new jsPDF({
      unit: "mm",
      format: [240, 240], // Set custom page size to 240x240 (200x200 + 2 * 10 margin)
    });

    pdf.addImage(canvas.toDataURL("image/png"), "PNG", 0, 0, 240, 240); // Set dimensions to match the custom page size

    // Get the PDF output as a Blob
    const pdfBlob = pdf.output("blob");

    // Create a new Blob URL
    const blobUrl = URL.createObjectURL(pdfBlob);

    // Open the PDF in a new tab
    window.open(blobUrl);
  };

  img.src = `data:image/png;base64,${base64Image}`;
};

export function base64ToFile(base64String, fileName) {
  const binaryString = base64String && atob(base64String);
  const byteArray = new Uint8Array(binaryString?.length);
  for (let i = 0; i < binaryString?.length; i++) {
    byteArray[i] = binaryString?.charCodeAt(i);
  }

  const blob = new Blob([byteArray], { type: "application/pdf" });
  const blobUrl = URL.createObjectURL(blob);

  const anchor = document.createElement("a");
  anchor.href = blobUrl;
  anchor.download = fileName;

  anchor.click();

  URL.revokeObjectURL(blobUrl);
}

export function generateUniqueId() {
  const random = Math.random().toString(36).substr(2, 5);
  localStorage.setItem("newProcessId", random);
  return random;
}

export function generateOpId() {
  const random = Math.random().toString(36).substr(2, 5);
  return random;
}

export function generateEdgeId() {
  const random = Math.random().toString(36).substr(2, 5);
  return random;
}
