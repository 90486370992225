import ELK from "elkjs";
import { MarkerType } from "reactflow";
import { Tooltip, Stack } from "@mui/material";
import { Typography } from "antd";

const { Text, Link } = Typography;

export default async function createLayout(
  nodes_data,
  groups_data,
  edges_data
) {
  const initialNodes = nodes_data?.map((op) => ({
    id: op?.operationId,
    group: op?.process,
    data: op,
  }));

  const initialGroups = groups_data?.map((pro) => ({
    id: pro.process_id,
    width: 50,
    height: 50,
    data: pro,
  }));

  const initialEdges = edges_data;

  const elk = new ELK();

  const graph = {
    id: "root",
    layoutOptions: {
      "elk.algorithm": "layered",
      "elk.direction": "RIGHT",
      "elk.edgeRouting": "POLYLINE",
      "elk.spacing.nodeNode": "40",
    },
    children: initialGroups.map((group) => ({
      id: group.id,
      data: group.data,
      width: group.width,
      height: group.height,
      layoutOptions: {
        "elk.algorithm": "layered",
        "elk.direction": "RIGHT",
        "elk.edgeRouting": "POLYLINE",
        "elk.spacing.edgeNode": "80",
        "elk.spacing.nodeNode": "40",
        "elk.layered.spacing.edgeNodeBetweenLayers": "40",
      },
      children: initialNodes
        .filter((node) => node.group === group.id)
        .map((node) => ({
          id: node.id,
          data: node.data,
          width: 120,
          height: 30,
          layoutOptions: {
            "elk.algorithm": "layered",
            "elk.direction": "RIGHT",
            "elk.layered.spacing.edgeNodeBetweenLayers": "40",
            "elk.edgeRouting": "POLYLINE",
            "elk.spacing.edgeNode": "40",
          },
        })),
    })),
    edges: initialEdges.map((edge) => ({
      id: edge.id,
      sources: [edge.source],
      targets: [edge.target],
    })),
  };

  const layout = await elk.layout(graph);
  const nodes = layout.children.reduce((result, current) => {
    result.push({
      id: current.id,
      position: { x: current.x, y: current.y },
      data: {
        label: (
          <div style={{ marginTop: "-28px" }}>
            <Tooltip title={current?.data?.process}>
              <div
                style={{
                  display: "block",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {current?.data?.process}
              </div>
            </Tooltip>
          </div>
        ),
        data: current?.data,
      },
      style: {
        width: current.width,
        height: current.height,
        backgroundColor:
          // get_color(current?.data?.status),
          "RGB(0,0,0,0)",
        border: "2px dashed #BFBFBF",
      },
      sourcePosition: "right",
      targetPosition: "left",
    });

    current.children.forEach((child) =>
      result.push({
        id: child.id,
        position: { x: child.x, y: child.y },
        data: {
          label: (
            <Tooltip title={child.data?.operation}>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
                sx={{ height: "100%" }}
              >
                <Text
                  style={{
                    display: "block",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    fontSize: "0.7rem", // Adjust as needed
                  }}
                  type="secondary"
                >
                  {child.data?.operation}
                </Text>
              </Stack>
            </Tooltip>
          ),
          data: child.data,
        },
        style: {
          width: child.width,
          height: child.height,
          // border: "none",
        },
        parentNode: current.id,
        sourcePosition: "right",
        targetPosition: "left",
      })
    );

    return result;
  }, []);

  return {
    nodes,
    edges: initialEdges?.map((item) => {
      return {
        ...item,
        type: "step",
        pathOptions: { offset: 1 },
        style: {
          strokeWidth: 1.5,
          zIndex: 10,
        },
        zIndex: 10,
        markerEnd: {
          type: MarkerType.ArrowClosed,
          width: 15,
          height: 15,
        },
      };
    }),
  };
}
