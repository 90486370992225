import React, { useState, useEffect } from "react";
// project import
import {
  bp_get_batch_notification_for_employee,
  get_ingredient_value_alert,
  get_notification,
} from "utils/api";
import { useNavigate } from "react-router";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  Typography,
  Grid,
  Card,
  Avatar,
  Tab,
  Box,
  Stack,
} from "@mui/material";
import { Tabs, Typography as Typography1 } from "antd";

import ThumbUpIcon from "@mui/icons-material/ThumbUp"; // -----> acknowledged
import WatchLaterOutlinedIcon from "@mui/icons-material/WatchLaterOutlined";
// -----> in progress
import HourglassBottomOutlinedIcon from "@mui/icons-material/HourglassBottomOutlined";
//  ------> waiting for spare parts
import CheckCircleIcon from "@mui/icons-material/CheckCircleOutlined";
// ------> complted
import AssignmentIndSharpIcon from "@mui/icons-material/AssignmentIndSharp";
// ----> assign
import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";
import { RightCircleFilled, UserSwitchOutlined } from "@ant-design/icons";
// -----> approved
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
//  ------> declined
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
// -------> closed
import TaskIcon from "@mui/icons-material/FlagCircleRounded";
// -------> resolved
import UndoIcon from "@mui/icons-material/Undo";
// ------> returned
import MoreIcon from "@mui/icons-material/More";
//  ------> others
import WorkIcon from "@mui/icons-material/Work";

import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import NotificationImportantOutlinedIcon from "@mui/icons-material/NotificationImportantOutlined";
import Transitions from "./@extended/Transitions";

// ==============================|| SAMPLE PAGE ||============================== //

const avatarSX = {
  width: 36,
  height: 36,
  fontSize: "1rem",
};

const actionSX = {
  mt: "6px",
  ml: 1,
  top: "auto",
  right: "auto",
  alignSelf: "flex-start",

  transform: "none",
};

const deviation_color = (per) => {
  let color;
  let bg_color;

  switch (true) {
    case per >= 0 && per <= 5:
      color = "green";
      bg_color = "#CCFFCC ";
      break;
    case (per >= -10 && per < 0) || (per > 5 && per <= 50):
      color = "orange";
      bg_color = "#FFF59E";
      break;
    case per < -10 || per > 50:
      color = "red";
      bg_color = "#FFCCCC";
      break;
    default:
      color = "black";
  }
  return { color: color, bg_color: bg_color };
};

const NotificationPage = () => {
  const [notifications_data, set_notifications_data] = useState([]);
  const [alert_data, set_alert_data] = useState([]);

  const success_get_notification = (res) => {
    set_notifications_data(res.data.data);
  };

  const success_get_alerts = (res) => {
    set_alert_data(res.data.data);
  };

  useEffect(() => {
    bp_get_batch_notification_for_employee(success_get_notification);
  }, []);
  const [activeKey, set_activeKey] = useState("notify");

  return (
    <Tabs
      activeKey={activeKey}
      onChange={(value) => {
        set_activeKey(value);
        !alert_data?.length &&
          value === "alert" &&
          get_ingredient_value_alert(success_get_alerts);
      }}
      items={[
        {
          key: "notify",
          label: (
            <Stack
              direction="row"
              justifyContent="space-around"
              alignItems="center"
              spacing={1}
            >
              <Typography>Notifications</Typography>
              <NotificationsOutlinedIcon
                color="success"
                style={{
                  marginBottom: 0,
                  marginRight: "10px",
                }}
              />
            </Stack>
          ),
          children: (
            <List
              component="nav"
              sx={{
                p: 0,
                "& .MuiListItemButton-root": {
                  py: 0.5,
                  "& .MuiAvatar-root": avatarSX,
                  "& .MuiListItemSecondaryAction-root": {
                    ...actionSX,
                    position: "relative",
                  },
                },
              }}
            >
              <Grid container>
                {notifications_data?.map((item) => {
                  return (
                    <Grid item lg={3}>
                      <Card sx={{ m: 2 }}>
                        <ListItem
                        // onClick={() => {
                        //   handleTimeLine(item);
                        // }}
                        >
                          <ListItemAvatar>
                            <Avatar
                              sx={{
                                color: "success.main",
                                bgcolor: "success.lighter",
                              }}
                            >
                              {item.recipe_name
                                .split(" ")
                                .map((item) => item[0])
                                .join("")
                                .toUpperCase()}
                            </Avatar>
                          </ListItemAvatar>
                          <Grid container>
                            <Grid item lg={12}>
                              <ListItemText
                                primary={
                                  <Typography variant="h6">
                                    {item.msg}
                                  </Typography>
                                }
                              />
                            </Grid>

                            <Grid container item lg={12}>
                              <Grid item lg={6}>
                                <Typography variant="caption" noWrap>
                                  Recipe: &nbsp;
                                </Typography>
                              </Grid>
                              <Grid item lg={6}>
                                <Typography variant="caption" noWrap>
                                  {item?.recipe_name}
                                </Typography>
                              </Grid>
                            </Grid>

                            <Grid container item lg={12}>
                              <Grid item lg={6}>
                                <Typography variant="caption" noWrap>
                                  Batch Number: &nbsp;
                                </Typography>
                              </Grid>
                              <Grid item lg={6}>
                                <Typography variant="caption" noWrap>
                                  {item?.batch_number}
                                </Typography>
                              </Grid>
                            </Grid>

                            <Grid container item lg={12}>
                              <Grid item lg={6}>
                                <Typography variant="caption" noWrap>
                                  Process Name: &nbsp;
                                </Typography>
                              </Grid>
                              <Grid item lg={6}>
                                <Typography variant="caption" noWrap>
                                  {item?.process_name}
                                </Typography>
                              </Grid>
                            </Grid>

                            <Grid container item lg={12}>
                              <Grid item lg={6}>
                                <Typography variant="caption" noWrap>
                                  Schedule Time: &nbsp;
                                </Typography>
                              </Grid>
                              <Grid item lg={6}>
                                <Typography variant="caption" noWrap>
                                  {item?.batch_schedule_time?.slice(0, 19)}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </ListItem>
                      </Card>
                    </Grid>
                  );
                })}
              </Grid>
            </List>
          ),
        },
        {
          key: "alert",
          label: (
            <Stack
              direction="row"
              justifyContent="space-around"
              alignItems="center"
              spacing={1}
            >
              <Typography>Alerts</Typography>
              <NotificationImportantOutlinedIcon
                color="warning"
                style={{
                  marginBottom: 0,
                  marginRight: "10px",
                }}
              />
            </Stack>
          ),
          children: (
            <List
              component="nav"
              sx={{
                p: 0,
                "& .MuiListItemButton-root": {
                  py: 0.5,
                  "& .MuiAvatar-root": avatarSX,
                  "& .MuiListItemSecondaryAction-root": {
                    ...actionSX,
                    position: "relative",
                  },
                },
              }}
            >
              <Grid container>
                {alert_data?.map((item) => {
                  return (
                    <Grid item lg={3}>
                      <Card sx={{ m: 2 }}>
                        <ListItem
                        // onClick={() => {
                        //   handleTimeLine(item);
                        // }}
                        >
                          <ListItemAvatar>
                            <Avatar
                              sx={{
                                color: deviation_color(item?.quantity_deviation)
                                  ?.color,
                                bgcolor: deviation_color(
                                  item?.quantity_deviation
                                )?.bg_color,
                              }}
                            >
                              {item.recipe_name
                                .split(" ")
                                .map((item) => item[0])
                                .join("")
                                .toUpperCase()}
                            </Avatar>
                          </ListItemAvatar>
                          <Grid container>
                            <Grid item lg={12}>
                              <ListItemText
                                primary={
                                  <Typography1.Paragraph
                                    ellipsis={{
                                      rows: 2,
                                      expandable: true,
                                      symbol: "more",
                                    }}
                                  >
                                    {item?.msg}
                                  </Typography1.Paragraph>
                                }
                              />
                            </Grid>
                            <Grid
                              container
                              item
                              lg={12}
                              sx={{
                                color: deviation_color(item?.quantity_deviation)
                                  ?.color,
                                bgcolor: deviation_color(
                                  item?.quantity_deviation
                                )?.bg_color,
                              }}
                            >
                              <Grid item lg={6}>
                                <Typography noWrap>
                                  Quantity Deviation: &nbsp;
                                </Typography>
                              </Grid>
                              <Grid item lg={6}>
                                <Typography noWrap>
                                  {item?.quantity_deviation} &nbsp;%
                                </Typography>
                              </Grid>
                            </Grid>

                            <Grid container item lg={12}>
                              <Grid item lg={6}>
                                <Typography variant="caption" noWrap>
                                  Recipe: &nbsp;
                                </Typography>
                              </Grid>
                              <Grid item lg={6}>
                                <Typography variant="caption" noWrap>
                                  {item?.recipe_name}
                                </Typography>
                              </Grid>
                            </Grid>

                            <Grid container item lg={12}>
                              <Grid item lg={6}>
                                <Typography variant="caption" noWrap>
                                  Batch Number: &nbsp;
                                </Typography>
                              </Grid>
                              <Grid item lg={6}>
                                <Typography variant="caption" noWrap>
                                  {item?.batch_number}
                                </Typography>
                              </Grid>
                            </Grid>

                            <Grid container item lg={12}>
                              <Grid item lg={6}>
                                <Typography variant="caption" noWrap>
                                  Process Name: &nbsp;
                                </Typography>
                              </Grid>
                              <Grid item lg={6}>
                                <Typography variant="caption" noWrap>
                                  {item?.process_name}
                                </Typography>
                              </Grid>
                            </Grid>

                            <Grid container item lg={12}>
                              <Grid item lg={6}>
                                <Typography variant="caption" noWrap>
                                  Operation: &nbsp;
                                </Typography>
                              </Grid>
                              <Grid item lg={6}>
                                <Typography variant="caption" noWrap>
                                  {item?.operation_type}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </ListItem>
                      </Card>
                    </Grid>
                  );
                })}
              </Grid>
            </List>
          ),
        },
      ]}
    />
  );
};

export default NotificationPage;
