import { lazy } from "react";

// project import
import Loadable from "components/Loadable";
import MinimalLayout from "layout/MinimalLayout";

// render - login
const AuthLogin = Loadable(lazy(() => import("pages/authentication/Login")));

const AuthRegister = Loadable(
  lazy(() => import("pages/authentication/Register"))
);
const PrivacyPolicy = Loadable(
  lazy(() => import("pages/company-details/PrivacyPolicy"))
);

const TermsConditions = Loadable(
  lazy(() => import("pages/company-details/TermsAndConditions"))
);

const ContactUs = Loadable(
  lazy(() => import("pages/company-details/ContactUs"))
);

const Simulator = Loadable(lazy(() => import("pages/simulator/Simulator")));

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = () => {
  return {
    path: "/",
    element: <MinimalLayout />,
    children: [
      {
        path: "/",
        element: <AuthLogin />,
      },
      {
        path: "/login",
        element: <AuthLogin />,
      },
      {
        path: "/register",
        element: <AuthRegister />,
      },
      {
        path: "/privacy-policy",
        element: <PrivacyPolicy />,
      },
      {
        path: "/terms-conditions",
        element: <TermsConditions />,
      },
      {
        path: "/contact-us",
        element: <ContactUs />,
      },
      {
        path: "/simulator",
        element: <Simulator />,
      },
    ],
  };
};

export default LoginRoutes;
