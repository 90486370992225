import React, { useEffect, useState, useRef } from "react";
import {
  bp_add_meta_data,
  bp_get_machine_by_machine_typ,
  bp_machine_data,
  bp_save_batch_parameters,
} from "../../../utils/api";
import { toast } from "react-toastify";

import {
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
  Grid,
  Select,
  MenuItem,
  Button,
  InputAdornment,
  IconButton,
  Avatar,
} from "@mui/material";
import MainCard from "components/MainCard";
import { useDispatch, useSelector } from "react-redux";
import { settab_number } from "store/reducers/tabNumber";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { Badge, Descriptions } from "antd";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

function AddData() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const operation_data = state?.operation_data;
  const dispatch = useDispatch();
  const [batch_no, set_batch_no] = useState("2");
  const [operation, set_operation] = useState("Operation 1");
  const [machine, set_machine] = useState("i");
  const [time, set_time] = useState(dayjs());
  const [param_value, set_param_value] = useState("");
  const [remark, set_remark] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    submit_function();
  };

  //####################### Handle Api's######################################################

  const [machine_data, setMachineData] = useState([]);
  const get_all_machine = async () => {
    try {
      await bp_get_machine_by_machine_typ(success_machine_list_get, {
        machine_type_id: operation_data?.machine_id,
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const success_machine_list_get = (res) => {
    setMachineData(res?.data?.data);
  };

  useEffect(() => {
    get_all_machine();
  }, [operation_data]);

  const submit_function = async (metaData) => {
    try {
      await bp_save_batch_parameters(
        {
          process_parameter: param_value?.filter(Boolean),
          operation_id: operation_data?.operation_id,
          machine_id: machine,
          batch_number: operation_data?.batch_number,
        },
        success_bp_save_batch_parameters
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const success_bp_save_batch_parameters = (res) => {
    if (res.status === "failed") {
      toast.warning(res.message);
    } else {
      navigate("/batch");
      resetFunction();
      toast.success("Parameter value added successfully");
    }
  };

  const resetFunction = () => {
    set_param_value([]);
    set_remark("");
    set_machine("");
  };

  const handle_parameter = (ingredient_id, e, index) => {
    if (e.target.value === "") {
      let temp = [...param_value];
      temp.splice(index, 1);
      set_param_value(temp);
    } else {
      let temp = [...param_value];
      temp[index] = {
        process_parameter_id: ingredient_id,
        value: e.target.value,
      };
      set_param_value(temp);
    }
  };

  return (
    <MainCard darkTitle title="Parameters" backbutton>
      <form onSubmit={handleSubmit}>
        <Descriptions
          title=""
          // layout="vertical"
          bordered
          column={2}
          contentStyle={{
            fontSize: "24px",
          }}
          labelStyle={{
            fontSize: "32px",
            fontWeight: "bold",
          }}
          items={[
            {
              key: "5",
              label: "Instruction",
              children: operation_data?.instruction,
              span: 2,
            },
            {
              key: "1",
              label: "Batch No",
              children: operation_data?.batch_number,
            },
            {
              key: "2",
              label: "Operation",
              children: `${operation_data?.operation_type} - ${operation_data?.operation_number}`,
            },
            {
              key: "3",
              label: "Machine",
              children: (
                <Select
                  value={machine}
                  size="medium"
                  required
                  fullWidth
                  onChange={(e) => set_machine(e.target.value)}
                  style={{
                    fontSize: "24px",
                  }}
                >
                  <MenuItem value="i" disabled>
                    <em> Select Machine</em>
                  </MenuItem>
                  {machine_data?.map((item) => (
                    <MenuItem value={item.machine_id}>
                      {item.machine_name}
                    </MenuItem>
                  ))}
                </Select>
              ),
            },
            {
              key: "4",
              label: "Duration",
              children: operation_data?.max_duration,
            },
          ]}
        />
        <Grid
          container
          spacing={10}
          sx={{ my: 0 }}
          direction="row"
          justifyContent="center"
        >
          <Grid container item lg={12} spacing={5}>
            {operation_data?.paramaters?.map((item, index) => (
              <Grid container item sx={12} lg={12}>
                <Grid item xs={12} lg={4}>
                  <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ height: "100%" }}
                  >
                    <InputLabel sx={{ fontSize: "24px" }} htmlFor="title">
                      {item?.parameter_name}
                    </InputLabel>
                  </Stack>
                </Grid>
                <Grid item xs={12} lg={8}>
                  <OutlinedInput
                    id="title"
                    type="number"
                    name="name"
                    fullWidth
                    value={param_value[index]?.value}
                    onChange={(e) => {
                      handle_parameter(item?.parameter_id, e, index);
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <Avatar
                          variant="square"
                          sx={{
                            color: "#000",
                            bgcolor: "#fff",
                            fontSize: "24px",
                          }}
                        >
                          {`${item?.parameter_name
                            ?.split(" ")
                            .pop()
                            .replace(")", "")}`}
                        </Avatar>
                      </InputAdornment>
                    }
                    inputProps={{
                      style: {
                        height: "50px",
                        fontSize: "24px",
                      },
                    }}
                  />
                </Grid>
              </Grid>
            ))}
            {/* <Grid container item sx={12} lg={12}>
              <Grid item xs={12} lg={4}>
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ height: "100%" }}
                >
                  <InputLabel sx={{ fontSize: "24px" }} htmlFor="title">
                    Remark :
                  </InputLabel>
                </Stack>
              </Grid>
              <Grid item xs={12} lg={8}>
                <OutlinedInput
                  id="title"
                  type="text"
                  name="name"
                  fullWidth
                  value={remark}
                  required
                  onChange={(e) => set_remark(e.target.value)}
                  inputProps={{
                    style: {
                      height: "50px",
                      fontSize: "24px",
                    },
                  }}
                />
              </Grid>
            </Grid> */}

            {/* <Grid item xs={12} lg={4}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="title">Remark</InputLabel>
                  <OutlinedInput
                    id="title"
                    type="text"
                    name="name"
                    fullWidth
                    value={remark}
                    onChange={(e) => set_remark(e.target.value)}
                  />
                </Stack>
              </Grid> */}

            <Grid item xs={12} lg={12}>
              <Stack
                direction="row"
                alignItems="end"
                justifyContent="end"
                spacing={2}
              >
                {/* <Button
                  size="medium"
                  type="reset"
                  variant="contained"
                  color="primary"
                  onClick={resetFunction}
                  sx={{
                    height: "50px",
                    fontSize: "24px",
                  }}
                >
                  Reset
                </Button> */}
                <Button
                  size="medium"
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{
                    height: "50px",
                    fontSize: "24px",
                  }}
                >
                  Submit
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </MainCard>
  );
}

export default AddData;
