import React, { useEffect, useState, useRef } from "react";

import { toast } from "react-toastify";
import { Typography, Grid, Box, Tab, Stack } from "@mui/material";
import { Modal, Tabs } from "antd";
import MainCard from "components/MainCard";
import ViewRecipe from "./ViewRecipe";
import ApproveProcess from "./ApproveProcess";
import PendingRecipes from "./Recipe/PendingRecipe";
import InfoComponent from "components/Info";
import DAGFlowCopy from "pages/dashboard/DAGFlow copy";
import ProdRecipeApproval from "./ProdRecipeApproval";
import { accessControlUtils } from "components/AccessControlUtils";
import BOM from "./BOM";

function RecipeConfig({ tabId }) {
  const [tab, set_tab] = useState("1");
  const [user_access_data, set_user_access_data] = useState([]);
  const [flow_data, set_flow_data] = useState();

  const handleChange = (newValue) => {
    set_tab(newValue);
  };

  useEffect(() => {
    set_user_access_data(JSON.parse(localStorage.getItem("user_access_data")));
  }, []);

  const [refresh, set_refresh] = useState([]);
  useEffect(() => {
    const storedData = localStorage.getItem("user_access_data");
    if (storedData) {
      set_user_access_data(JSON.parse(storedData));
    } else {
      set_refresh(!refresh);
    }
  }, [refresh]);

  useEffect(() => {
    if (tabId) {
      set_tab(tabId);
    }
  }, [tabId]);

  return (
    <MainCard>
      {flow_data?.recipe_id && (
        <DAGFlowCopy data={flow_data} set_flow_data={set_flow_data} />
      )}
      <Tabs
        // activeKey={tab}
        onChange={(value) => {
          handleChange(value);
          set_flow_data();
        }}
        tabBarExtraContent={
          <React.Fragment>
            <Stack direction="row" spacing="1" justifyContent="end">
              <InfoComponent infoText={"view Recipe"} />
            </Stack>
          </React.Fragment>
        }
        moreIcon
        items={[
          accessControlUtils("Recipe")?.read_access &&
            accessControlUtils("View Recipe")?.read_access && {
              label: "View Recipe",
              key: "1",
              children: <ViewRecipe set_flow_data={set_flow_data} />,
            },
          accessControlUtils("Recipe")?.read_access &&
            accessControlUtils("Pending Recipe")?.read_access && {
              label: "Pending Recipe's",
              key: "2",
              children: <PendingRecipes set_flow_data={set_flow_data} />,
            },
          accessControlUtils("Recipe")?.read_access &&
            accessControlUtils("Recipe Approval")?.read_access && {
              label: "Process Approval",
              key: "3",
              children: <ApproveProcess />,
            },
          accessControlUtils("Recipe")?.read_access &&
            accessControlUtils("Prod Recipe Approval")?.read_access && {
              label: "Production Recipe Approval",
              key: "4",
              children: <ProdRecipeApproval />,
            },
        ]}
      />
    </MainCard>
  );
}

export default RecipeConfig;
