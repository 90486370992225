import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// material-ui
import { useTheme } from "@mui/material/styles";
import { Box, Toolbar, useMediaQuery, Container } from "@mui/material";

// project import
import Drawer from "./Drawer";
import Header from "./Header";
// import navigation from 'menu-items';
import MenuItems from "menu-items/index";

import Breadcrumbs from "components/@extended/Breadcrumbs";

// types
import { openDrawer } from "store/reducers/menu";
import Footer from "./Footer";
import Footer1 from "./Footer";
import useConfig from "hooks/useConfig";
import { drawerWidth } from "config";

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const theme = useTheme();
  const matchDownLG = useMediaQuery(theme.breakpoints.down("lg"));
  const dispatch = useDispatch();
  const navigation = MenuItems();

  const { container, miniDrawer, menuOrientation } = useConfig();

  const { drawerOpen } = useSelector((state) => state.menu);

  // drawer toggler
  const [open, setOpen] = useState(drawerOpen);
  const handleDrawerToggle = () => {
    setOpen(!open);
    dispatch(openDrawer({ drawerOpen: !open }));
  };

  // useEffect(() => {
  //   setOpen(!matchDownLG);
  //   dispatch(openDrawer({ drawerOpen: !matchDownLG }));
  // }, [matchDownLG]);

  useEffect(() => {
    if (open !== drawerOpen) setOpen(drawerOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerOpen]);

  return (
    <Box sx={{ display: "flex", width: "100%",  }}>
      <Header open={open} handleDrawerToggle={handleDrawerToggle} />
      <Drawer open={open} handleDrawerToggle={handleDrawerToggle} />
      <Container
        maxWidth={drawerOpen ? "100%" : false}
        sx={{
          ...(container && { px: { xs: 0, sm: 2 } }),
          position: "relative",
          minHeight: "calc(100vh)",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Toolbar />
        <Breadcrumbs
          navigation={navigation}
          title
          titleBottom
          card={false}
          divider={false}
        />
        <Outlet />

        <Footer />
      </Container>
    </Box>
  );
};

export default MainLayout;
