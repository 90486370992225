import { createSlice } from "@reduxjs/toolkit";

const ingredientSlice = createSlice({
  name: "ingredient",
  initialState: [],
  reducers: {
    setIngredient: (state, action) => {
      return action.payload;
    },
  },
});

export const { setIngredient } = ingredientSlice.actions;
export default ingredientSlice.reducer;
