import React, { useEffect, useRef, useState } from "react";
import TableContainer from "components/TableContainer";
import {
  Button,
  Grid,
  Typography,
  Card,
  Chip,
  Stack,
  Tooltip,
  IconButton,
  Paper,
} from "@mui/material";
import {
  Modal,
  Descriptions,
  Image as Image1,
  Table,
  Pagination,
  Checkbox,
  Popover,
} from "antd";

import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { jsPDF } from "jspdf";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import QrCodeIcon from "@mui/icons-material/QrCode";
import { useReactToPrint } from "react-to-print";
import html2canvas from "html2canvas";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";

function QrPrinter({
  set_show_qr,
  show_qr,
  set_qr_image,
  qr_image,
  componentRef,
}) {
  const navigate = useNavigate();
  const handleOk = () => {};
  const handleCancel = () => {
    set_show_qr(false);
    set_qr_image();
  };
  // -----------------pagination------------------------

  return (
    <>
      <Modal
        open={show_qr}
        title=""
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[]}
      >
        <Stack
          justifyContent="start"
          alignItems="center"
          sx={{ height: "100%", width: "100%" }}
          spacing={2}
        >
          {/* <Image1 style={{ width: "350px", height: "auto" }}  src={`data:image/png;base64,${qr_image}`} /> */}
          <Paper
            ref={componentRef}
            elevation={3}
            style={{ padding: "12px", maxWidth: "100%", margin: "auto" }}
          >
            <Grid container>
              <Grid
                container
                item
                xs={7}
                lg={7}
                style={{ padding: "8px", maxWidth: "100%", margin: "auto" }}
              >
                <Grid item xs={8}>
                  <Typography
                    variant="body1"
                    sx={{ fontSize: "clamp(0.7rem, 1.5vw, 1.1rem)" }}
                  >
                    <strong>Material No :</strong>
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    variant="body1"
                    sx={{ fontSize: "clamp(0.7rem, 1.5vw, 1.1rem)" }}
                  >
                    {qr_image?.ingredent_material_id}
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography
                    variant="body1"
                    sx={{ fontSize: "clamp(0.7rem, 1.5vw, 1.1rem)" }}
                  >
                    <strong>Pack Quantity :</strong>
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    variant="body1"
                    sx={{ fontSize: "clamp(0.7rem, 1.5vw, 1.1rem)" }}
                  >
                    {qr_image?.quantity}
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography
                    variant="body1"
                    sx={{ fontSize: "clamp(0.7rem, 1.5vw, 1.1rem)" }}
                  >
                    <strong>Packs :</strong>
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    variant="body1"
                    sx={{ fontSize: "clamp(0.7rem, 1.5vw, 1.1rem)" }}
                  >
                    {qr_image?.packs}
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography
                    variant="body1"
                    sx={{ fontSize: "clamp(0.7rem, 1.5vw, 1.1rem)" }}
                  >
                    <strong>Packs Status :</strong>
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    variant="body1"
                    sx={{ fontSize: "clamp(0.7rem, 1.5vw, 1.1rem)" }}
                  >
                    {"R"}
                  </Typography>
                </Grid>
              </Grid>

              {/* Right Grid - QR Code Image */}
              <Grid item xs={5}>
                <img
                  src={`data:image/png;base64,${qr_image?.qr}`}
                  alt="QR Code"
                  style={{
                    width: "100%",
                    height: "auto",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                />
              </Grid>
            </Grid>
          </Paper>
          <Stack direction="row" spacing={2}>
            <IconButton
              variant="contained"
              // onClick={() => {
              //   downloadPDF(qr_image);
              // }}
              color="primary"
              onClick={async () => {
                const scale = 3; // Adjust for higher resolution displays
                const canvas = await html2canvas(componentRef.current, {
                  scale: scale,
                  useCORS: true,
                });

                const data = canvas.toDataURL("image/png");

                const printWindow = window.open("", "_blank");
                printWindow.document.write(`
                  <html>
                    <head>
                      <title>Print</title>
                      <style>
                        @media print {
                          @page {
                            margin: 0;
                          }
                          body {
                            margin: 0;
                            width: 100%;
                            height: 100vh;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            background-color: white;
                          }
                          img {
                            width: 100%;
                            height: auto;
                            display: block;
                          }
                        }
                      </style>
                    </head>
                    <body>
                      <img src="${data}" onload="window.print(); window.close();" />
                    </body>
                  </html>
                `);
                printWindow.document.close();
              }}
            >
              <LocalPrintshopIcon color="primary" />
            </IconButton>
            <IconButton
              variant="contained"
              onClick={async () => {
                const scale = 3; // Adjust for higher resolution displays
                const canvas = await html2canvas(componentRef.current, {
                  scale: scale,
                  useCORS: true,
                });
                const data = canvas.toDataURL("image/png");
                // Create an anchor element and trigger the download
                const link = document.createElement("a");
                link.href = data;
                link.download = "image.png"; // The name of the downloaded file
                link.click();
              }}
              color="primary"
            >
              <FileDownloadIcon color="primary" />
            </IconButton>
          </Stack>
        </Stack>
      </Modal>
    </>
  );
}

export default QrPrinter;
