import { createSlice } from "@reduxjs/toolkit";

const newProcessDataSlice = createSlice({
  name: "newProcessData",
  initialState: {
    process_list: [],
    operation_list: [],
    operation_edges: [],
    process_edges: [],
  },
  reducers: {
    setnewProcessDataShow: (state, action) => {
      return action.payload;
    },
  },
});

export const { setnewProcessDataShow } = newProcessDataSlice.actions;
export default newProcessDataSlice.reducer;
