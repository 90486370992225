// third-party
import { combineReducers } from "redux";

// project import
import menu from "./menu";
import auth from "./auth";
import search from "./search";
import dashboardValue from "./dashboardValue";
import recordView from "./recordView";
import accessControl from "./accessControl";
import maindashboard from "./maindashboard";
import loginShow from "./loginShow";
import otpShow from "./otpValueShow";
import forgotPasswordShow from "./forgotPassword";
import setpasswordShow from "./setpasswordshow";
import notification_refresh from "./notification_refresh";
import company_profile from "./company_profile";
import tab_number from "./tabNumber";
import ingredients from "./ingredients";
import submit_status from "./submit_status"
import newProcessData from "./newProcessData";
// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
  menu,
  auth,
  search,
  dashboardValue,
  recordView,
  forgotPasswordShow,
  accessControl,
  maindashboard,
  otpShow,
  loginShow,
  setpasswordShow,
  notification_refresh,
  company_profile,
  tab_number,
  ingredients,
  submit_status,
  newProcessData
});

export default reducers;
