import React, { useEffect, useRef, useState } from "react";
import { Modal, Select as AntSelect } from "antd";
import {
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
  Grid,
  Tooltip,
  Button,
  Select,
  IconButton,
  MenuItem,
} from "@mui/material";
import {
  add_material_data,
  bp_add_location_data,
  add_machine_room,
  bp_add_parameter_data,
  bp_machine_cat_data,
  save_machine_category,
  bp_add_machine_cat_data,
  bp_add_unit_data,
  bp_units_data,
} from "utils/api";
import { toast } from "react-toastify";
import AddIcon from "@mui/icons-material/Add";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { Category } from "../../../../node_modules/@mui/icons-material/index";

const ConfigModal = ({
  show_modal,
  set_show_modal,
  add_number,
  set_add_number,
  get_status,
  set_get_status,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const ipRegex =
    /^(?=\d+\.\d+\.\d+\.\d+$)(?:(?:25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]|[0-9])\.?){4}$/;
  const handleCancel = () => {
    setIsModalOpen(false);
    set_show_modal(false);
    reset_function();
  };

  useEffect(() => {
    setIsModalOpen(show_modal);
  }, [show_modal]);

  const handleSubmit = (e) => {
    e.preventDefault();
    add_number === 1 &&
      add_Material({
        material_name: material_name,
        material_unique_id: material_unique_id,
      });
    add_number === 2 && add_unit({ unit_name: unit_name });
    add_number === 3 && add_location({ location_name: location });
    add_number === 4 &&
      add_Parameter({
        parameter_name: paramter_name,
        unit_id: unit_id,
      });
    add_number === 5 && add_machineType({ machine_category_name: machineType });
    add_number === 6 &&
      add_machine_location({ room_name: location, room_ip: room_ip });
    add_number === 7 && add_machine_category({ name: machineCategory });
  };

  const success_add_function = (res) => {
    if (res.status === "failed") {
      toast.warning(res.message);
    } else {
      set_show_modal(false);
      reset_function();
      set_get_status(!get_status);
      toast.success(res.message);
    }
  };

  const reset_function = () => {
    setmaterial_name("");
    setmaterial_unique_id("");
    set_unit_name("");
    set_machineCategory("");
    set_loctation("");
    set_room_ip("");
    set_paramter_name("");
    setMachineType("");
    set_unit_id("");
  };

  const [unit_list, set_unit_list] = useState([]);

  const get_all_parameters_and_units = async () => {
    try {
      await bp_units_data(success_unit_list_get);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const success_unit_list_get = (res) => {
    set_unit_list(res?.data?.data);
  };

  useEffect(() => {
    add_number === 4 && get_all_parameters_and_units();
  }, [add_number]);

  // ======================================Material===============================

  const [material_name, setmaterial_name] = useState("");
  const [material_unique_id, setmaterial_unique_id] = useState("");
  const add_Material = async (materialData) => {
    try {
      await add_material_data(materialData, success_add_function);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // ======================================UNit===============================

  const [unit_name, set_unit_name] = useState("");
  const add_unit = async (unitReq) => {
    try {
      await bp_add_unit_data(unitReq, success_add_function);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  //   =================================Location========================================
  const [location, set_loctation] = useState("");

  const add_location = async (locationReq) => {
    try {
      await bp_add_location_data(locationReq, success_add_function);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  //   ================================= Machine Location========================================
  const [room_ip, set_room_ip] = useState("");

  const add_machine_location = async (locationReq) => {
    if (locationReq?.room_name && locationReq?.room_ip) {
      const validateIpAddress = ipRegex.test(locationReq?.room_ip); // validate ip address
      if (validateIpAddress) {
        try {
          await add_machine_room(locationReq, success_add_function);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      } else {
        toast?.warning("Please Provide Valid IP Address");
      }
    } else {
      toast?.warning("Please provide all the fields");
    }
  };

  //   ================================= Machine Category========================================
  const [machineCategory, set_machineCategory] = useState("");

  const add_machine_category = async (catReq) => {
    if (catReq?.name) {
      try {
        await save_machine_category(catReq, success_add_function);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    } else {
      toast?.warning("Please provide category name");
    }
  };

  //   =================================Machine Type========================================
  const [machineType, setMachineType] = useState("");

  const add_machineType = async (typeReq) => {
    try {
      await bp_add_machine_cat_data(typeReq, success_add_function);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  //   =================================Parameter========================================
  const [paramter_name, set_paramter_name] = useState("");
  const [unit_id, set_unit_id] = useState("");

  const add_Parameter = async (parameterReq) => {
    try {
      if(parameterReq?.unit_id && parameterReq?.parameter_name){
      await bp_add_parameter_data(parameterReq, success_add_function);
      }else{
        toast.warning("Please provide all the fields")
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const generateUniqueNumber = () => {
    const letters = String.fromCharCode(
      Math.floor(Math.random() * 26) + 65,
      Math.floor(Math.random() * 26) + 65
    );
    const number = Math.floor(100000 + Math.random() * 900000);
    return `${letters}${number}`;
  };

  return (
    <Modal
      title={`Add ${
        add_number === 1
          ? "Material"
          : add_number === 2
          ? "Unit"
          : add_number === 3
          ? "Location"
          : add_number === 4
          ? " New Parameter"
          : add_number === 5
          ? "MachineType"
          : add_number === 6 ? "Machine Location"
          : add_number === 7 && "Machine Category"
      }`}
      open={isModalOpen}
      onCancel={handleCancel}
      footer={[]}
    >
      {add_number === 1 && (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} style={{ display: "flex" }}>
            <Grid item xs={12}>
              <Stack spacing={1}>
                <InputLabel htmlFor="title">Material Name</InputLabel>
                <OutlinedInput
                  id="title"
                  type="text"
                  size="small"
                  name="title"
                  placeholder="Enter Material Name"
                  fullWidth
                  value={material_name}
                  required
                  onChange={(e) => setmaterial_name(e.target.value)}
                />
              </Stack>
            </Grid>

            <Grid item xs={12}>
              <Stack spacing={1}>
                <InputLabel htmlFor="title">Material Id</InputLabel>
                <Stack direction="row" spacing={1}>
                  <OutlinedInput
                    id="title"
                    type="text"
                    size="small"
                    name="title"
                    placeholder="Enter Unique Material Id"
                    fullWidth
                    value={material_unique_id}
                    required
                    onChange={(e) => setmaterial_unique_id(e.target.value)}
                  />
                  <Tooltip title="Generate Material Unique Id">
                    <IconButton
                      color="primary"
                      onClick={() => {
                        setmaterial_unique_id(generateUniqueNumber());
                      }}
                    >
                      <AutorenewIcon color="primary" />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </Stack>
            </Grid>

            <Grid item xs={12}>
              <Stack direction="row" justifyContent="end" spacing={2}>
                <Button
                  size="small"
                  type="reset"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    reset_function();
                  }}
                >
                  Reset
                </Button>
                <Button
                  size="small"
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Submit
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </form>
      )}
      {add_number === 2 && (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} style={{ display: "flex" }}>
            <Grid item xs={12}>
              <Stack spacing={1}>
                <InputLabel htmlFor="title">Unit Name</InputLabel>
                <OutlinedInput
                  id="title"
                  type="text"
                  size="small"
                  name="email"
                  placeholder="Enter unit"
                  fullWidth
                  value={unit_name}
                  required
                  onChange={(e) => set_unit_name(e.target.value)}
                />
              </Stack>
            </Grid>

            <Grid item xs={12}>
              <Stack direction="row" justifyContent="end">
                <Button
                  size="small"
                  type="reset"
                  variant="contained"
                  color="primary"
                  className="mx-2"
                  onClick={() => {
                    reset_function();
                  }}
                >
                  Reset
                </Button>
                <Button
                  size="small"
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Submit
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </form>
      )}
      {add_number === 3 && (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} style={{ display: "flex" }}>
            <Grid item xs={12}>
              <Stack spacing={1}>
                <InputLabel htmlFor="title">Location</InputLabel>
                <OutlinedInput
                  id="title"
                  size="small"
                  type="text"
                  name="email"
                  placeholder="Enter Location"
                  fullWidth
                  value={location}
                  required
                  onChange={(e) => set_loctation(e.target.value)}
                />
              </Stack>
            </Grid>

            <Grid item xs={12}>
              <Stack direction="row" justifyContent="end">
                <Button
                  size="small"
                  type="reset"
                  variant="contained"
                  color="primary"
                  className="mx-2"
                  onClick={() => {
                    reset_function();
                  }}
                >
                  Reset
                </Button>
                <Button
                  size="small"
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Submit
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </form>
      )}
      {add_number === 4 && (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} style={{ display: "flex" }}>
            <Grid item xs={12} lg={12}>
              <Stack spacing={1}>
                <InputLabel htmlFor="title">Parameter Name</InputLabel>
                <OutlinedInput
                  id="title"
                  type="text"
                  name="email"
                  placeholder="Enter Parameter Name"
                  fullWidth
                  value={paramter_name}
                  required
                  onChange={(e) => set_paramter_name(e.target.value)}
                />
              </Stack>
            </Grid>

            <Grid item xs={12} lg={12}>
              <Stack spacing={1}>
                <InputLabel htmlFor="title">Unit</InputLabel>
                <Stack direction="row">
                  <AntSelect
                    allowClear
                    showSearch
                    id="demo-select-small"
                    value={unit_id || null}
                    size="large"
                    required
                    style={{
                      width: "100%",
                      border: "0.5px solid #D9D9D9",
                      borderRadius: "3px",
                    }}
                    bordered={false}
                    placeholder="Select Unit"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children.localeCompare(optionB.children)
                    }
                    dropdownStyle={{
                      maxHeight: 400,
                      overflow: "auto",
                    }}
                    onChange={(value, option) => {
                      set_unit_id(value);
                    }}
                  >
                    {unit_list?.map((item) => (
                      <AntSelect.Option key={item.id} value={item.id}>
                        {item.unit_name}
                      </AntSelect.Option>
                    ))}
                  </AntSelect>

                  <Tooltip title="Add Unit">
                    <IconButton
                      onClick={() => {
                        set_show_modal(true);
                        set_add_number(2);
                      }}
                    >
                      <AddIcon
                        style={{
                          color: "#00C853",
                          fontSize: "32px",
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack direction="row" justifyContent="end">
                <Button
                  size="small"
                  type="reset"
                  variant="contained"
                  color="primary"
                  className="mx-2"
                  onClick={() => {
                    reset_function();
                  }}
                >
                  Reset
                </Button>
                <Button
                  size="small"
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Submit
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </form>
      )}
      {add_number === 5 && (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} style={{ display: "flex" }}>
            <Grid item xs={12}>
              <Stack spacing={1}>
                <InputLabel htmlFor="title">Machine Type</InputLabel>
                <OutlinedInput
                  id="title"
                  size="small"
                  type="text"
                  name="email"
                  placeholder="Enter Type"
                  fullWidth
                  value={machineType}
                  required
                  onChange={(e) => setMachineType(e.target.value)}
                />
              </Stack>
            </Grid>

            <Grid item xs={12}>
              <Stack direction="row" justifyContent="end" spacing={1}>
                <Button
                  size="small"
                  type="reset"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    reset_function();
                  }}
                >
                  Reset
                </Button>
                <Button
                  size="small"
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Submit
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </form>
      )}
      {add_number === 6 && (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} style={{ display: "flex" }}>
            <Grid item xs={12}>
              <Stack spacing={1}>
                <InputLabel htmlFor="title">Location</InputLabel>
                <OutlinedInput
                  id="title"
                  size="small"
                  type="text"
                  name="email"
                  placeholder="Enter Location"
                  fullWidth
                  value={location}
                  required
                  onChange={(e) => set_loctation(e.target.value)}
                />
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack spacing={1}>
                <InputLabel htmlFor="title">IP Address</InputLabel>
                <OutlinedInput
                  id="title"
                  size="small"
                  type="text"
                  name="email"
                  placeholder="Enter Ip Address"
                  fullWidth
                  value={room_ip}
                  required
                  onChange={(e) => set_room_ip(e.target.value)}
                />
              </Stack>
            </Grid>

            <Grid item xs={12}>
              <Stack direction="row" justifyContent="end">
                <Button
                  size="small"
                  type="reset"
                  variant="contained"
                  color="primary"
                  className="mx-2"
                  onClick={() => {
                    reset_function();
                  }}
                >
                  Reset
                </Button>
                <Button
                  size="small"
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Submit
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </form>
      )}
      {add_number === 7 && (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} style={{ display: "flex" }}>
            <Grid item xs={12}>
              <Stack spacing={1}>
                <InputLabel htmlFor="title">Category</InputLabel>
                <OutlinedInput
                  id="title"
                  size="small"
                  type="text"
                  name="email"
                  placeholder="Enter Category Name"
                  fullWidth
                  value={machineCategory}
                  required
                  onChange={(e) => set_machineCategory(e.target.value)}
                />
              </Stack>
            </Grid>

            <Grid item xs={12}>
              <Stack direction="row" justifyContent="end">
                <Button
                  size="small"
                  type="reset"
                  variant="contained"
                  color="primary"
                  className="mx-2"
                  onClick={() => {
                    reset_function();
                  }}
                >
                  Reset
                </Button>
                <Button
                  size="small"
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Submit
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </form>
      )}
    </Modal>
  );
};
export default ConfigModal;
