import React, { useEffect, useState, useRef } from "react";
import { get_bill_of_materials, get_final_recipe_list } from "../../utils/api";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import BackspaceIcon from "@mui/icons-material/Backspace";
import {
  Table,
  Pagination,
  Input,
  Modal,
  Timeline,
  Typography as Typography1,
  Tag,
  Popconfirm,
  Select as AntSelect,
  Collapse,
} from "antd";
import {
  InputLabel,
  OutlinedInput,
  Stack,
  Chip,
  Select,
  MenuItem,
  FormControlLabel,
  Grid,
  InputAdornment,
  IconButton,
  FormHelperText,
  Button,
  Checkbox,
  Tooltip,
  FormGroup,
  Typography,
} from "@mui/material";
import MainCard from "components/MainCard";

function BOM() {
  const formRef = useRef();
  const [searchvalue, setSearchvalue] = useState("");
  const navigate = useNavigate();
  const [bomList, set_bomList] = useState([]);
  const [recipe_list, set_recipe_list] = useState([]);
  const [selected_recipe, set_selected_recipe] = useState("");
  const location = useLocation();
  const { recipeId } = location.state || {};
  const columns = [
    {
      title: "Material Name",
      dataIndex: "material_name",
      key: "title",
      filteredValue: [searchvalue],
      onFilter: (value, record) => {
        return String(record.material_name)
          .toLowerCase()
          .includes(value.toLowerCase());
      },
      align: "center",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "created_on",
      align: "center",
    },
    {
      title: "Type",
      dataIndex: "material_type",
      key: "type",
      align: "center",
    },
  ];

  //####################### Handle Api's######################################################

  const get_all_prod_data = async (recipe_id) => {

    try {
      await get_bill_of_materials({ recipe_id: recipe_id }, sucess_bom_get);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const sucess_bom_get = (res) => {
    if (res?.data?.data && res?.data?.data?.length > 0) {
      set_bomList(res?.data?.data);
    } else {
      set_bomList([]);
    }
  };

  const get_all_recipes = async () => {
    try {
      await get_final_recipe_list(success_recipe_list);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const success_recipe_list = (res) => {
    if (res?.data?.data && res?.data?.data?.length > 0) {
      set_recipe_list(res?.data?.data);
    } else {
      set_recipe_list([]);
    }
  };

  useEffect(() => {
    get_all_prod_data(recipeId);
  }, []);

  return (
    <MainCard title="BOM">
      <Stack direction="row" justifyContent="end" style={{marginTop:"-55px" ,marginBottom:"10px"}}>
        <Tooltip title="Back">
        <IconButton onClick={()=>navigate(-1)}>
          <BackspaceIcon color="primary" style={{fontSize:"30px"}}/>
        </IconButton>
        </Tooltip>
      </Stack>
      <Grid
        container
        spacing={1}
        columns={12}
        style={{ display: "flex", marginTop: "-20px" }}
      >
        <Grid item xs={12} lg={12}>
          <Stack
            direction="row"
            spacing={1}
            justifyContent="space-between"
            alignItems="center"
          >
          
            <div style={{ marginLeft: "auto" }}>
              <Input.Search
                Placeholder="Search here..."
                className="mt-1"
                size="large"
                onSearch={(value) => {
                  setSearchvalue(value);
                }}
                onChange={(e) => setSearchvalue(e.target.value)}
              />
            </div>
          </Stack>
        </Grid>

        <Grid item lg={12}>
          <Table
            dataSource={bomList}
            columns={columns}
            pagination={{ pageSize: 10 }}
            showHeader={true}
            bordered
          />
        </Grid>
      </Grid>
    </MainCard>
  );
}

export default BOM;
