import React, { useEffect, useState, useRef } from "react";
import {
  bp_units_data,
  bp_machine_data,
  getProcessByRecipeId,
  bp_get_pending_recipe,
  bp_save_recipe_status,
  get_api_function,
} from "../../../utils/api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Table, Pagination, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { settab_number } from "store/reducers/tabNumber";
import AddIcon from "@mui/icons-material/Add";
import VisibilityIcon from "@mui/icons-material/Visibility";
import TableInModal from "components/TableInModal";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import MarkUnreadChatAltIcon from "@mui/icons-material/MarkUnreadChatAlt";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import { message, Input, Select, Switch, Popconfirm } from "antd";
import {
  InputLabel,
  OutlinedInput,
  Stack,
  Chip,
  MenuItem,
  FormControlLabel,
  Grid,
  InputAdornment,
  FormHelperText,
  Button,
  Checkbox,
  Tooltip,
  FormGroup,
  Typography,
} from "@mui/material";
import MainCard from "components/MainCard";
import { accessControlUtils } from "../../../components/AccessControlUtils";
import { setnewProcessDataShow } from "store/reducers/newProcessData";
import { generateUniqueId } from "pages/reusable_functions";

function AddRecipe({ set_flow_data }) {
  const controllerRef = useRef();
  const dispatch = useDispatch();
  dispatch(settab_number("2"));
  const formRef = useRef();
  const { TextArea } = Input;
  const [unit, setUnitData] = useState();
  const [isShow, setIsShow] = useState(false);
  const [machineData, setMachineData] = useState([]);
  const navigate = useNavigate();
  const [previewList, set_PreviewList] = useState([]);
  const [isView, setIsView] = useState(false);
  const recipe_id = localStorage.getItem("recipe_md_id");
  const [process_expand, set_process_expand] = useState("");
  const [sourceData, setSourceData] = useState([]);
  const [columnData, setColumnData] = useState([]);
  const [modalTitle, setModalTitle] = useState();
  const [searchvalue, setSearchvalue] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [selectedMetaData, setSelectedMetadata] = useState();
  const [metadataList, setMetadataList] = useState([]);
  const [comment, setComment] = useState();
  const [recipe, setRecipe] = useState();
  const [approveStatus, setApproveStatus] = useState(false); // for finalize button disable
  const [addApproveStatus, setAddApproveStatus] = useState(false); // for add button disable
  const accessData = accessControlUtils("Recipe");
  const output = [
    { ingredient_name: "Tea", ingredient_id: 1 },
    { ingredient_name: "Coffee", ingredient_id: 2 },
  ];

  const handleConfirm = () => {
    if (selectedMetaData) {
      add_recipe({ recipe_id: selectedMetaData, recipe_status: true }); // call the api
    }
  };

  const [all_process_data, set_all_Process_data] = useState();

  const columns_data = [
    {
      title: "Stage",
      dataIndex: "",
      key: "key",

      align: "center",
      render: (text, record, index) => {
        return <strong> {`${"Stage- " + (index + 1)}`}</strong>;
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      align: "center",
    },
    {
      title: "Ingredient",
      dataIndex: "ingredients_id",
      key: "ingredients_id",
      align: "center",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      align: "center",
    },
    {
      title: "Machine Parameter",
      dataIndex: "machineParam",
      key: "machineParam",
      align: "center",
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
      align: "center",
    },
    {
      title: "Unit",
      dataIndex: "unit",
      key: "unit",
      align: "center",
    },
    {
      title: "Resource",
      dataIndex: "resource",
      key: "resource",
      align: "center",
    },
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
      align: "center",
    },
  ];

  const columns = [
    {
      title: "Process Name",
      dataIndex: "process_name",
      key: "title",
      filteredValue: [searchvalue],
      onFilter: (value, record) => {
        return String(record.process_name)
          .toLowerCase()
          .includes(value.toLowerCase());
        // String(record.process_roles).toLowerCase().includes(value.toLowerCase()) ||
        // String(record.status).toLowerCase().includes(value.toLowerCase())
      },
      align: "center",
    },
    {
      title: "Process Roles",
      dataIndex: "process_roles", // Assuming the key in your data for process_roles is "process_roles"
      key: "process_roles",
      align: "center",
      render: (processRoles) => (
        <>
          {processRoles.map((role, index) => (
            <span key={index}>
              <Chip
                sx={{
                  m: 0.5,
                }}
                label={role.role_name}
              />
            </span>
          ))}
        </>
      ),
    },
    {
      title: "Approval Status",
      dataIndex: "process_roles", // Assuming the key in your data for process_roles is "process_roles"
      key: "process_roles",
      align: "center",
      render: (processRoles) => (
        <>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Stack direction="row" spacing={2}>
              {processRoles &&
                processRoles?.some(
                  (role) => role.role_name === "Production Manager"
                ) && (
                  <Tooltip
                    title={
                      processRoles?.find(
                        (role) => role.role_name === "Production Manager"
                      ).status
                        ? "Approved"
                        : "Approval Pending"
                    }
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            processRoles?.find(
                              (role) => role.role_name === "Production Manager"
                            ).status
                          }
                        />
                      }
                      label="Production"
                    />
                  </Tooltip>
                )}
              {processRoles &&
                processRoles?.some((role) => role.role_name === "R&D") && (
                  <Tooltip
                    title={
                      processRoles?.find((role) => role.role_name === "R&D")
                        .status
                        ? "Approved"
                        : "Approval Pending"
                    }
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            processRoles?.find(
                              (role) => role.role_name === "R&D"
                            ).status
                          }
                        />
                      }
                      label="R&D"
                    />
                  </Tooltip>
                )}
              {processRoles &&
                processRoles?.some((role) => role.role_name === "QA") && (
                  <Tooltip
                    title={
                      processRoles?.find((role) => role.role_name === "QA")
                        .status
                        ? "Approved"
                        : "Approval Pending"
                    }
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            processRoles?.find(
                              (role) => role.role_name === "QA"
                            ).status
                          }
                        />
                      }
                      label="QA"
                    />
                  </Tooltip>
                )}
            </Stack>
          </div>
        </>
      ),
    },
    {
      title: "Action",
      dataIndex: "comment",
      key: "status",
      width: "8%",
      align: "center",
      render: (data, record, index) => {
        if (record.comment) {
          return (
            <>
              <Stack direction="row" spacing={-2}>
                <Tooltip title={"Edit Process"}>
                  <Button
                    size="medium"
                    variant="outline"
                    onClick={() => {
                      localStorage.setItem("recipe_md_id", selectedMetaData);
                      localStorage.setItem("recipe_name", recipe);
                      navigate("/edit-recipe", { state: { record } });
                    }}
                    color="primary"
                  >
                    <BorderColorIcon style={{ color: "orange" }} />
                  </Button>
                </Tooltip>
                <Tooltip title={"Comment"}>
                  <Button
                    size="medium"
                    variant="outline"
                    onClick={() => {
                      setComment(record.comment);
                      setIsShow(true);
                    }}
                    color="primary"
                  >
                    <MarkUnreadChatAltIcon style={{ color: "blue" }} />
                  </Button>
                </Tooltip>
              </Stack>
            </>
          );
        } else {
          return <></>;
        }
      },
    },
  ];

  const ingredientStrings = (data, header) => {
    return data?.map((ingredient) => {
      let ingredientString = `${ingredient.material_name} `;
      if (header === "operation") {
        // Conditionally include the parameter with its min and max values if they exist
        ingredientString +=
          ingredient.parameter_name && `(${ingredient.parameter_name}), `;
      } else {
        ingredientString += `${ingredient.quantity + " "}${
          ingredient.material_unit
        }`;
        if (
          ingredient.parameter_name !== "" &&
          ingredient.min_value >= 0 &&
          ingredient.max_value > 0
        ) {
          ingredientString += ` (${ingredient.parameter_name} with min ${ingredient.min_value}${ingredient.parameter_name_unit} and max ${ingredient.max_value}${ingredient.parameter_name_unit})`;
        }
      }
      return ingredientString;
    });
  };
  const operationsColumns = [
    {
      title: "Operation Number",
      dataIndex: "operation_number",
      key: "Operation",
      width: "10%",
      align: "center",
    },
    {
      title: "Operation",
      dataIndex: "operation_type",
      width: "20%",
      key: "operation_type",
      align: "center",
      render: (data, record, index) => {
        const ingred = ingredientStrings(record.ingredients, "operation");

        return (
          <div style={{ textAlign: "left" }}>
            <p>
              {record?.operation_name && (
                <>
                  <strong> Operation : </strong>
                  <span>{`${record?.operation_name}, `}</span> <br />
                </>
              )}
              <strong> Operation Type: </strong>
              {`${data}, `} <br />
              {record.instruction && (
                <>
                  <strong> Instruction: </strong>{" "}
                  <span>{`${record.instruction}`}</span>
                  <br />
                </>
              )}
              {record?.dependencie && (
                <>
                  <strong> Depending on Operations: </strong>{" "}
                  <span>{`${record?.dependencie}`}</span>
                </>
              )}
            </p>
            <p>
              {ingred && ingred.length > 0 && (
                <>
                  <strong> Ingredients: </strong>
                  {ingred.join("\n")}
                </>
              )}
            </p>
          </div>
        );
      },
    },
    {
      title: "Equipment",
      dataIndex: "machine_type",
      key: "machine_type",
    },
    {
      title: "Duration",
      dataIndex: "max_duration",
      key: "max_duration",
      align: "center",
    },
    {
      title: "Details / Remarks",
      dataIndex: "process_products",
      key: "process_products",
      align: "center",
      render: (data, record, index) => {
        const columns = [
          {
            title: "Product Name",
            dataIndex: "product_name",
            key: "product_name",
          },
          {
            title: "Process Thero Quantity",
            dataIndex: "process_thero_quantity",
            key: "operation_number",
            render: (data, record, index) => {
              return <p>{`${data} (${record.product_name_unit})`}</p>;
            },
          },
          {
            title: "Expected Min Value",
            dataIndex: "process_expec_min_value",
            key: "process_expec_min_value",
            render: (data, record, index) => {
              return <p>{`${data} (${record.product_name_unit})`}</p>;
            },
          },
          {
            title: "Expected Max Value",
            dataIndex: "process_expec_max_value",
            key: "process_expec_max_value",
            render: (data, record, index) => {
              return <p>{`${data} (${record.product_name_unit})`}</p>;
            },
          },
        ];

        const machineCols = [
          // {
          //   title: "Machine Id",
          //   dataIndex: "machine_id",
          //   key: "machine_name",
          // },
          {
            title: "Machine Name",
            dataIndex: "machine_name",
            key: "machine_name",
          },
        ];

        const pr_columns = [
          {
            title: "Parameter",
            dataIndex: "parameter_name",
            rowScope: "row",
          },
          {
            title: "Readings Per Interval (HH:MM:SS)",
            dataIndex: "parameter_max_value",
            key: "parameter_max_value",
            render: (data, record, index) => {
              return <p>{`${data}`}</p>;
            },
          },
          {
            title: "Min",
            dataIndex: "min_value",
            key: "min_value",
            align: "center"
          },
          {
            title: "Max",
            dataIndex: "max_value",
            key: "max_value",
            align: "center"
          },
        ];

        const samplingCols = [
          {
            title: "Output",
            align: "center",
            dataIndex: "material_inventory_name",
            key: "material_inventory_name",
          },
          {
            title: "Quantity",
            align: "center",
            dataIndex: "quantity",
            key: "quantity",
            render: (data, record, index) => {
              return <p>{`${data} (${record.material_inventory_unit})`}</p>;
            },
          },
          {
            title: "Department",
            align: "center",
            dataIndex: "to",
            key: "to",
          },
          {
            title: "Testing Type",
            align: "center",
            dataIndex: "testing_type_name",
            key: "testing_type_name",
          },
        ];

        const ingred = ingredientStrings(record.ingredients, "");
        return (
          <div style={{ textAlign: "left" }}>
            {ingred.length > 0 && (
              <>
                <strong> Ingredients: </strong>
                <div className="mb-4">{ingred.join("\n")}</div>
              </>
            )}

            {data && data.length > 0 && (
              <>
                <div className="mb-3">
                  <strong> Product: </strong>
                </div>
                <div style={{ margin: 10, marginLeft: -35 }}>
                  <Table
                    size="small"
                    pagination={false}
                    columns={columns}
                    dataSource={data}
                    bordered
                    style={{ width: "100%" }}
                  />
                </div>
                <br />
              </>
            )}
            {record.process_parameters &&
              record.process_parameters.length > 0 && (
                <>
                  <div className="mb-3">
                    {" "}
                    <strong> Process Parameters: </strong>
                  </div>
                  <div style={{ margin: 10, marginLeft: -35 }}>
                    <Table
                      size="small"
                      pagination={false}
                      columns={pr_columns}
                      dataSource={record.process_parameters}
                      bordered
                      style={{ width: "100%" }}
                    />
                  </div>
                  <br />
                </>
              )}

            {record.operation_type === "Sampling" &&
              record.sampling &&
              record.sampling.length > 0 && (
                <>
                  <div className="mb-3">
                    <strong> Sampling: </strong>
                  </div>
                  <div style={{ margin: 10, marginLeft: -35 }}>
                    <Table
                      size="small"
                      pagination={false}
                      columns={samplingCols}
                      dataSource={record.sampling}
                      bordered
                      style={{ width: "100%" }}
                    />
                  </div>
                </>
              )}

            {record.machines && record.machines.length > 0 && (
              <>
                <div className="mb-3">
                  <strong> Machines: </strong>
                </div>
                <div style={{ margin: 10, marginLeft: -35 }}>
                  <Table
                    size="small"
                    pagination={false}
                    columns={machineCols}
                    dataSource={record.machines}
                    bordered
                    style={{ width: "100%" }}
                  />
                </div>
              </>
            )}
          </div>
        );
      },
    },
  ];
  //####################### Handle Api's######################################################

  const add_recipe = async (recipeData) => {
    try {
      await bp_save_recipe_status(recipeData, success_save_recipe_status);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const success_save_recipe_status = (res) => {
    if (res.status === "failed") {
      toast.warning(res.message);
    } else {
      setSelectedMetadata();
      set_all_Process_data([]);
      window.location.reload();
      toast.success(res.message);
    }
  };

  const get_all_process_data = async (recipe_id) => {
    try {
      // await getProcessByRecipeId(recipe_id, success_all_process_list_get);
      await get_api_function(
        `/get_process_list_by_recipe?recipe_id=${recipe_id}`,
        success_all_process_list_get
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const success_all_process_list_get = (res) => {
    if (res.data.data && res.data.data?.length > 0) {
      const processList = res?.data?.data || [];
      set_all_Process_data(addKeysInFilterDataForCollapse(processList));
      // here we can check the if last process is approved by all roles then only we can add new process
      const finalizeProcessPermission =
        processList?.length > 0 &&
        processList[processList?.length - 1].process_roles?.every(
          (item) => item.status === true
        );
      //  && processList[processList?.length - 1].operations?.length > 0; // commented on date: 23/08/2024
      const addProcessPermission =
        processList?.length > 0 &&
        processList[processList?.length - 1].process_roles?.every(
          (item) => item.status === true
        );
      console.log("perm: ", addProcessPermission);
      setApproveStatus(finalizeProcessPermission);
      setAddApproveStatus(addProcessPermission);
    } else {
      set_all_Process_data([]);
      setAddApproveStatus(true);
      setApproveStatus(false);
    }
  };

  const successMetaData = (res) => {
    setMetadataList(addKeysInFilterDataForCollapse(res.data.data));
  };

  const get_all_DropDown_data = async () => {
    try {
      await bp_get_pending_recipe(successMetaData);
      await bp_units_data(success_unit_list_get);
      await bp_machine_data(success_machine_list_get);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const success_machine_list_get = (res) => {
    setMachineData(res?.data?.data);
  };

  const success_unit_list_get = (res) => {
    setUnitData(res?.data?.data);
  };

  useEffect(() => {
    get_all_DropDown_data();
  }, []);

  // -----------------------------

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleook = () => {
    setIsVisible(false);
  };

  const handleCancell = () => {
    setIsVisible(false);
  };

  // ==========================================

  //For collapse open at a time only one row
  const addKeysInFilterDataForCollapse = (filterData) => {
    const updatedFilterList = filterData?.map((item, index) => {
      return {
        ...item,
        key: index.toString(), // Update the key to the index converted to a string
      };
    });
    return updatedFilterList;
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [expanded_operations, set_expanded_operations] = useState([]);
  const [loading, set_loading] = useState(true);

  const expandedRowRender = (record) => {
    const updateData = expanded_operations?.map((item, index) => {
      return {
        ...item,
        key: index.toString(), // Update the key to the index converted to a string
      };
    });

    return (
      <Table
        loading={loading}
        columns={operationsColumns}
        dataSource={updateData}
        pagination={false}
        bordered
      />
    );
  };

  return (
    <MainCard title={`Approval Status`}>
      <Grid container spacing={1} columns={12} style={{ display: "flex" }}>
        <Grid container item xs={12} lg={12}>
          <Grid item xs={3}>
            <Select
              showSearch
              allowClear
              size="large"
              placeholder="Select Recipe"
              labelRender={(props) => {
                const selectedColor =
                  props?.label?.props?.children?.[0]?.props?.children?.props
                    ?.style?.color;

                return (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <FiberManualRecordIcon
                      style={{
                        color: selectedColor || "inherit", // Apply the color if available
                        fontSize: "small",
                        marginRight: "8px",
                      }}
                    />
                    {props?.label?.props?.children?.[1]}
                  </div>
                );
              }}
              optionFilterProp="children"
              value={selectedMetaData}
              onChange={(value) => {
                set_process_expand("");
                set_flow_data();
                setTimeout(() => {
                  set_flow_data({ recipe_id: value });
                }, 1);
                if (value) {
                  setRecipe(
                    metadataList?.find((data) => data.recipe_id === value)
                      .recipe_name
                  );
                  setSelectedMetadata(value);
                  get_all_process_data(value);
                } else {
                  setRecipe();
                  setSelectedMetadata();
                  set_all_Process_data([]);
                  setAddApproveStatus(true);
                  setApproveStatus(false);
                }
              }}
              filterOption={(input, option) =>
                (option?.label2 ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              style={{
                width: "70%",
                border: "0.5px solid #D9D9D9",
                borderRadius: "3px",
              }}
              bordered={false}
              dropdownStyle={{
                maxHeight: 400,
                overflow: "auto",
              }}
              options={metadataList?.map((mdata) => {
                return {
                  value: mdata?.recipe_id,
                  label: (
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Tooltip
                        title={
                          mdata.approval_status === "Approved"
                            ? "Approved"
                            : mdata.approval_status === "Pending"
                            ? "Pending"
                            : mdata.approval_status === "Rejected"
                            ? "Rejected"
                            : "Empty"
                        }
                      >
                        <FiberManualRecordIcon
                          style={{
                            color:
                              mdata.approval_status === "Approved"
                                ? "Green"
                                : mdata.approval_status === "Pending"
                                ? "Orange"
                                : mdata.approval_status === "Rejected"
                                ? "Red"
                                : "inherit",
                            fontSize: "small",
                          }}
                        />
                      </Tooltip>
                      <div
                        style={{
                          maxWidth: "100%",
                          wordWrap: "break-word",
                          whiteSpace: "normal",
                        }}
                      >
                        {mdata?.recipe_name}
                      </div>
                    </Stack>
                  ),
                  label2: mdata?.recipe_name,
                };
              })}
            />
          </Grid>
          <Grid xs={9} style={{ display: "flex", justifyContent: "end" }}>
            <div>
              <Input.Search
                Placeholder="Search here..."
                className="mt-1"
                style={{ width: "230px" }}
                size="large"
                onSearch={(value) => {
                  setSearchvalue(value);
                }}
                onChange={(e) => setSearchvalue(e.target.value)}
              />
            </div>
            {accessData?.create_access && (
              <Stack direction="row" spacing={-2}>
                <Tooltip
                  title={approveStatus ? "Finalize Recipe" : "Approval Pending"}
                >
                  <div>
                    <Popconfirm
                      title="Are you sure to Finalize this Recipe?"
                      onConfirm={handleConfirm}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button
                        disabled={!approveStatus}
                        hidden={selectedMetaData === "i"}
                      >
                        <ThumbUpIcon
                          color="success"
                          sx={{ fontSize: "32px" }}
                        />
                      </Button>
                    </Popconfirm>
                  </div>
                </Tooltip>
                <Tooltip
                  title={approveStatus ? "Add Process" : "Approval Pending"}
                >
                  <div>
                    <Button
                      disabled={!addApproveStatus}
                      hidden={selectedMetaData === "i"}
                      onClick={() => {
                        dispatch(
                          setnewProcessDataShow({
                            process_list: [
                              {
                                process: "New Process",
                                process_id: generateUniqueId(),
                              },
                            ],
                            operation_list: [],
                            operation_edges: [],
                            process_edges: [],
                          })
                        );

                        // Get the last process in the process list
                        if (all_process_data && all_process_data?.length > 0) {
                          // Get the last process in the process list
                          // const lastProcess =
                          //   all_process_data[all_process_data?.length - 1];
                          // // Initialize variable to store the maximum operation id of the last process
                          // let maxOperationId = 0; // Initialize with a value lower than any possible operation id
                          // lastProcess.operations?.forEach((operation) => {
                          //   // Check if the operation id is greater than the current maximum operation id
                          //   if (operation.operation_number > maxOperationId) {
                          //     // Update the maximum operation id
                          //     maxOperationId = operation.operation_number;
                          //   }
                          // });
                          localStorage.setItem(
                            "last_process_op_id",
                            metadataList?.find(
                              (item) => item?.recipe_id === selectedMetaData
                            )?.operation_count
                          );
                        } else {
                          localStorage.setItem("last_process_op_id", 0);
                        }
                        localStorage.setItem("recipe_md_id", selectedMetaData);
                        localStorage.setItem("recipe_name", recipe);
                        navigate("/addRecipeForm");
                      }}
                    >
                      <AddIcon color="primary" sx={{ fontSize: "32px" }} />
                    </Button>
                  </div>
                </Tooltip>
              </Stack>
            )}
          </Grid>
        </Grid>
        <Grid item lg={12}>
          <Table
            dataSource={all_process_data}
            columns={columns}
            pagination={false}
            expandable={{
              expandedRowRender,
              onExpand: async (expanded, record) => {
                set_expanded_operations([]);
                if (expanded) {
                  set_process_expand(record?.key);
                  set_loading(true);
                  const success_get_operation = (res) => {
                    if (res.data.status === "success") {
                      set_expanded_operations(res.data.data[0].operations);
                    }
                    set_loading(false);
                  };
                  try {
                    if (controllerRef.current) {
                      controllerRef.current.abort();
                    }
                    controllerRef.current = new AbortController();
                    const signal = controllerRef.current.signal;
                    await get_api_function(
                      `/bp_get_all_process_data?recipe_id=${selectedMetaData}&process_id=${record?.process_id}`,
                      success_get_operation,
                      {},
                      signal
                    );
                  } catch (e) {
                    console.log(e);
                  }
                } else {
                  set_process_expand("");
                }
              },
              expandedRowKeys: [process_expand],
            }}
            rowClassName={(record) =>
              record.status === "" ? { background: "red" } : null
            }
            showHeader={true}
            bordered
          />
        </Grid>

        {/* <Grid item lg={12}>
          <Stack direction="row" justifyContent="end" spacing={2}>
            <Button
              type="button"
              variant="contained"
              onClick={(e) => handleSubmit()}
              color="primary"
            >
              Submit
            </Button>
          </Stack>
        </Grid> */}
      </Grid>

      <Modal
        open={isView}
        title="View Recipe"
        width={900}
        onOk={handleOk}
        onCancel={() => setIsView(false)}
        footer={[]}
      >
        <div className="mt-4">
          <Table
            dataSource={previewList}
            columns={columns_data}
            pagination={false}
            showHeader={true}
            bordered
          />
        </div>
      </Modal>

      <Modal
        open={isShow}
        title="Rejection Comment"
        width={500}
        onCancel={() => setIsShow(!isShow)}
        footer={[]}
      >
        <Grid container xs={12} lg={12}>
          <Grid item xs={8}>
            <textarea
              value={comment}
              style={{ width: "400px", height: "50px" }}
            />
          </Grid>
        </Grid>
      </Modal>

      <TableInModal
        isVisible={isVisible} // Set to true or false based on visibility
        title={modalTitle}
        handleOk={handleook}
        handleCancel={handleCancell}
        sourceData={sourceData}
        columnsData={columnData}
      />
    </MainCard>
  );
}

export default AddRecipe;
