import { createSlice } from "@reduxjs/toolkit";

const set_submit_status_slice = createSlice({
  name: "submit_status",
  initialState: false,
  reducers: {
    set_submit_status: (state, action) => {
      console.log(state);
      return !state;
    },
  },
});

export const { set_submit_status } = set_submit_status_slice.actions;
export default set_submit_status_slice.reducer;
