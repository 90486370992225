import React, { useEffect, useState } from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { MdLiveHelp } from "react-icons/md";
import { Button, IconButton } from "@mui/material";
import { Popover, Typography } from "antd";
const { Paragraph } = Typography;

function InfoComponent({ infoText }) {
  return (
    <Popover
      placement="leftTop"
      title={"Info"}
      content={
        <Paragraph
          style={{
            width: "200px"
          }}
          ellipsis={{ rows: 5, expandable: true, symbol: "more" }}
        >
          <i>{infoText}</i>
        </Paragraph>
      }
    >
      <IconButton color="primary">
        <MdLiveHelp color="action" style={{ fontSize: "20" }} />
      </IconButton>
    </Popover>
  );
}

export default InfoComponent;
