// project import
import React from "react";

import Routes from "routes";
import ThemeCustomization from "themes";
import ScrollTop from "components/ScrollTop";
import { HashRouter } from "react-router-dom";
import "react-chat-elements/dist/main.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "bootstrap/dist/css/bootstrap.min.css";
// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const App = () => {
  return (
      <HashRouter>
        <React.Suspense fallback={loading}>
          <ThemeCustomization>
            <ScrollTop>
              <Routes />
            </ScrollTop>
          </ThemeCustomization>
        </React.Suspense>
      </HashRouter>
  );
};

export default App;
