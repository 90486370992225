import { useEffect, useRef, useState } from "react";
import { Grid, Stack } from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import { DownOutlined, RightOutlined } from "@ant-design/icons";
import {
  Radio,
  Tabs,
  Form,
  Select,
  Checkbox,
  Divider as AntDivider,
  Tooltip,
  DatePicker,
  Card,
} from "antd";
import { get_api_function } from "utils/api";
// d, f, m,  t

function GrafanaDashboard1() {
  const baseURL =
    "https://140.238.250.178/d-solo/b17e45c7-4a70-4437-a1b1-1d7b1ef09176/batch-processing-stats?orgId=1";

  const overallBaseUrl =
    "https://140.238.250.178/d-solo/b17e45c7-4a70-4437-a1b1-1d7b1ef09176/batch-processing-stats?orgId=1";

  const productBaseUrl =
    "https://140.238.250.178/d-solo/b17e45c7-4a70-4437-a1b1-1d7b1ef09176/batch-processing-stats?orgId=1";
  
    const panel_id_list = {
    default: {
      Daily: [17, 18, 19, 20, 21, 23, 22, 27, 25, 26, 24, 28, 29],
      OverAll: [1, 3, 5, 6, 2, 4, 7],
      Product: [9,10,11,12,13,14,15],
    },
  };
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isProdCollapse, setIsProdCollapse] = useState(false);
  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };
  const [panel_ids, set_panel_ids] = useState(
    panel_id_list["default"]["Daily"]
  );

  const [product_Panel_ids, set_Product_Panel_ids] = useState(
    panel_id_list["default"]["Product"]
  );

  const [overall_Panel_ids, set_Overall_Panel_ids] = useState(
    panel_id_list["default"]["OverAll"]
  );

  const handleDateChange = (dates, dateStrings) => {
    if (dates && dates.length === 2) {
      // Convert moment objects to timestamps
      const fromTimestamp = dates[0].valueOf();
      const toTimestamp = dates[1].valueOf();
      console.log(fromTimestamp);
      console.log("data", toTimestamp);
      setFromDate(fromTimestamp);
      setToDate(toTimestamp);
      set_range_value(dates);
    } else {
      setFromDate(null);
      setToDate(null);
      set_range_value(null);
    }
  };

  const handleLogin = async () => {
    try {
      const res = await axios.post(
        "http://140.238.250.178:3000/login",
        {
          user: "admin@gmail.com",
          password: "Welcome@123",
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = res.data;
      // handle the data as needed
    } catch (err) {
      toast.warning(err.response?.data?.message || err.message); // Handle error
    }
  };
  const { RangePicker } = DatePicker;
  const [range_value, set_range_value] = useState([]);
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const filters = (
    <Stack direction="row" spacing={2} justifyContent="end">
      <RangePicker
        showTime // Enables time selection
        format="YYYY-MM-DD HH:mm:ss" // Format with time
        value={range_value}
        onChange={handleDateChange}
        style={{ marginBottom: "-8px" }}
      />
    </Stack>
  );

  useEffect(() => {
    // handleLogin();
  }, []);

  return (
    <>
      <Grid container spacing={1}>
        <Grid item lg={12}>
          {filters}
        </Grid>
        <Card
          bordered={false} // This removes the border// You can customize the width or other styles as needed
          style={{ backgroundColor: "transparent", marginBottom: "2px" }}
        >
          <Grid item container columns={12} spacing={1}>
            <Grid item lg={1.71} sm={1.71} key="Number of batches scheduled">
              <iframe
                src={`${baseURL}&from=${fromDate}&to=${toDate}&theme=light&panelId=${panel_ids[0]}`}
                width="100%"
                height="200"
                frameborder="0"
                referrerPolicy="origin"
                title="1"
              ></iframe>
            </Grid>
            <Grid item lg={1.71} sm={1.71} key="Number of Batches Completed">
              <iframe
                src={`${baseURL}&from=${fromDate}&to=${toDate}&theme=light&panelId=${panel_ids[1]}`}
                width="100%"
                height="200"
                frameborder="0"
                referrerPolicy="origin"
                title="1"
              ></iframe>
            </Grid>
            <Grid item lg={1.71} sm={1.71} key="Number of Batches Running">
              <iframe
                src={`${baseURL}&from=${fromDate}&to=${toDate}&theme=light&panelId=${panel_ids[2]}`}
                width="100%"
                height="200"
                frameborder="0"
                referrerPolicy="origin"
                title="1"
              ></iframe>
            </Grid>
            <Grid item lg={1.71} sm={1.71} key="Batches approved">
              <iframe
                src={`${baseURL}&from=${fromDate}&to=${toDate}&theme=light&panelId=${panel_ids[3]}`}
                width="100%"
                height="200"
                frameborder="0"
                referrerPolicy="origin"
                title="1"
              ></iframe>
            </Grid>
            <Grid item lg={1.71} sm={1.71} key="Batches Awaiting Approval">
              <iframe
                src={`${baseURL}&from=${fromDate}&to=${toDate}&theme=light&panelId=${panel_ids[4]}`}
                width="100%"
                height="200"
                frameborder="0"
                referrerPolicy="origin"
                title="1"
              ></iframe>
            </Grid>
            <Grid item lg={1.71} sm={1.71} key="Batches Rejected">
              <iframe
                src={`${baseURL}&from=${fromDate}&to=${toDate}&theme=light&panelId=${panel_ids[5]}`}
                width="100%"
                height="200"
                frameborder="0"
                referrerPolicy="origin"
                title="1"
              ></iframe>
            </Grid>
            <Grid item lg={1.71} sm={1.71} key="Number of Batches Pending">
              <iframe
                src={`${baseURL}&from=${fromDate}&to=${toDate}&theme=light&panelId=${panel_ids[6]}`}
                width="100%"
                height="200"
                frameborder="0"
                referrerPolicy="origin"
                title="1"
              ></iframe>
            </Grid>
            <Grid item lg={6} sm={6} key="">
              <iframe
                src={`${baseURL}&from=${fromDate}&to=${toDate}&theme=light&panelId=${panel_ids[7]}`}
                width="100%"
                height="300"
                frameborder="0"
                referrerPolicy="origin"
                title="1"
              ></iframe>
            </Grid>
            <Grid item lg={6} sm={6} key="">
              <iframe
                src={`${baseURL}&from=${fromDate}&to=${toDate}&theme=light&panelId=${panel_ids[10]}`}
                width="100%"
                height="300"
                frameborder="0"
                referrerPolicy="origin"
                title="1"
              ></iframe>
            </Grid>
            <Grid item lg={6} sm={6} key="">
              <iframe
                src={`${baseURL}&from=${fromDate}&to=${toDate}&theme=light&panelId=${panel_ids[8]}`}
                width="100%"
                height="300"
                frameborder="0"
                referrerPolicy="origin"
                title="1"
              ></iframe>
            </Grid>
            <Grid item lg={6} sm={6} key="">
              <iframe
                src={`${baseURL}&from=${fromDate}&to=${toDate}&theme=light&panelId=${panel_ids[11]}`}
                width="100%"
                height="300"
                frameborder="0"
                referrerPolicy="origin"
                title="1"
              ></iframe>
            </Grid>
            <Grid item lg={4} sm={4} key="">
              <iframe
                src={`${baseURL}&from=${fromDate}&to=${toDate}&theme=light&panelId=${panel_ids[9]}`}
                width="100%"
                height="300"
                frameborder="0"
                referrerPolicy="origin"
                title="1"
              ></iframe>
            </Grid>
            <Grid item lg={4} sm={4} key="">
              <iframe
                src={`${baseURL}&from=${fromDate}&to=${toDate}&theme=light&panelId=${panel_ids[12]}`}
                width="100%"
                height="300"
                frameborder="0"
                referrerPolicy="origin"
                title="1"
              ></iframe>
            </Grid>
            <Grid item lg={4} sm={4} key="">
              <iframe
                src={`${baseURL}&from=${fromDate}&to=${toDate}&theme=light&panelId=${panel_ids[10]}`}
                width="100%"
                height="300"
                frameborder="0"
                referrerPolicy="origin"
                title="1"
              ></iframe>
            </Grid>
          </Grid>
        </Card>
        <Grid item lg={12}>
          <div onClick={toggleCollapse} style={{ cursor: "pointer" }}>
            <Stack direction="row" spacing={1.2} alignItems="center">
              {isCollapsed ? <DownOutlined /> : <RightOutlined />}
              <h6>
                <strong>{"Overall"}</strong>
              </h6>
            </Stack>
          </div>
        </Grid>

        {isCollapsed && (
          <Card
            bordered={false} // This removes the border// You can customize the width or other styles as needed
            style={{ backgroundColor: "transparent", marginBottom: "2px" }}
          >
            <Grid item container columns={12} spacing={1}>
              <Grid item container lg={3} spacing={1}>
                <Grid item lg={12} sm={12} key="Number of Products">
                  <iframe
                    src={`${overallBaseUrl}&from=${fromDate}&to=${toDate}&theme=light&panelId=${overall_Panel_ids[0]}`}
                    width="100%"
                    height="200"
                    frameborder="0"
                    referrerPolicy="origin"
                    title="1"
                  ></iframe>
                </Grid>
                <Grid item lg={12} sm={12} key="Total Unit Production">
                  <iframe
                    src={`${overallBaseUrl}&from=${fromDate}&to=${toDate}&theme=light&panelId=${overall_Panel_ids[1]}`}
                    width="100%"
                    height="200"
                    frameborder="0"
                    referrerPolicy="origin"
                    title="1"
                  ></iframe>
                </Grid>
                <Grid item lg={12} sm={12} key="Yield Efficiency">
                  <iframe
                    src={`${overallBaseUrl}&from=${fromDate}&to=${toDate}&theme=light&panelId=${overall_Panel_ids[2]}`}
                    width="100%"
                    height="200"
                    frameborder="0"
                    referrerPolicy="origin"
                    title="1"
                  ></iframe>
                </Grid>
              </Grid>
              <Grid item container lg={9} spacing={1}>
                <Grid item lg={7} sm={7} key="Top Ingredients used with Qty">
                  <iframe
                    src={`${overallBaseUrl}&from=${fromDate}&to=${toDate}&theme=light&panelId=${overall_Panel_ids[3]}`}
                    width="100%"
                    height="300"
                    frameborder="0"
                    referrerPolicy="origin"
                    title="1"
                  ></iframe>
                </Grid>
                <Grid
                  item
                  lg={5}
                  sm={5}
                  key="Total Expected Yield vs Actual Yield"
                >
                  <iframe
                    src={`${overallBaseUrl}&from=${fromDate}&to=${toDate}&theme=light&panelId=${overall_Panel_ids[5]}`}
                    width="100%"
                    height="300"
                    frameborder="0"
                    referrerPolicy="origin"
                    title="1"
                  ></iframe>
                </Grid>
                <Grid item lg={7} sm={7} key="Recipe Per Product">
                  <iframe
                    src={`${overallBaseUrl}&from=${fromDate}&to=${toDate}&theme=light&panelId=${overall_Panel_ids[4]}`}
                    width="100%"
                    height="300"
                    frameborder="0"
                    referrerPolicy="origin"
                    title="1"
                  ></iframe>
                </Grid>
                <Grid item lg={5} sm={5} key="Production Per Product">
                  <iframe
                    src={`${overallBaseUrl}&from=${fromDate}&to=${toDate}&theme=light&panelId=${overall_Panel_ids[6]}`}
                    width="100%"
                    height="300"
                    frameborder="0"
                    referrerPolicy="origin"
                    title="1"
                  ></iframe>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        )}
        <Grid item lg={12}>
          <div
            onClick={() => setIsProdCollapse(!isProdCollapse)}
            style={{ cursor: "pointer" }}
          >
            <Stack direction="row" spacing={1.2} alignItems="center">
              {isProdCollapse ? <DownOutlined /> : <RightOutlined />}
              <h6><strong>{"Product"}</strong></h6>
            </Stack>
          </div>
        </Grid>

        {isProdCollapse && (
          <Card bordered={false} style={{ backgroundColor: "transparent" }}>
            <Grid item container columns={12} spacing={1}>
              <Grid item lg={1.71} sm={1.71} key="">
                <iframe
                  src={`${productBaseUrl}&from=${fromDate}&to=${toDate}&theme=light&panelId=${product_Panel_ids[0]}`}
                  width="100%"
                  height="200"
                  frameborder="0"
                  referrerPolicy="origin"
                  title="1"
                ></iframe>
              </Grid>
              <Grid item lg={1.71} sm={1.71} key="">
                <iframe
                  src={`${productBaseUrl}&from=${fromDate}&to=${toDate}&theme=light&panelId=${product_Panel_ids[1]}`}
                  width="100%"
                  height="200"
                  frameborder="0"
                  referrerPolicy="origin"
                  title="1"
                ></iframe>
              </Grid>
              <Grid item lg={1.71} sm={1.71} key="">
                <iframe
                  src={`${productBaseUrl}&from=${fromDate}&to=${toDate}&theme=light&panelId=${product_Panel_ids[2]}`}
                  width="100%"
                  height="200"
                  frameborder="0"
                  referrerPolicy="origin"
                  title="1"
                ></iframe>
              </Grid>
              <Grid item lg={1.71} sm={1.71} key="">
                <iframe
                  src={`${productBaseUrl}&from=${fromDate}&to=${toDate}&theme=light&panelId=${product_Panel_ids[3]}`}
                  width="100%"
                  height="200"
                  frameborder="0"
                  referrerPolicy="origin"
                  title="1"
                ></iframe>
              </Grid>
              <Grid item lg={1.71} sm={1.71} key="">
                <iframe
                  src={`${productBaseUrl}&from=${fromDate}&to=${toDate}&theme=light&panelId=${product_Panel_ids[4]}`}
                  width="100%"
                  height="200"
                  frameborder="0"
                  referrerPolicy="origin"
                  title="1"
                ></iframe>
              </Grid>
              <Grid item lg={1.71} sm={1.71} key="">
                <iframe
                  src={`${productBaseUrl}&from=${fromDate}&to=${toDate}&theme=light&panelId=${product_Panel_ids[5]}`}
                  width="100%"
                  height="200"
                  frameborder="0"
                  referrerPolicy="origin"
                  title="1"
                ></iframe>
              </Grid>
              <Grid item lg={1.71} sm={1.71} key="">
                <iframe
                  src={`${productBaseUrl}&from=${fromDate}&to=${toDate}&theme=light&panelId=${product_Panel_ids[6]}`}
                  width="100%"
                  height="200"
                  frameborder="0"
                  referrerPolicy="origin"
                  title="1"
                ></iframe>
              </Grid>
            </Grid>
          </Card>
        )}
      </Grid>
    </>
  );
}

export default GrafanaDashboard1;
