import React, { useEffect, useState, useRef } from "react";
import {
  bp_add_meta_data,
  bp_get_machine_by_machine_typ,
  bp_get_testing_type,
  bp_machine_data,
  bp_save_batch_product_inventory,
  bp_save_sample_details,
} from "../../../utils/api";
import { toast } from "react-toastify";

import {
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
  Grid,
  Select,
  MenuItem,
  Button,
  InputAdornment,
  IconButton,
  Avatar,
} from "@mui/material";
import MainCard from "components/MainCard";
import { batch, useDispatch, useSelector } from "react-redux";
import { settab_number } from "store/reducers/tabNumber";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { Badge, Descriptions, Table } from "antd";
import AddIcon from "@mui/icons-material/Add";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

function AddSample() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const operation_data = state?.operation_data;
  const dispatch = useDispatch();
  const [machine, set_machine] = useState("i");
  const [testing_type, set_testing_type] = useState("i");

  const handleSubmit = (e) => {
    e.preventDefault();
    submit_function();
  };

  const submit_function = async () => {
    try {
      await bp_save_sample_details(
        {
          sample_number: operation_data?.operation_id,
          batch_id: operation_data?.batch_id,
          quantity: quantity,
          machine_id: machine,
          testing_type_id: testing_type,
        },
        success_bp_save_batch_product_inventory
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const success_bp_save_batch_product_inventory = (res) => {
    console.log(res);
    if (res.status === "failed") {
      toast.warning(res.message);
    } else {
      navigate("/batch");
      toast.success("Metadata added Successfully");
    }
  };

  const [added_data, set_added_data] = useState([{}]);
  const [sample_number, set_sample_number] = useState("");
  const [quantity, set_quantity] = useState("");

  useEffect(() => {
    console.log("added_data", added_data);
  }, [added_data]);

  const machineDetailColumns = [
    {
      title: () => <Typography variant="h3">Mass</Typography>,
      align: "center",
      key: "Mass",
      width: "25%",
      render: (_, __, index) => (
        <Typography style={{ fontSize: "24px" }}>
          {operation_data?.instruction}
        </Typography>
      ),
    },
    {
      title: () => <Typography variant="h3">Testing Type</Typography>,
      align: "center",
      key: "Testing_type",
      width: "25%",
      render: (_, __, index) => (
        <Select
          size="medium"
          required
          fullWidth
          value={testing_type}
          onChange={(e) => set_testing_type(e.target.value)}
          style={{
            fontSize: "24px",
          }}
        >
          <MenuItem value="i" disabled>
            <em> Select Testing Type</em>
          </MenuItem>
          {testing_type_list?.map((item) => (
            <MenuItem value={item.testing_type_id}>
              {item.testing_type}
            </MenuItem>
          ))}
        </Select>
      ),
    },
    {
      title: () => <Typography variant="h3">Sample Number</Typography>,
      align: "center",
      key: "Sample_nO",
      width: "25%",
      render: (_, __, index) => (
        <OutlinedInput
          id="title"
          type="text"
          name="name"
          fullWidth
          value={sample_number}
          onChange={(e) => set_sample_number(e.target.value)}
          inputProps={{
            style: {
              fontSize: "24px",
            },
          }}
        />
      ),
    },
    {
      title: () => <Typography variant="h3">Quantity</Typography>,
      align: "center",
      key: "Quantity",
      width: "25%",
      render: (_, __, index) => (
        <OutlinedInput
          id="title"
          type="number"
          name="name"
          fullWidth
          value={quantity}
          onChange={(e) => set_quantity(e.target.value)}
          inputProps={{
            style: {
              fontSize: "24px",
            },
          }}
        />
      ),
    },
  ];

  //####################### Handle Api's######################################################

  const [machine_data, setMachineData] = useState([]);
  const [testing_type_list, set_testing_type_list] = useState([]);
  const get_all_machine = async () => {
    try {
      await bp_get_machine_by_machine_typ(success_machine_list_get, {
        machine_type_id: operation_data?.machine_id,
      });
      await bp_get_testing_type(success_bp_get_testing_type);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const success_machine_list_get = (res) => {
    setMachineData(res?.data?.data);
  };

  const success_bp_get_testing_type = (res) => {
    set_testing_type_list(res?.data?.data);
  };

  useEffect(() => {
    get_all_machine();
  }, [operation_data]);

  const resetFunction = () => {
    set_machine("");
  };

  return (
    <MainCard darkTitle title="Sampling" backbutton>
      <Stack spacing={2}>
        <Descriptions
          title=""
          // layout="vertical"
          bordered
          column={2}
          contentStyle={{
            fontSize: "24px",
          }}
          labelStyle={{
            fontSize: "32px",
            fontWeight: "bold",
          }}
          items={[
            {
              key: "5",
              label: "Instruction",
              children: operation_data?.instruction,
              span: 2,
            },
            {
              key: "1",
              label: "Batch No",
              children: operation_data?.batch_number,
            },
            {
              key: "2",
              label: "Operation",
              children: `${operation_data?.operation_type} - ${operation_data?.operation_number}`,
            },
            {
              key: "3",
              label: "Machine",
              children: (
                <Select
                  value={machine}
                  size="medium"
                  required
                  fullWidth
                  onChange={(e) => set_machine(e.target.value)}
                  style={{
                    fontSize: "24px",
                  }}
                >
                  <MenuItem value="i" disabled>
                    <em> Select Machine</em>
                  </MenuItem>
                  {machine_data?.map((item) => (
                    <MenuItem value={item.machine_id}>
                      {item.machine_name}
                    </MenuItem>
                  ))}
                </Select>
              ),
            },
            {
              key: "4",
              label: "Duration",
              children: operation_data?.max_duration,
            },
          ]}
        />
        <Table
          dataSource={added_data}
          columns={machineDetailColumns}
          size="small"
          bordered
          pagination={false}
        />

        <Stack
          direction="row"
          alignItems="end"
          justifyContent="end"
          spacing={2}
        >
          <Button
            size="medium"
            type="submit"
            variant="contained"
            color="primary"
            sx={{
              height: "50px",
              fontSize: "24px",
            }}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Stack>
      </Stack>
    </MainCard>
  );
}

export default AddSample;
