import React, { useState, useEffect } from "react";
// project import
import {
  bp_get_batch_notification_for_employee,
  get_api_function,
  get_ingredient_value_alert,
  get_notification,
} from "utils/api";
import { useNavigate } from "react-router";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  Typography,
  Grid,
  Card,
  Avatar,
  Tab,
  Box,
  Stack,
} from "@mui/material";
import { Typography as Typography1 } from "antd";
// ==============================|| SAMPLE PAGE ||============================== //

const avatarSX = {
  width: 36,
  height: 36,
  fontSize: "1rem",
};

const actionSX = {
  mt: "6px",
  ml: 1,
  top: "auto",
  right: "auto",
  alignSelf: "flex-start",

  transform: "none",
};

const ScheduleNotificationPage = () => {
  const [notifications_data, set_notifications_data] = useState([]);

  useEffect(() => {
    get_api_function("/get_schedule_notification", (res) => {
      set_notifications_data(res?.data?.data);
    });
  }, []);

  return (
    <List
      component="nav"
      sx={{
        p: 0,
        "& .MuiListItemButton-root": {
          py: 0.5,
          "& .MuiAvatar-root": avatarSX,
          "& .MuiListItemSecondaryAction-root": {
            ...actionSX,
            position: "relative",
          },
        },
      }}
    >
      <Grid container>
        {notifications_data?.map((item) => {
          return (
            <Grid item lg={3}>
              <Card sx={{ m: 2 }}>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      sx={{
                        color: "success.main",
                        bgcolor: "success.lighter",
                      }}
                    >
                      {item.recipe_name
                        .split(" ")
                        .map((item) => item[0])
                        .join("")
                        .toUpperCase()}
                    </Avatar>
                  </ListItemAvatar>
                  <Grid container>
                    <Grid item lg={12}>
                      <ListItemText
                        primary={
                          <Typography1.Paragraph
                            ellipsis={{
                              rows: 2,
                              expandable: true,
                              symbol: "more",
                            }}
                          >
                            {item?.msg}
                          </Typography1.Paragraph>
                        }
                      />
                    </Grid>

                    <Grid container item lg={12}>
                      <Grid item lg={6}>
                        <Typography variant="caption" noWrap>
                          Recipe: &nbsp;
                        </Typography>
                      </Grid>
                      <Grid item lg={6}>
                        <Typography variant="caption" noWrap>
                          {item?.recipe_name}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid container item lg={12}>
                      <Grid item lg={6}>
                        <Typography variant="caption" noWrap>
                          Batch Number: &nbsp;
                        </Typography>
                      </Grid>
                      <Grid item lg={6}>
                        <Typography variant="caption" noWrap>
                          {item?.batch_number}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid container item lg={12}>
                      <Grid item lg={6}>
                        <Typography variant="caption" noWrap>
                          Process Name: &nbsp;
                        </Typography>
                      </Grid>
                      <Grid item lg={6}>
                        <Typography variant="caption" noWrap>
                          {item?.process_name}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid container item lg={12}>
                      <Grid item lg={6}>
                        <Typography variant="caption" noWrap>
                          Schedule Time: &nbsp;
                        </Typography>
                      </Grid>
                      <Grid item lg={6}>
                        <Typography variant="caption" noWrap>
                          {item?.batch_schedule_time?.slice(0, 19)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </ListItem>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </List>
  );
};

export default ScheduleNotificationPage;
